import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "./axiosInterceptor";

export const getChangeRequests = async (params) => {
  try {
    let endpoint = "employee/employee-request/manager/change-request";
    if (params && params.id) {
      endpoint += `/${params.id}`;
      delete params.id;
    }

    const response = await api.get(endpoint, { params: params });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const updateChangeRequests = async (id, data) => {
  try {
    const response = await api.patch(
      `employee/employee-request/manager/change-request/${id}`,
      data
    );
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } catch (err) {
    return toast.error("İşçi dəyişikliyi sorğusu artıq işlənib.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
};
export const getChangeRequestsDownloadCSV = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  const str = isWithFilter
    ? `/employee/employee-request/manager/change-request/download?filter=${filter}&columns=${columns}`
    : `/employee/employee-request/manager/change-request/download?columns=${columns}`;

  return await api.get(str, { responseType: "blob" });
};
export const handleApiError = (err) => {
  err?.response?.data?.errors &&
    err?.response?.data?.errors.map((item) => {
      return toast.error(item?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
};
