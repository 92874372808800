import React, { useEffect, useState } from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CreateIcon from "@mui/icons-material/Create";
import { getCommonData } from "../../../api/api";
import { academicDegreeValidation } from "./Validations";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";

const MCViewAcademicDegree = ({ employeeById }) => {
  const [degreeName, setDegreeName] = useState([]);
  const [degreeIssuer, setDegreeIssuer] = useState([]);
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  useEffect(() => {
    const handleAcademicDegrees = async () => {
      const degreeNameResponse = await getCommonData("academicDegrees");
      const degreeIssuerResponse = await getCommonData("academicDegreeIssuers");
      setDegreeName(degreeNameResponse.data);
      setDegreeIssuer(degreeIssuerResponse.data);
    };
    handleAcademicDegrees();
  }, []);

  const initialValues = {
    academicDegrees:
      employeeById.academicDegrees.map((item, index) => ({
        ...item,
        id: index,
      })) || [],
  };

  const [dataIndex, setDataIndex] = useState(
    initialValues.academicDegrees.at(-1).id
  );

  // form change request methods

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key]) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "academic-degree",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.academicDegrees.forEach((obj1) => {
      const obj2 = oldValue.academicDegrees.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.academicDegrees.forEach((obj2) => {
      const obj1 = formValue.academicDegrees.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(data) {
    makeChangesData(data, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "academic-degree",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.academicDegrees.forEach((obj1) => {
      const obj2 = oldValue.academicDegrees.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.academicDegrees.forEach((obj2) => {
      const obj1 = formValue.academicDegrees.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}
            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>
          <Formik
            validationSchema={academicDegreeValidation}
            onSubmit={handleSubmit}
            initialValues={{
              academicDegrees:
                initialValues.academicDegrees.map((item, index) => ({
                  ...item,
                  id: index,
                })) || [], // Set the initial laborActivity values from employeeById
            }}
          >
            {({ values, errors, setFieldValue, handleChange }) => {
              return (
                <Form
                  style={
                    !formCorrection
                      ? { pointerEvents: "none" }
                      : { pointerEvents: "auto" }
                  }
                >
                  <FieldArray name="academicDegrees">
                    {({ push, remove }) => (
                      <div>
                        {values.academicDegrees.map((form, index) => (
                          <div key={index}>
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              >
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1 + ")"}Elmi dərəcə
                                </Typography>
                              </div>

                              {/* academicDegree type and issuer */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    error={
                                      errors?.academicDegrees?.[index]?.name &&
                                      Boolean(
                                        errors?.academicDegrees?.[index]?.name
                                      )
                                    }
                                    fullWidth
                                  >
                                    <InputLabel
                                      id={`academicDegrees[${index}].name`}
                                    >
                                      Elmi dərəcə
                                    </InputLabel>
                                    {/* <Field
                                      as={Select}
                                      required
                                      value={values.academicDegrees[index].name}
                                      name={`academicDegrees[${index}].name`}
                                      labelId={`academicDegrees[${index}].name`}
                                      label="Elmi dərəcə"
                                      id={`academicDegrees[${index}].name`}
                                      defaultValue=""
                                      fullWidth
                                      onChange={(e) => {
                                        setRequestButton(true);

                                        setFieldValue(
                                          `academicDegrees[${index}].name`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.academicDegrees?.[index]
                                          ?.name &&
                                        Boolean(
                                          errors?.academicDegrees?.[index]?.name
                                        )
                                      }
                                    >
                                      {degreeName.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Field> */}
                                    <Field
                                      as={Select}
                                      required
                                      value={values.academicDegrees[index].name}
                                      name={`academicDegrees[${index}].name`}
                                      labelId={`academicDegrees[${index}].name`}
                                      label="Elmi dərəcə"
                                      id={`academicDegrees[${index}].name`}
                                      defaultValue=""
                                      fullWidth
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `academicDegrees[${index}].name`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.academicDegrees?.[index]
                                          ?.name &&
                                        Boolean(
                                          errors?.academicDegrees?.[index]?.name
                                        )
                                      }
                                    >
                                      {degreeName.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Field>

                                    {errors.academicDegrees?.[index]?.name &&
                                      errors.academicDegrees?.[index]?.name && (
                                        <FormHelperText error>
                                          {
                                            errors.academicDegrees?.[index]
                                              ?.name
                                          }
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    error={
                                      errors?.academicDegrees?.[index]
                                        ?.issuer &&
                                      Boolean(
                                        errors?.academicDegrees?.[index]?.issuer
                                      )
                                    }
                                    fullWidth
                                  >
                                    <InputLabel
                                      id={`academicDegrees[${index}].issuer`}
                                    >
                                      Elmi dərəcəni verən müəssisə
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`academicDegrees[${index}].issuer`}
                                      labelId={`academicDegrees[${index}].issuer`}
                                      label="Elmi dərəcəni verən müəssisə"
                                      id={`academicDegrees[${index}].issuer`}
                                      defaultValue=""
                                      fullWidth
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `academicDegrees[${index}].issuer`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.academicDegrees?.[index]
                                          ?.issuer &&
                                        Boolean(
                                          errors?.academicDegrees?.[index]
                                            ?.issuer
                                        )
                                      }
                                    >
                                      {degreeIssuer.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                    {errors.academicDegrees?.[index]?.issuer &&
                                      errors.academicDegrees?.[index]
                                        ?.issuer && (
                                        <FormHelperText error>
                                          {
                                            errors.academicDegrees?.[index]
                                              ?.issuer
                                          }
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                              </Grid>

                              {/*start date and end date */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    name={`academicDegrees[${index}].date`}
                                  >
                                    {({ field, form }) => {
                                      return (
                                        <DatePicker
                                          {...field}
                                          label="Elmi dərəcəni aldığı tarix"
                                          inputFormat="dd/MM/yyyy"
                                          value={field.value || null}
                                          maxDate={new Date()}
                                          minDate={new Date(1940, 0, 1)}
                                          onChange={(date) => {
                                            setRequestButton(true);
                                            form.setFieldValue(
                                              `academicDegrees[${index}].date`,
                                              date
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                form.errors?.academicDegrees?.[
                                                  index
                                                ]?.date
                                              }
                                              error={Boolean(
                                                form.errors?.academicDegrees?.[
                                                  index
                                                ]?.date
                                              )}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`academicDegrees[${index}].note`}
                                    as={TextField}
                                    label="Qeyd "
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `academicDegrees[${index}].note`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.academicDegrees?.[index]?.note &&
                                      Boolean(
                                        errors?.academicDegrees?.[index]?.note
                                      )
                                    }
                                    helperText={
                                      errors?.academicDegrees?.[index]?.note &&
                                      errors?.academicDegrees?.[index]?.note
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    value={
                                      values?.academicDegrees[index]?.value
                                    }
                                    as={TextField}
                                    name={`academicDegrees[${index}].value`}
                                    label="Elmi dərəcənin dəyəri"
                                    fullWidth
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "19px",
                                          }}
                                        >
                                          &#8380;
                                        </span>
                                      ),
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </>
                            <Button
                              sx={
                                formCorrection
                                  ? { visibility: "visible" }
                                  : { visibility: "hidden" }
                              }
                              variant="contained"
                              color="error"
                              type="button"
                              style={{
                                marginBottom: "10px",
                              }}
                              onClick={() => {
                                setRequestButton(true);
                                remove(index);
                                const updatedArray =
                                  values.academicDegrees.filter(
                                    (item) => item.id !== index
                                  );
                                controlFormForSubmitting({
                                  academicDegrees: updatedArray,
                                });
                              }}
                            >
                              Ləğv et
                            </Button>
                          </div>
                        ))}
                        <Button
                          sx={
                            formCorrection
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                          variant="contained"
                          color="secondary"
                          style={{ marginBottom: "10px", marginTop: "20px" }}
                          onClick={() => {
                            let newId = dataIndex + 1;
                            setDataIndex(newId);
                            push({
                              id: newId,
                            });
                          }}
                        >
                          Əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  <Grid
                    style={
                      formCorrection
                        ? { visibility: "visible", marginTop: "10px" }
                        : { visibility: "hidden" }
                    }
                  >
                    <Button
                      type="submit"
                      style={
                        !requestButton
                          ? { pointerEvents: "none" }
                          : {
                              backgroundColor: "#28358a",
                              pointerEvents: "auto",
                            }
                      }
                      variant="contained"
                      color="secondary"
                    >
                      Dəyişikliklər sorğusu göndər
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewAcademicDegree;
