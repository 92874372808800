import * as Yup from "yup";
import Column from "../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../constants";

export const overTimeValidationSchema = Yup.object().shape({
  type: Yup.string().required("Növ seçilməyib"),
  overtime: Yup.array().of(
    Yup.object().shape({
      employee: Yup.string().required("Əməkdaş seçimi zəruridir"),
      contract: Yup.string().required("Müqavilə seçilməyib."),
      reason: Yup.string().max(200, "Səbəb maksimum 200 simvol olmalıdır"),
      contractItem: Yup.array()
        .of(Yup.string().required("Əmək məcəlləsinin maddəsi zəruridir"))
        .min(1, "Əmək məcəlləsinin maddəsi seçilməlidir"),
      footer: Yup.array().of(
        Yup.object().shape({
          date: Yup.date().required("Tarix seçimi zəruridir"),
          hour: Yup.number()
            .typeError("Saat rəqəm olmalıdır")
            .required("Saat daxil edilməlidir")
            .positive("Saat müsbət olmalıdır"),
        })
      ),
    })
  ),
});

export const overtimeColumns = [
  new Column({
    path: "firstname",
    headerName: "Əməkdaşın Adı",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "lastname",
    headerName: "Əməkdaşın soyadı",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "fathername",
    headerName: "Əməkdaşın ata adı",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "docNumber",
    headerName: "Əmək müqaviləsinin nömrəsi",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "structure",
    headerName: "Struktur",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "subStructure",
    headerName: "Sub-struktur",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "position",
    headerName: "Vəzifə",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "date",
    headerName: "Tarix(lər)",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "hour",
    headerName: "Saat(lar)",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "type",
    headerName: "İş vaxtından artıq işə çıxmanın növü",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "contractItem",
    headerName: "Ə.M. maddəsi",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "reason",
    headerName: "Səbəb",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "paymentType",
    headerName: "Ödənişin növü",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
];

export const overtimeMockData = [
  {
    firstname: "Eli",
    lastname: "Eliyev",
    fathername: "Veli",
    docNumber: "ƏM80000",
    structure: "Blink-BI",
    subStructure: "FE Team",
    position: "Frontend Developer",
    date: "01/01/2025",
    hour: "8",
    type: "İş vaxtından artıq işə çıxma",
    contractItem: "Ə.M. maddə 99.4",
    reason: "Siqaret çəkdiyi üçün",
    paymentType: "2x (2qat)",
    overtimeTypes: [
      {
        label: "1. İş vaxtından artıq işə çıxma",
        value: "overtime",
      },
      {
        label: "2. Qeyri iş günlərində işə çıxma",
        value: "weekend",
      },
    ],
    contractItemTypes: {
      overtime: [
        {
          label:
            "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə;",
          value: "0",
        },
        {
          label:
            "Maddə 101 b. su, qaz və elektrik təchizatı, isitmə, kanalizasiya, rabitə və digər kommunal müəssisələrində işlərin, xidmətlərin pozulmasına səbəb olan gözlənilməz hadisələrin nəticələrini aradan qaldırmaq üçün zəruri işlərin görülməsini təmin etmək üçün;",
          value: "1",
        },
        {
          label:
            "Maddə 101 c. başlanmış və istehsalın texniki şəraitinə görə iş gününün sonunadək tamamlana bilməyən işlərin dayandırılması avadanlıqların, əmtəələrin qarşısıalınmaz korlanması, sıradan çıxması təhlükəsi zamanı işlərin tamamlanması zəruriyyəti olduqda;",
          value: "2",
        },
        {
          label:
            "Maddə 101 ç. işçilərin əksəriyyətinin işinin dayandırılmasına səbəb olan sıradan çıxmış mexanizmlərin, qurğuların təmiri, bərpası ilə əlaqədar işlərin görülməsi zərurəti olduqda;",
          value: "3",
        },
        {
          label:
            "Maddə 101 d. əvəz edən işçinin işdə olmaması ilə əlaqədar işə fasilə verilməsinə yol vermək mümkün olmadıqda.",
          value: "4",
        },
      ],
      weekend: [
        {
          label:
            "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə;",
          value: "5",
        },
        {
          label:
            "Maddə 101 b. su, qaz və elektrik təchizatı, isitmə, kanalizasiya, rabitə və digər kommunal müəssisələrində işlərin, xidmətlərin pozulmasına səbəb olan gözlənilməz hadisələrin nəticələrini aradan qaldırmaq üçün zəruri işlərin görülməsini təmin etmək üçün;",
          value: "6",
        },
        {
          label:
            "Maddə 107 Fasiləsiz istehsalatlar, ticarət, ictimai iaşə, rabitə, nəqliyyat və digər xidmət müəssisələri",
          value: "7",
        },
      ],
    },
  },
];
