import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validateDate } from "../pages/documentation/orders/create-orders/order-validations";
import {
  getActiveStructuresEmptyStaff,
  getCommand_API_SERVICE,
  getEmployeeId_API_SERVICE,
  getFillingVacantPositionOrder_SERVICE,
  postFillingVacantPositionOrder_SERVICE,
  putVacantOrder_SERVICE,
} from "../services/ordersServices";
import { enqueueSnackbar } from "notistack";
import { removeEmptyStrings } from "../utils/removeEmptyString";
import { getAllStructuresEmptyStaff } from "../api/api";
import moment from "moment";

function useTemporaryReplacement(mainId) {
  const [value, setValue] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const value = searchParams.get("value");
    setValue(value);
  }, [searchParams]);

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [staffId, setStaffId] = useState();
  const [persent, setPersent] = useState(false);
  const [positionId, setPositionId] = useState();
  const [contractPosition, setContractPosition] = useState();
  const [loading, setIsLoading] = useState(false);
  const [commandData, setCommandData] = useState([]);
  const [structureId, setStructureId] = useState("");
  const [replacedData, setReplacedData] = useState([]);
  const [contractName, setContractName] = useState("");
  const [allStructures, setAllStructures] = useState([]);
  const [nestedChildren, setNestedChildren] = useState([]);
  const [replacedContractData, setReplacedContractData] = useState([]);
  const [replacedCurrentContractData, setReplacedCurrentContract] =
    useState(null);
  const [oldStructure, setOldStructure] = useState({
    organization: {},
    _id: "",
  });
  const [emptyPositionDatas, setEmptyPositionDatas] = useState({
    note: "",
    currency: "AZN",
    structure: "",
    orderType: "",
    endDate: null,
    startDate: null,
    oldStructure: "",
    positionLevel: "",
    vacantStaffId: "",
    paymentType: "amount",
    endWorkYear: null,
    startWorkYear: null,
    personalCategory: "",
    newContractDate: null,
    newContractNumber: "",
    additionToSalary: null,
  });

  // useEffect(() => {
  //   if (!persent) {
  //     setEmptyPositionDatas((prevData) => ({
  //       ...prevData,
  //       currency: "",
  //     }));
  //   }
  // }, [persent]);

  const [replacedEmployee, setReplacedEmployee] = useState({
    employeeId: "",
    tabelNumber: "",
    empStaffId: "",
    vacantStaffId: "",
  });
  const validateForm = () => {
    const newErrors = {};
    newErrors.startDate = validateDate(emptyPositionDatas.startDate);
    newErrors.endDate = validateDate(emptyPositionDatas.endDate);
    newErrors.currency = validateDate(emptyPositionDatas.currency);
    if (emptyPositionDatas.endDate < emptyPositionDatas.startDate) {
      newErrors.endDate = "Bitmə tarixi başlama tarixindən əvvəl ola bilməz";
    }
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const isFormValid = () => {
    return (
      !emptyPositionDatas?.startDate ||
      !emptyPositionDatas?.endDate ||
      !emptyPositionDatas?.paymentType ||
      !emptyPositionDatas?.additionToSalary ||
      !staffId ||
      !replacedEmployee?.employeeId
    );
  };

  const createFillingVacantPositionOrder = async () => {
    try {
      const payload = {
        ...emptyPositionDatas,
        vacantStaffId: replacedEmployee?.vacantStaffId,
        staffId: mainId,
      };
      delete payload.structure;
      if (emptyPositionDatas.endWorkYear) {
        payload.endWorkYear = moment(emptyPositionDatas.endWorkYear).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.startDate) {
        payload.startDate = moment(emptyPositionDatas.startDate).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.endDate) {
        payload.endDate = moment(emptyPositionDatas.endDate).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.newContractDate) {
        payload.newContractDate = moment(
          emptyPositionDatas.newContractDate
        ).format("MM/DD/yyyy");
      }
      if (emptyPositionDatas.startWorkYear) {
        payload.startWorkYear = moment(emptyPositionDatas.startWorkYear).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.additionToSalary) {
        payload.additionToSalary = Number(payload.additionToSalary);
      }

      if (!persent) {
        delete payload.currency;
      }

      const result = removeEmptyStrings(payload);

      let final = {
        docModel: "Employee",
        subjects: [replacedEmployee.employeeId],
        data: {
          ...result,
        },
      };
      let response = await postFillingVacantPositionOrder_SERVICE(
        contractName,
        final
      );
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      validateForm();
    }
  };
  const updateVacantorder = async () => {
    try {
      const payload = {
        ...emptyPositionDatas,
        vacantStaffId: replacedEmployee?.vacantStaffId.value
          ? replacedEmployee?.vacantStaffId.value
          : replacedEmployee?.vacantStaffId,
        staffId: mainId?.value ? mainId?.value : mainId,
        paymentType: emptyPositionDatas?.paymentType?.value
          ? emptyPositionDatas?.paymentType?.value
          : emptyPositionDatas?.paymentType,
      };

      if (emptyPositionDatas.startDate) {
        payload.startDate = moment(emptyPositionDatas.startDate).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.endDate) {
        payload.endDate = moment(emptyPositionDatas.endDate).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.endWorkYear) {
        payload.endWorkYear = moment(emptyPositionDatas.endWorkYear).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.newContractDate) {
        payload.newContractDate = moment(
          emptyPositionDatas.newContractDate
        ).format("MM/DD/yyyy");
      }
      if (emptyPositionDatas.startWorkYear) {
        payload.startWorkYear = moment(emptyPositionDatas.startWorkYear).format(
          "MM/DD/yyyy"
        );
      }
      if (emptyPositionDatas.additionToSalary) {
        payload.additionToSalary = Number(payload.additionToSalary);
      }
      if (!persent) {
        delete payload.currency;
      }
      const result = removeEmptyStrings(payload);
      let final = {
        docModel: "Employee",
        subjects: [replacedEmployee.employeeId],
        data: {
          ...result,
        },
      };
      let response = await putVacantOrder_SERVICE(contractName, value, final);
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      validateForm();
    }
  };

  const getFillingVacantPositionOrder = async () => {
    const filter = [
      { column: "status", operator: "equalsTo", value: "active" },
    ];

    try {
      const res = await getFillingVacantPositionOrder_SERVICE(
        encodeURIComponent(JSON.stringify(filter))
      );
      if (res) {
        setReplacedData(res.data.employees);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCommandApi = async () => {
    try {
      if (value) {
        const res = await getCommand_API_SERVICE(value, contractName);
        setCommandData(res.data);
        setReplacedEmployee({
          ...replacedEmployee,
          employeeId: res?.data?.subjects?.[0]?.subject?._id,
          tabelNumber: res?.data?.subjects?.[0]?.subject?.tableNumber,
          vacantStaffId: res?.data?.data?.vacantStaffId?.value,
          empStaffId: res?.data?.data?.staffId?.value,
        });
        setEmptyPositionDatas({
          ...emptyPositionDatas,
          startDate: res?.data?.data?.startDate?.value,
          newContractDate: res?.data?.data?.newContractDate?.value,
          endDate: res?.data?.data?.endDate?.value,
          paymentType: res?.data?.data?.paymentType?.value,
          additionToSalary: res?.data?.data?.additionToSalary?.value,
          currency: res?.data?.data?.currency?.value,
          note: res?.data?.data?.note?.value,
        });
        setOldStructure({
          ...oldStructure,
          organization: res?.data?.data?.hierarchies
            ? res?.data?.data?.hierarchies?.value?.value?.vacantStaffData
                ?.vacantOrganization
            : { name: res?.data?.baseStructures?.[0]?.organization?.name },
          _id: res?.data?.data?.hierarchies
            ? res?.data?.data?.hierarchies?.value?.value?.vacantStaffData
                ?.vacantOrganization?.structureId
            : res?.data?.baseStructures?.[0]?._id,
        });
        if (commandData.length > 0) {
          setReplacedCurrentContract(
            replacedContractData?.find((s) =>
              s?._id === commandData?.data?.staffId?.value?.value
                ? commandData?.data?.staffId?.value?.value
                : commandData?.data?.staffId?.value
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContract = async () => {
    try {
      const res = await getEmployeeId_API_SERVICE(replacedEmployee.employeeId);
      if (res) {
        setReplacedContractData(res.data?.staffs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFillingVacantPositionOrder();
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (value) {
      getCommandApi();
    }
  }, [value]);
  useEffect(() => {
    replacedEmployee?.employeeId && getContract();
  }, [replacedEmployee?.employeeId]);
  useEffect(() => {
    if (commandData?.data?.staffId?.value && replacedContractData?.length > 0) {
      const matchingContract = replacedContractData?.find((s) =>
        s?._id === commandData?.data?.staffId?.value?.value
          ? commandData?.data?.staffId?.value?.value
          : commandData?.data?.staffId?.value
      );

      if (matchingContract) {
        setReplacedCurrentContract(matchingContract);
      }
    }
  }, [commandData, replacedContractData]);
  const handleReplacedEmployeeChange = (value) => {
    const emp = replacedData?.find((e) => e?._id === value);
    setReplacedEmployee({
      ...replacedEmployee,
      employeeId: value || "",
      tabelNumber: emp?.tableNumber || "",
    });
  };

  const handleReplacedCurrentContract = (id) => {
    const value = replacedContractData?.find((s) => s?._id === id);
    setReplacedCurrentContract(
      replacedContractData?.find((s) => s?._id === id)
    );
    setReplacedEmployee({
      ...replacedEmployee,
      vacantStaffId: value?.contract?.data?.staffId,
      // trAnJobEmpStaffId: value?.contract?.data?.staffId,
    });
  };
  async function getPositionsData() {
    if (structureId || oldStructure?._id) {
      try {
        const res = await getActiveStructuresEmptyStaff({
          structureId: structureId ? structureId : oldStructure?._id,
        });
        setNestedChildren(res?.data?.children);
      } catch (err) {
        console.log(err);
      }
    }
  }
  useEffect(() => {
    const handleAllStructures = async () => {
      try {
        const response = await getActiveStructuresEmptyStaff({
          getStructures: true,
        });
        setAllStructures(response.data);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleAllStructures();
  }, []);
  useEffect(() => {
    (structureId || oldStructure?._id) && getPositionsData();
  }, [structureId, oldStructure?._id]);
  const handleInputChange = (name, value) => {
    setEmptyPositionDatas({
      ...emptyPositionDatas,
      [name]: value,
    });
  };

  const handleInputChange_TemporaryReplacement = (name, value) => {
    setEmptyPositionDatas((prevData) => {
      if (value === "percent") {
        prevData = {
          ...prevData,
          currency: "",
        };
      } else {
        prevData = {
          ...prevData,
          currency: "AZN",
        };
      }
      prevData = {
        ...prevData,
        [name]: value,
      };
      return prevData;
    });
  };
  const handleSubmit = async (e) => {
    if (validateForm()) {
      e.preventDefault();
      setIsLoading(true);
      await createFillingVacantPositionOrder();
      setIsLoading(false);
    }
  };
  return {
    errors,
    persent,
    loading,
    navigate,
    setPersent,
    setStaffId,
    commandData,
    isFormValid,
    structureId,
    handleSubmit,
    oldStructure,
    setPositionId,
    replacedData,
    allStructures,
    setStructureId,
    nestedChildren,
    setContractName,
    replacedEmployee,
    updateVacantorder,
    handleInputChange,
    emptyPositionDatas,
    setContractPosition,
    replacedContractData,
    setEmptyPositionDatas,
    replacedCurrentContractData,
    handleReplacedEmployeeChange,
    handleReplacedCurrentContract,
    createFillingVacantPositionOrder,
    handleInputChange_TemporaryReplacement,
  };
}

export default useTemporaryReplacement;
