import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { putExplanations } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";
import moment from "moment";
import { APP_EXP_DOCMODEL } from "../../../constants";
import { sendExplanation_ApplicationRequest_SERVICE } from "../../../services/cabinetServices";

// CSS

export default function ExplanationData({
  row,
  viewDialogText,
  open,
  onClose,
  click,
}) {
  const [inputValue, setInputValue] = useState("");
  async function sendExplanationData() {
    try {
      const { data } = await sendExplanation_ApplicationRequest_SERVICE(
        row?._id,
        exp_desc.current
      );

      toast.info("Məlumat göndərildi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // fetchData();
      onClose();
    } catch (error) {
      toast.error("Xəta baş verdi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
  let exp_desc = useRef();
  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
        <ToastContainer />

        <DialogTitle>
          İzahat (Xahiş olunur aşağıdakı xanaya izahatınızı qeyd
          edəsiniz.Müvafiq sənəd başlıqları sənədə avtomatik əlavə olunacaq.){" "}
        </DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <div
            style={{
              margin: "5px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid>
              <Grid>
                Məzmun: {APP_EXP_DOCMODEL[viewDialogText?.state?.content]}
              </Grid>
              <Grid>
                Yaradılma tarixi :{" "}
                {moment(viewDialogText?.state?.date)?.format("MM/DD/YYYY")}
              </Grid>
              <Grid className="mt-4">
                <TextField
                  label="İzahat"
                  multiline
                  rows={8}
                  fullWidth
                  onChange={(e) => {
                    exp_desc.current = e.target.value;
                  }}
                />
              </Grid>
              <Grid className="text-right mt-4">
                <Button variant="contained" color="warning">
                  Sənədə bax
                </Button>
                <Button
                  variant="contained"
                  className="ms-2"
                  // onClick={sendApplicationData}
                >
                  Göndər
                </Button>
              </Grid>
            </Grid>
            <button
              onClick={sendExplanationData}
              style={
                inputValue.length
                  ? {
                      pointerEvents: "auto",
                      border: "none",
                      backgroundColor: "#2a3889",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "5px",
                    }
                  : {
                      pointerEvents: "none",
                      border: "none",
                      backgroundColor: "#2a3889",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "5px",
                    }
              }
            >
              Göndər
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={click} color="primary">
            Bağla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
