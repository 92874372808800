import { useEffect, useState } from "react";
import DataTable from "../../../components/layouts/dataTable/DataTable";
import Table from "../../../components/table/Table";

import {
  APP_EXP_DOCMODEL,
  OPERATORS_CONTAINING,
  STAFFS_STATUS_TYPE,
  STAFF_DROPDOWN_VALUE,
} from "../../../constants";

import { getExplanationsApplications } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";
import { Button, Grid } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import { normalizeDate } from "../../../utils/helperFunctions";
import Column from "../../../models/table/Column";
import { getStaffTracking } from "../../../services/staffTracking/StaffTrackingServices";
import ButtonCustom from "../../../components/ui/Button/ButtonCustom";

export default function Explanations({ dialog }) {
  const [explanations, setExplanations] = useState([]);
  const [totalExpRow, setTotalExpRow] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [contId, setContId] = useState("");
  const [showContDialog, setShowContDialog] = useState(false);
  function openExpDialog(paramsrow) {
    dialog(paramsrow);
  }

  // const handlePaginationChange = (pageSize, table) => {
  //   if (!pageSize) return;
  //   const value = pageSize.page * pageSize.pageSize;
  //   setOffset(value);
  //   setLimit(parseInt(pageSize.pageSize));
  // };

  function contentConverter(data) {
    switch (data) {
      case "ExclusionApplication":
        return "Kənarlaşdırma";
      case "UnknownReasonApplication":
        return "Naməlum səbəb";
      default:
        return "";
    }
  }

  useEffect(() => {
    paginationHandler();
  }, [limit, offset, filters]);

  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        applicationRequestType: "explanation",
        filter: filters,
      };
      const { data } = await getExplanationsApplications(payload);
      setExplanations(data?.records);
      setTotalExpRow(data?.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
      return;
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const handleRowDialogBtnClick = async (id) => {
    // // let { data } = await fetchChangeRequest(id);
    // console.log(data);
    setContId(id);
    setShowContDialog(true);
  };

  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      disableColSearch: true,
      renderCell: (row, column) => {
        return (
          <div
            style={{
              position: "relative",
              display: "grid",
              columnGap: "5px",
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
              height: "100%",
            }}
          >
            <ButtonCustom
              onClick={() => {
                handleRowDialogBtnClick(row._id);
              }}
              children={<RemoveRedEye />}
              style={{ height: "100%", background: "none" }}
              styleContainer={{ height: "auto" }}
            />
          </div>
        );
      },
    }),
    new Column({
      path: "activity.explanationAsFile.docNumber",
      headerName: "Sənəd no",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "docModel",
      headerName: "Məzmun",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row, column) => {
        return APP_EXP_DOCMODEL[row.docModel];
      },
    }),
    new Column({
      path: "createdAt",
      headerName: "Tarix",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      path: "adresat",
      headerName: "Adresat",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
  ];

  return (
    <>
      <Table
        columns={columns}
        data={explanations}
        totalRows={totalExpRow}
        offset={offset}
        setOffset={(value) => setOffset(value)}
        limit={limit}
        setLimit={(value) => setLimit(value)}
        isLoading={isLoading}
        applyFiltersHandler={applyFiltersHandler}
        cancelAppliedFilters={cancelAppliedFiltersHandler}
      />
    </>
  );
}
