import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import swal from "sweetalert";
import { useTheme } from "@mui/material/styles";
import {
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { Person } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  empConstModifeValue,
  empConstValidation,
  weekDays,
} from "../../../../labour-contract-changes/dialog/empConstMock/empConstMock";
import { removeEmptyStrings } from "../../../../../utils/removeEmptyString";
import Administrative from "../../../../labour-contract-changes/dialog/component/Administrative";
import { getPagesData } from "../../../../../services/documentations/ContractServices";
import {
  getEmployeesForLabourContractByID_SERVICE,
  updateLabourContractAfterEMAS_SERVICE,
} from "../../../../../services/documentServices";
import LabourPeriod from "../../../../labour-contract-changes/dialog/component/LabourPeriod";
import InformationAboutParties from "../../../../labour-contract-changes/dialog/component/InformationAboutParties";
import Wage from "../../../../labour-contract-changes/dialog/component/Wage";
import Protection from "../../../../labour-contract-changes/dialog/component/Protection";
import WorkVacationTime from "../../../../labour-contract-changes/dialog/component/WorkVacationTime";
import VacationForDialog from "../../../../labour-contract-changes/dialog/component/VacationForDialog";
import PhysicalEducationSport from "../../../../labour-contract-changes/dialog/component/PhysicalEducationSport";
import SocialProtection from "../../../../labour-contract-changes/dialog/component/SocialProtection";
import RegulationPropertyRelation from "../../../../labour-contract-changes/dialog/component/RegulationPropertyRelation";
import { orderEmpInitialValues } from "./components/order-emp-initialvalues";
import OrderJobRelated from "./components/order-job-related";
import { orderEmpConstModifeValue } from "./components/order-emp-modifie-value";
import { handleApiError } from "../../../../../api/changeRequests";

const InputCont = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 10px;
`;

export default function EmployeeContractModal({
  fetchData,
  detailData,
  open,
  setOpen,
  oldData,
  selectedCont,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [emps, setEmps] = useState([]);
  const [showCreateOrder, setShowCreateOrder] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);

  const [selectedEmp, setSelectedEmp] = useState("");
  const [selectedEmpData, setSelectedEmpData] = useState(null);
  const [positionId, setPositionId] = useState("");
  const [oldContract, setOldContract] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [banks, setBanks] = useState([]);
  console.log(selectedCont);

  const handleFetchEmps = async () => {
    try {
      const response = await getEmployeesForLabourContractByID_SERVICE();
      const res = await getPagesData("reasonOfTemporaryContract");
      setSelectedValue(res?.contracts?.static?.labour);
      setEmps(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleFetchEmps();
  }, []);
  const handleClose = () => {
    setOpen(false);
    setShowSubmit(false);
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const modifeValue = orderEmpConstModifeValue(
      values,
      selectedCont,
      detailData,
      oldData
    );
    console.log(modifeValue);

    const result = removeEmptyStrings(modifeValue);
    if (showSubmit) {
      try {
        const willSubmit = await swal({
          title: "Əminsiniz mi?",
          text: "Mövcud müqaviləyə dəyişiklik etməyə əminsiniz?",
          icon: "warning",
          dangerMode: true,
          buttons: ["Ləğv et", "Tətbiq et"],
        });
        if (willSubmit) {
          const resp = await updateLabourContractAfterEMAS_SERVICE(result);
          if (resp) {
            handleClose();
            swal(
              "Tamamlandı!",
              "Əmək müqavilələrinə dəyişiklik sorğusu göndərildi!",
              "success"
            );
            resetForm();
            await fetchData();
          }
        }
      } catch (error) {
        handleApiError(error);
      }
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: "70vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }} id="responsive-dialog-title">
          {"Ə.M. dəyişiklik"}
        </DialogTitle>
        <DialogContent sx={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Formik
              initialValues={orderEmpInitialValues(oldData)}
              enableReinitialize={true}
              validationSchema={empConstValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                resetForm,
                errors,
                setSubmitting,
                submitForm,
                isValid,
              }) => (
                <>
                  <Form style={{ width: "100%" }}>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          overflow: "scroll",
                        }}
                      >
                        <>
                          <InformationAboutParties
                            errors={errors}
                            values={values}
                            oldData={oldData}
                            setFieldValue={setFieldValue}
                            selectedValue={selectedValue}
                          />
                          <LabourPeriod
                            errors={errors}
                            values={values}
                            oldData={detailData}
                            setFieldValue={setFieldValue}
                            selectedValue={selectedValue}
                          />
                          <Wage
                            banks={banks}
                            values={values}
                            weekDays={weekDays}
                            setFieldValue={setFieldValue}
                            selectedValue={selectedValue}
                          />
                          <Protection
                            values={values}
                            setFieldValue={setFieldValue}
                            selectedValue={selectedValue}
                            inventoryTypes={inventoryTypes}
                          />
                          <WorkVacationTime
                            values={values}
                            weekDays={weekDays}
                            selectedValue={selectedValue}
                            setFieldValue={setFieldValue}
                          />
                          <VacationForDialog
                            values={values}
                            selectedValue={selectedValue}
                            setFieldValue={setFieldValue}
                            selectedEmpData={selectedEmpData}
                          />
                          <PhysicalEducationSport
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                          <SocialProtection
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                          <RegulationPropertyRelation
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                          <Administrative
                            values={values}
                            oldContract={oldContract}
                            setFieldValue={setFieldValue}
                          />
                          <OrderJobRelated
                            values={values}
                            oldData={oldData}
                            detailData={detailData}
                            positionIds={positionId}
                            selectedEmp={selectedEmp}
                            selectedCont={selectedCont}
                            setFieldValue={setFieldValue}
                          />
                        </>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Typography
                          sx={{ paddingLeft: "10px", marginBottom: "10px" }}
                          variant="h4"
                        >
                          Əmək müqaviləsinə dəyişiklik ediləcək əməkdaşı seçin:
                        </Typography>
                        <InputCont>
                          <FormControl sx={{ pointerEvents: "none" }} fullWidth>
                            <TextField value={detailData?.data?.employeeName} />
                          </FormControl>

                          <FormControl
                            fullWidth
                            sx={{ marginTop: "10px", pointerEvents: "none" }}
                          >
                            <TextField value={detailData?.data?.docNumber} />
                          </FormControl>

                          <Grid
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              pointerEvents: "none",
                            }}
                          >
                            <Field name={`labourContractChangeDate`}>
                              {({ field, form }) => (
                                <DatePicker
                                  {...field}
                                  inputFormat="dd/MM/yyyy"
                                  value={detailData?.data?.startWorkYear}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                              )}
                            </Field>
                          </Grid>
                        </InputCont>
                        <div
                          style={{
                            width: "96%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              window.open(`/employees/${selectedEmp}/view`)
                            }
                            sx={{ borderRadius: "5px" }}
                            disabled={!selectedEmp}
                          >
                            <Person />
                            <Typography>Əməkdaş haqqında</Typography>
                          </IconButton>
                        </div>
                      </div>
                      <Dialog
                        open={showCreateOrder}
                        onClose={() => setShowCreateOrder(false)}
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogTitle id="form-dialog-title">
                          Əmək müqaviləsinin rəsmiləşdirməsi formalaşsınmı?
                        </DialogTitle>

                        <DialogActions>
                          <Button
                            onClick={() => {
                              setShowSubmit(true);
                              setShowCreateOrder(false);
                              setFieldValue("createOrder", false);
                              submitForm();
                            }}
                            color="primary"
                          >
                            Xeyr
                          </Button>
                          <Button
                            onClick={() => {
                              setShowCreateOrder(false);
                              setFieldValue("createOrder", true);
                              submitForm();
                            }}
                            color="primary"
                          >
                            Bəli
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        paddingBottom: "15px",
                      }}
                    >
                      <Button
                        color="error"
                        variant="contained"
                        title="Ləğv et"
                        sx={{ width: "25%" }}
                        autoFocus
                        onClick={handleClose}
                      >
                        <Typography>Ləğv et</Typography>
                      </Button>
                      <Button
                        type={
                          !oldData?.isVerified &&
                          oldData?.staffId?._id !== values?.jobRelated?.staffId
                            ? "button"
                            : "submit"
                        }
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => {
                          setShowSubmit(true);
                          setShowCreateOrder(
                            !oldData?.isVerified &&
                              oldData?.staffId?._id !==
                                values?.jobRelated?.staffId
                          );
                        }}
                        sx={{ width: "25%" }}
                        disabled={isSubmitting || !isValid}
                      >
                        Tətbiq et
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
