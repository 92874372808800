import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";
import { supplementalWagesValidation } from "../employees/Validations";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "../ui/Accordion/Accordion";

const EditSalarySupplements = ({
  employeeById,
  submitted,
  setSubmitted,
  setOpenAddDialog,
  setSelectedType,
}) => {
  const [supplementName, setSupplementName] = useState([]);
  const [pastData, setPastData] = useState([]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const modifiedValues = {
      ...values,
      supplementalWages:
        values?.supplementalWages &&
        values?.supplementalWages.map((supplement) => {
          const { ...rest } = supplement;
          return {
            ...rest,
          };
        }),
    };
    // Handle form submission
    await updateEmployee(employeeById._id, modifiedValues);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  useEffect(() => {
    const handleSupplements = async () => {
      try {
        const response = await getCommonData("supplementalWageGeneral");
        const supplement = response?.data || [];
        setSupplementName(supplement);
        const combinedData = [];
        if (supplement.length) {
          combinedData.push({ supplement: [...supplement] });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleSupplements();
  }, [submitted]);

  const handleWageSelect = (item) => {
    const selectWage = supplementName.find((wage) => {
      return wage?.name === item;
    });
    return selectWage;
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    supplementName &&
      supplementName.push(employeeById?.supplementalWages[index]?.name);
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Əməkhaqqına əlavələr</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              supplementalWages: employeeById.supplementalWages || [], // Set the initial laborActivity values from employeeById
            }}
            onSubmit={handleSubmit}
            validationSchema={supplementalWagesValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="supplementalWages">
                    {({ push, remove }) => (
                      <div>
                        {values?.supplementalWages &&
                          values?.supplementalWages.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Əməkhaqqına əlavələr
                                </Typography>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.supplementalWages?.[index]
                                          ?.name &&
                                        errors?.supplementalWages?.[index]
                                          ?.name &&
                                        Boolean(
                                          errors?.supplementalWages?.[index]
                                            ?.name
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`supplementalWages[${index}].name`}
                                      >
                                        Əməkhaqqına əlavənin səbəbi *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`supplementalWages[${index}].name`}
                                        labelId={`supplementalWages[${index}].name`}
                                        label="Əməkhaqqına əlavənin səbəbi *"
                                        id={`supplementalWages[${index}].name`}
                                        onChange={(event) => {
                                          checkUniqueness(
                                            values.supplementalWages,
                                            "name",
                                            event,
                                            setFieldValue,
                                            `supplementalWages[${index}]`,
                                            "əməkhaqqına əlavənin səbəbi",
                                            handleWageSelect
                                          );
                                        }}
                                        error={
                                          touched?.supplementalWages?.[index]
                                            ?.name &&
                                          errors?.supplementalWages?.[index]
                                            ?.name &&
                                          Boolean(
                                            errors?.supplementalWages?.[index]
                                              ?.name
                                          )
                                        }
                                        fullWidth
                                      >
                                        {supplementName &&
                                          [...new Set(supplementName)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "supplement",
                                                  item
                                                )}
                                                key={index}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "supplementalWageGeneral",
                                              { name: "Ümumi əlavələr" },
                                            ]);
                                          }}
                                        >
                                          Yeni əməkhaqqına əlavənin səbəbi əlavə
                                          et
                                        </Button>
                                      </Field>
                                    </FormControl>
                                    {touched?.supplementalWages?.[index]
                                      ?.name &&
                                      errors?.supplementalWages?.[index]
                                        ?.name &&
                                      errors?.supplementalWages?.[index]
                                        ?.name && (
                                        <FormHelperText error>
                                          {
                                            errors?.supplementalWages?.[index]
                                              ?.name
                                          }
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        setFieldValue(
                                          `supplementalWages[${index}].value`,
                                          value
                                        );
                                      }}
                                      value={
                                        values.supplementalWages[index].value
                                      }
                                      as={TextField}
                                      name={`supplementalWages[${index}].value`}
                                      label="Əməkhaqqına əlavənin dəyəri"
                                      fullWidth
                                      error={
                                        touched.supplementalWages?.[index]
                                          ?.value &&
                                        Boolean(
                                          errors.supplementalWages?.[index]
                                            ?.value
                                        )
                                      }
                                      helperText={
                                        touched.supplementalWages?.[index]
                                          ?.value &&
                                        errors.supplementalWages?.[index]?.value
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Əməkhaqqına əlavəni sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          Əməkhaqqına əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditSalarySupplements;
