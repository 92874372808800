import * as Yup from "yup";
import { endOfDay, isValid } from "date-fns";

import { checkUniqueness } from "../../../api/api";
import moment from "moment";

const Swift_Leng_Min = 2,
  educationMin = 2,
  Position_Min = 2,
  Workplace_Min = 2,
  nameMinLength = 2,
  Structure_Min = 2,
  birthMinLength = 2,
  Organization_Min = 2,
  fullMinLength = 2;

const finMin = 7,
  finMax = 7,
  AA_Length = 7,
  MYI_Length = 7,
  phoneNumber = 7;
const IBAN_Leng = 28;

const Structure_Max = 100,
  Organization_Max = 100,
  Position_Max = 100;

const fullMaxLength = 150,
  birthMaxLength = 150,
  emailMax = 150,
  inventarLeng = 150,
  bankNameLeng = 150;

const educationMax = 500,
  Workplace_Max = 500,
  RewardsReprimandsLeng = 500,
  bankCode = 500,
  Iban_Leng = 500;

const actualAddress_Max = 255;
const foreignFin = 6;
const AZA_Length = 8;
const VOEN_Leng = 10;
const SSN_Length = 13;
const nameMaxLength = 50,
  homeNumberMax = 50;

const registrationNumber = 25;
const registrationPlate = 15;
const Swift_Leng_Max = 250;
const actualAddress_Min = 5;
const taxAccountNumber = 11;
const accountNumber_Leng = 100;
const cityMax = 70;
const pinRegex = /^[a-hj-np-zA-HJ-NP-Z0-9]*$/;
const cardExpirationDateRegex = /^(0[1-9]|1[0-2])\/(20[2-9][0-9])$/;

const letterNumberRegex = /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ0-9]*$/;
const letterRegex = /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ\s]*$/;
const numberRegex = /^[0-9]*$/;
const ninetyYearsAgo = new Date();
ninetyYearsAgo.setFullYear(ninetyYearsAgo.getFullYear() - 84);

const hasFaculties = (val) => {
  switch (val) {
    case "bachelor":
    case "incompleteHigherTill1993":
    case "completeHigherTill1993":
    case "master":
    case "doctoral":
      return true;
    case "primary":
    case "generalSecondary":
    case "completeSecondary":
      return false;
    case "firstVocational":
    case "secondarySpecial":
      return true;
    case "secBasisOfGeneralSec":
    case "secBasisOfCompleteSec":
      return false;
    default:
      return false;
  }
};

export const isDateMoreThan50Years = (dateString) => {
  const enteredDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the date 50 years ago from the current date
  const fiftyYearsAgo = new Date();
  fiftyYearsAgo.setFullYear(currentDate.getFullYear() - 50);

  // Compare the entered date with the date 50 years ago
  return enteredDate < fiftyYearsAgo;
};

async function handleUniqueness(field, value, employeeById) {
  if (!value) return true;

  try {
    const response = await checkUniqueness(field, value, employeeById?._id);
    return response.data.isUnique;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const isEmailUnique = (emailArray) => {
  const filledEmails = emailArray.filter((email) => email !== "");
  const uniqueEmails = new Set(filledEmails);
  return uniqueEmails.size === 1 && filledEmails.length > 1;
};

export const personalInformationValidation = (employeeId) => {
  // const fiftyYearsAgo = new Date();
  // fiftyYearsAgo.setFullYear(fiftyYearsAgo.getFullYear() - 50);
  return Yup.object({
    firstname: Yup.string()
      .matches(
        /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ]+(?: [a-zA-ZüçğıöəşÜÇĞIÖƏŞ]+)*$/,
        "Yalnız hərflər və yalnız bir boşluq qəbul olunur"
      )
      .min(nameMinLength, "Ad minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Ad maksimum 50 hərf olmalıdır")
      .required("Ad daxil edilməlidir"),
    lastname: Yup.string()
      .matches(/^[\p{L}]+$/u, "Yalnız hərflərdən ibarət olmalıdır")
      .min(nameMinLength, "Soyad minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Soyad maksimum 50 hərf olmalıdır")
      .required("Soyad daxil edilməlidir"),
    fathername: Yup.string()
      .matches(
        /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ]+(?: [a-zA-ZüçğıöəşÜÇĞIÖƏŞ]+)*$/,
        "Yalnız hərflər və yalnız bir boşluq qəbul olunur"
      )
      .min(nameMinLength, "Ata adı minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Ata adı maksimum 50 hərf olmalıdır")
      .required("Ata adı daxil edilməlidir"),
    gender: Yup.string().required("Cins daxil edilməlidir"),
    dob: Yup.date()
      .max(new Date(), "Doğum tarixi gələcək tarix ola bilməz.")
      .min(
        ninetyYearsAgo,
        `Doğum tarixi ${moment(ninetyYearsAgo).format(
          "DD/MM/YYYY"
        )} tarixindən əvvəl ola bilməz.`
      )
      .nullable()
      .required("Doğum tarixi düzgün doldurulmalıdır.")
      .test(
        "is-after-idCardIssuanceDate",
        "Doğum tarixi şəxsiyyət vəsiqəsinin verilmə tarixindən sonra ola bilməz.",
        function (value) {
          const { idCardIssuanceDate } = this.parent;
          return !idCardIssuanceDate || value < idCardIssuanceDate;
        }
      ),
    placeOfBirth: Yup.string()
      .min(birthMinLength, "Doğum yeri minimum 2 hərf olmalıdır")
      .max(birthMaxLength, "Doğum yeri maksimum 150 hərf olmalıdır")
      .required("Qeydiyyat ünvanı daxil edilməlidir"),
    idCardNumber: Yup.object().shape({
      code: Yup.string().required("Ş.V/Seriya № -nin kodunu seçin"),
      number: Yup.string()
        .when("code", {
          is: (code) => code === "AA",
          then: Yup.string()
            .min(AA_Length, "Ş.V/Seriya nömrəsi minimum 7 simvol olmalıdır")
            .max(AA_Length, "Ş.V/Seriya nömrəsi maksimum 7 simvol olmalıdır")
            .required("Ş.V/Seriya № -ni daxil edin"),
          otherwise: Yup.string()
            .min(AZA_Length, "Ş.V/Seriya nömrəsi minimum 8 simvol olmalıdır")
            .max(AZA_Length, "Ş.V/Seriya nömrəsi maksimum 8 simvol olmalıdır")
            .required("Ş.V/Seriya № -ni daxil edin"),
        })
        .matches(numberRegex, "Yalnız rəqəmlər qəbul olunur")
        .test(
          "check-cardNumber",
          "Bu Ş.V/Seriya № artıq istifadə edilib",
          async function (value) {
            const { code, number } = this.parent;
            const combinedValue = code + number;
            if (code + number === code + value) {
              return true;
            }
            const response = await handleUniqueness(
              "idCardNumber",
              combinedValue,
              employeeId
            );

            return response;
          }
        ),
    }),
    pin: Yup.string()
      .min(finMin, "FIN nömrəsi minimum 7 simvol olmalıdır")
      .max(finMax, "FIN nömrəsi maksimum 7 simvol olmalıdır")
      .matches(pinRegex, "FIN nömrəsi düzgün deyil")

      .required("FIN nömrəsi daxil edilməlidir")
      .test(
        "check-pin",
        "Bu PIN artıq istifadə edilib",
        async function (value) {
          const { pin } = this.parent;
          if (value === pin) {
            return true;
          }
          const response = await handleUniqueness("pin", value, employeeId);
          return response;
        }
      ),
    idCardIssuanceDate: Yup.date()
      .max(new Date(), "Gələcək tarix ola bilməz.")
      .min(
        ninetyYearsAgo,
        `Tarix ${moment(ninetyYearsAgo).format(
          "DD/MM/YYYY"
        )} tarixindən əvvəl ola bilməz.`
      )
      .nullable()
      .required("Şəxsiyyət vəsiqəsinin verilmə tarixi daxil edilməlidir")
      .test(
        "is-before-dob",
        "Şəxsiyyət vəsiqəsinin verilmə tarixi doğum tarixidən əvvəl ola bilməz.",
        function (value) {
          const { dob } = this.parent;
          return !dob || value > dob;
        }
      ),
    idCardValidityPeriod: Yup.date().when("dob", (dob, schema) => {
      return isDateMoreThan50Years(dob)
        ? schema.nullable().notRequired()
        : schema
            .min(
              new Date(),
              "Şəxsiyyət vəsiqəsinin etibarlılıq tarixi keçmiş tarix ola bilməz."
            )
            .nullable();
      // .required(
      //   "Şəxsiyyət vəsiqəsinin etibarlılıq müddəti daxil edilməlidir"
      // );
    }),
    idCardAuthority: Yup.string().required(
      "Şəxsiyyət vəsiqəsinin verən orqanı daxil edilməlidir"
    ),
    nationality: Yup.string().required("Vətəndaşlıq daxil edilməlidir"),
    ssn: Yup.string()
      .matches(/^\d+$/, {
        message:
          "Sosial Təhlükəsizlik Nömrəsi (SSN) yalnız rəqəmlərdən ibarət olmalıdır",
      })
      .length(
        SSN_Length,
        "Sosial Təhlükəsizlik Nömrəsi (SSN) 13 simvol olmalıdır"
      )
      .test(
        "check-SSN",
        "Bu SSN artıq istifadə edilib",
        async function (value) {
          const { ssn } = this.parent;
          if (value === ssn) {
            return true;
          }
          const response = await handleUniqueness("ssn", value, employeeId);
          return response;
        }
      ),
    // maritalStatus: Yup.string().required("Ailə vəziyyəti daxil edilməlidir"),
  });
};

export const bankInfoValidation = (employeeId) => {
  return Yup.object().shape({
    bankInfos: Yup.array().of(
      Yup.object().shape({
        bankName: Yup.string().required("Bank adı zəruridir"),
        // .max(bankNameLeng, "Bank adı maksimum 150 simvol olmalıdır"),
        // bankCode: Yup.number()
        //   .required("Bank kodu zəruridir")
        //   .max(bankCode, "Bank kodu maksimum 500 simvol olmalıdır"),
        // bankVoen: Yup.string().required("Bank VÖEN zəruridir"),
        accountNumber: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "Hesab nömrəsi hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .min(IBAN_Leng, "Hesab nömrəsi minimum 28 simvol olmalıdır")
          .max(IBAN_Leng, "Hesab nömrəsi maksimum 28 simvol olmalıdır")

          .required("Hesab nömrəsi zəruridir")
          .test(
            "check-accountNumber-iban",
            "Hesab nömrəsi və IBAN eyni ola bilməz",
            function (value, context) {
              const iban = this.parent.iban;
              const { bankInfos } = context?.from[1]?.value;

              const accountNumberExists =
                bankInfos &&
                bankInfos.some((detail) => detail.accountNumber === iban);

              // Return true if ibanExists is false, because we want the test to pass
              return !accountNumberExists;
            }
          )
          .test(
            "unique-accountNumber",
            "Hesab nömrəsi eyni ola bilməz",
            function (value, context) {
              const { bankInfos } = context?.from[1]?.value;
              const accountNumbers =
                bankInfos && bankInfos?.map((detail) => detail?.accountNumber);
              return (
                accountNumbers?.filter((number) => number === value).length ===
                1
              );
            }
          ),
        iban: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "IBAN yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .min(IBAN_Leng, "IBAN 28 minimum simvol olmalıdır")
          .max(IBAN_Leng, "IBAN 28 maksimum simvol olmalıdır")
          .required("IBAN zəruridir")

          .test(
            "check-accountNumber-iban",
            "Hesab nömrəsi və IBAN eyni ola bilməz",
            function (value, context) {
              const accountNumber = this.parent.accountNumber;
              const { bankInfos } = context?.from[1]?.value;

              const ibanExists =
                bankInfos &&
                bankInfos.some((detail) => detail.iban === accountNumber);

              // Return true if ibanExists is false, because we want the test to pass
              return !ibanExists;
            }
          )

          .test(
            "unique-IBAN",
            "IBAN kodu eyni ola bilməz",
            function (value, context) {
              const { bankInfos } = context?.from[1]?.value;
              const iban =
                bankInfos && bankInfos?.map((detail) => detail?.iban);
              return (
                iban && iban.filter((number) => number === value).length === 1
              );
            }
          ),
        cardExpirationDate: Yup.string()
          .test("custom-validations", function (value) {
            if (!value) return true;

            const [month, year] = value.split("/").map(Number);
            const today = new Date();
            const expirationDate = new Date(year, month - 1);

            if (year > 2099) {
              return this.createError({
                message:
                  "Zəhmət olmasa kartın etibarlıq müddətini 2099-dan böyük daxil etməyin.",
              });
            }

            if (
              expirationDate < new Date(today.getFullYear(), today.getMonth())
            ) {
              return this.createError({
                message: "Kartın etibarlıq müddəti keçmiş ola bilməz.",
              });
            }

            return true;
          })
          .matches(
            cardExpirationDateRegex,
            "Zəhmət olmasa kartın etibarlıq müddətini düzgün daxil edin.(MM/YYYY)"
          ),
      })
    ),
  });
};

export const laborActivityValidation = (isCurrentlyWorking) => {
  return Yup.object().shape({
    laborActivity: Yup.array().of(
      Yup.lazy((value, { parent, createError, index }) => {
        return Yup.object().shape({
          organizationName: Yup.string()
            .when("sameWorkplace", {
              is: false,
              then: Yup.string()
                .required("Təşkilatın adı zəruridir")
                .test(
                  "minLength",
                  "Təşkilatın adı minimum 2 hərf olmalıdır",
                  (value) => value && value.trim().length >= Organization_Min
                )
                .max(
                  Organization_Max,
                  "Təşkilatın adı maksimum 100 hərf olmalıdır"
                ),
            })
            .nullable(),
          department: Yup.string().when("sameWorkplace", {
            is: false,
            then: Yup.string()
              .min(Structure_Min, "Struktur bölmə adı minimum 2 hərf olmalıdır")
              .max(
                Structure_Max,
                "Struktur bölmə adı maksimum 100 hərf olmalıdır"
              )
              .required("Struktur bölmə adı zəruridir"),
          }),
          position: Yup.string().when("sameWorkplace", {
            is: false,
            then: Yup.string()
              .min(Position_Min, "Vəzifə adı minimum 2 hərf olmalıdır")
              .max(Position_Max, "Vəzifə adı maksimum 100 hərf olmalıdır")
              .required("Vəzifə adı zəruridir"),
          }),
          isCivilServant: Yup.string().when("sameWorkplace", {
            is: false,
            then: Yup.string().required("Dövlət qulluqçusu xanası zəruridir"),
          }),
          startDate: Yup.date()
            .transform((value, originalValue) => {
              // Attempt to convert the input value to a Date object
              const parsedDate = new Date(originalValue);
              // Return the parsed Date object if it is valid, or null otherwise
              return isValid(parsedDate) ? parsedDate : null;
            })
            .min(
              Yup.ref("startDate"),
              "Başlama tarixi bitiş tarixidən əvvəl olmalıdır"
            )
            .max(
              Yup.ref("endDate"),
              "Başlama tarixi bitiş tarixidən əvvəl olmalıdır"
            )
            .max(new Date(), "Başlama tarixi gələcək tarix ola bilməz.")
            .test(
              "unique-startDate",
              "Başlama tarixləri fərqli olmalıdır",
              function (value) {
                if (!value) {
                  return true;
                }
                for (let i = 0; i < parent.length; i++) {
                  let elem = parent[i].startDate;
                  if (
                    elem &&
                    elem.toString() === value.toString() &&
                    i !== index
                  ) {
                    return false;
                  }
                }
                return true;
              }
            )
            .nullable()
            .required("Başlama tarixi zəruridir"),
          endDate: Yup.date()
            .transform((value, originalValue) => {
              // Attempt to convert the input value to a Date object
              const parsedDate = new Date(originalValue);
              // Return the parsed Date object if it is valid, or null otherwise
              return isValid(parsedDate) ? parsedDate : null;
            })
            .min(
              Yup.ref("startDate"),
              "Bitiş tarixi başlama tarixidən sonra olmalıdır"
            )
            .max(new Date(), "Bitiş tarixi gələcək tarix ola bilməz.")
            .nullable()
            .when("present", {
              is: false,
              then: Yup.date()
                .required("Bitiş tarixi zəruridir")
                .test(
                  "unique-endDate",
                  "Bitiş tarixləri fərqli olmalıdır",
                  function (value) {
                    if (!value) {
                      return true;
                    }
                    for (let i = 0; i < parent.length; i++) {
                      let elem = parent[i].endDate;

                      if (
                        elem &&
                        elem.toString() === value.toString() &&
                        i !== index
                      ) {
                        return false;
                      }
                    }
                    return true;
                  }
                ),
              otherwise: Yup.date().nullable(),
            }),
        });
      })
    ),
  });
};

export const relationshipInfoValidation = (employeeId) => {
  const relationshipName =
    /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ](?:[a-zA-ZüçğıöəşÜÇĞIÖƏŞ\s]{0,2}[a-zA-ZüçğıöəşÜÇĞIÖƏŞ])*[a-zA-ZüçğıöəşÜÇĞIÖƏŞ]$/;
  return Yup.object().shape({
    relationshipInfo: Yup.array().of(
      Yup.object().shape({
        relationType: Yup.string()
          .required("Qohumluq əlaqəsi növü zəruridir")
          .test(
            "unique-relationType",
            "Eyni qohumluq əlaqəsi növü iki dəfə yazıla bilməz",
            function (value) {
              // Check if value is "sister", "brother", "son", or "daughter"
              if (
                value === "sister" ||
                value === "brother" ||
                value === "son" ||
                value === "daughter" ||
                value === "grandchild"
              ) {
                return true; // Skip validation error for these values
              }

              // Otherwise, perform the duplicate check
              const relationshipInfo =
                this.options.from[1].value.relationshipInfo;
              const currentRelationType = this.parent.relationType;

              const count = relationshipInfo.filter(
                (info) => info.relationType === currentRelationType
              ).length;

              return count <= 1; // Return true or false based on duplicate check
            }
          ),
        fullname: Yup.string()
          .min(
            fullMinLength,
            "Soyadı Adı Atasının adı minimum 2 simvol olmalıdır"
          )
          .max(
            fullMaxLength,
            "Soyadı Adı Atasının adı maksimum 150 simvol olmalıdır"
          )
          // .matches(
          //   relationshipName,
          //   "Soyadı Adı Atasının adı  yalnız hərflərdən ibarət olmalıdır"
          // )
          // .test(
          //   "spaces",
          //   "Soyadı Adı Atasının adı tam daxil edilməlidir",
          //   (value) => {
          //     if (!value) {
          //       return false; // Return false if value is undefined
          //     }
          //     const spaceCount = (value.match(/ /g) || []).length;
          //     return spaceCount === 2;
          //   }
          // )
          .required("Soyadı Adı Atasının adı zəruridir"),
        pin: Yup.string()

          .matches(
            letterNumberRegex,
            "FIN yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .when("isForeigner", {
            is: true,
            then: Yup.string()
              .min(foreignFin, "FIN minimum 6 simvol olmalıdır")
              .max(foreignFin, "FIN maksimum 6 simvol olmalıdır")
              .required("FIN zəruridir"),
          })
          .when("isForeigner && isMyiExist", {
            is: true,
            then: Yup.string()
              .min(foreignFin, "FIN minimum 6 simvol olmalıdır")
              .max(foreignFin, "FIN maksimum 6 simvol olmalıdır")
              .required("FIN zəruridir"),
          }),

        idCardNumber: Yup.object().when("isForeigner", {
          is: true,
          then: Yup.object().shape({
            number: Yup.string()
              .matches(/^\d+$/, {
                message:
                  "Passport nömrəsini yalnız rəqəmlərdən ibarət olmalıdır",
              })
              .required("Passport nömrəsini daxil edin"),
          }),
          otherwise: Yup.object().shape({
            code: Yup.string().nullable(),
            number: Yup.string().when("code", {
              is: (code) => code === "AA",
              then: Yup.string()
                .min(AA_Length, "Ş.V/Seriya minimum 7 simvol olmalıdır")
                .max(AA_Length, "Ş.V/Seriya maksimum 7 simvol olmalıdır")
                .matches(
                  letterNumberRegex,
                  "Ş.V/Seriya yalnız hərf və rəqəmlərdən ibarət olmalıdır"
                )
                .required("Ş.V/Seriya № -ni daxil edin"),
              otherwise: Yup.string()
                .min(AZA_Length, "Ş.V/Seriya minimum 8 simvol olmalıdır")
                .max(AZA_Length, "Ş.V/Seriya maksimum 8 simvol olmalıdır")
                .matches(
                  letterNumberRegex,
                  "Ş.V/Seriya yalnız hərf və rəqəmlərdən ibarət olmalıdır"
                )
                .required("Ş.V/Seriya № -ni daxil edin"),
            }),
          }),
        }),
        tRPNumber: Yup.string().when("isForeigner && isMyiExist", {
          is: true,
          then: Yup.string()
            .matches(/^\d+$/, {
              message: "MYİ seriya nömrəsi yalnız rəqəmlərdən ibarət olmalıdır",
            })
            .min(MYI_Length, "MYİ seriya nömrəsi 7 simvol olmalıdır")
            .max(MYI_Length, "MYİ seriya nömrəsi maksimum 7 simvol olmalıdır")
            .required("MYİ seriya nömrəsi zəruridir"),
        }),
        dob: Yup.date()
          .max(new Date(), "Doğum tarixi gələcək tarix ola bilməz.")
          .required("Doğum tarixi zəruridir"),
        // education: Yup.string().required("Təhsili zəruridir"),
        // placeOfWork: Yup.string()
        //   .min(Workplace_Min, "İş yeri (vəzifəsi) minimum 2 simvol olmalıdır")
        //   .max(
        //     Workplace_Max,
        //     "İş yeri (vəzifəsi) maksimum 500 simvol olmalıdır"
        //   )
        //   .required("İş yeri (vəzifəsi) zəruridir"),
      })
    ),
  });
};

export const ContactInfoValidation = (employeeId) => {
  return Yup.object().shape({
    actualAddress: Yup.object().shape({
      address: Yup.string()
        // .matches(
        //   /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ0-9]+(?: [a-zA-ZüçğıöəşÜÇĞIÖƏŞ0-9]+)*$/,
        //   "Yalnız hərflər, rəqəmlər və boşluqlar qəbul olunur"
        // )
        .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
        .required("Faktiki ünvanı daxil edin"),
      city: Yup.string()
        .when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string(),
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      district: Yup.string()
        .test("district", "Rayon daxil edin", function (value) {
          const city = this.resolve(Yup.ref("city"));
          if (
            (city === "Bakı" || city === "Sumqayıt") &&
            (!value || value === "")
          ) {
            return this.createError({
              path: "actualAddress.district",
              message: "Rayon daxil edin",
            });
          }
          return true;
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      homeNumber: Yup.array().of(
        Yup.object().shape({
          data: Yup.string()
            .max(homeNumberMax, "Ev nömrəsi maksimum 50 simvol olmalıdır")
            .required("Telefon nömrəsi daxil edin"),
          // .test(
          //   "check-homeNumber",
          //   "Bu ev telefonu artıq istifadə edilib",
          //   async function (value) {
          //     const response = await handleUniqueness(
          //       "homeNumber",
          //       value,
          //       employeeId
          //     );
          //     return response;
          //   }
          // )
        })
      ),
    }),
    registeredAddress: Yup.object().when(["sameAddress", "actualAddress"], {
      is: (sameAddress, actualAddress) => !sameAddress && actualAddress,
      then: Yup.object().shape({
        address: Yup.string()
          .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
          .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
          .required("Faktiki ünvanı daxil edin")
          .test(
            "unique-address",
            "Eyni adres iki dəfə yazıla bilməz",
            function (value) {
              let address1 = this.options.from[1].value.actualAddress.address;
              let address2 = this.parent.address;
              const actualAddress = address1 === address2;
              return !actualAddress;
            }
          ),
        city: Yup.string().when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string().max(
            cityMax,
            "Şəhər adı maksimum 70 simvol olmalıdır"
          ),
        }),
        district: Yup.string()
          .test("district", "Rayon daxil edin", function (value) {
            const city = this.resolve(Yup.ref("city"));
            if (
              (city === "Bakı" || city === "Sumqayıt") &&
              (!value || value === "")
            ) {
              return this.createError({
                path: "registeredAddress.district",
                message: "Rayon daxil edin",
              });
            }
            return true;
          })
          .max(
            actualAddress_Max,
            "Faktiki ünvan maksimum 500 simvol olmalıdır"
          ),
      }),
      homeNumber: Yup.array().of(
        Yup.object().shape({
          data: Yup.string()
            .max(homeNumberMax, "Ev nömrəsi maksimum 50 simvol olmalıdır")
            .required("Telefon nömrəsi daxil edin"),
          // .test(
          //   "check-homeNumber",
          //   "Bu ev telefonu artıq istifadə edilib",
          //   async function (value) {
          //     const response = await handleUniqueness(
          //       "homeNumber",
          //       value,
          //       employeeId
          //     );
          //     return response;
          //   }
          // )
        })
      ),
    }),
    mobile: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required("Operator daxil edilməlidir"),
          number: Yup.string()
            .matches(numberRegex, "Mobil nömrə rəqəmlərdən ibarət olmalıdır")
            // .min(phoneNumber, "Mobil nömrə minimum 7 simvol olmalıdır")
            // .max(phoneNumber, "Mobil nömrə maksimum 7 simvol olmalıdır")
            .test("number", "Nömrə daxil edilməlidir", (value) => value !== "")
            .required("Nömrə daxil edilməlidir"),
          // .test(
          //   "check-mobile",
          //   "Bu nömrə artıq istifadə edilib",
          //   async function (value) {
          //     const { code, number } = this.parent;
          //     const combinedValue = code + number;
          //     const response = await handleUniqueness(
          //       "mobile",
          //       combinedValue,
          //       employeeId
          //     );
          //     return response;
          //   }
          // ),
        })
      )
      .required("Mobil nömrəni daxil edin"),

    email: Yup.array().of(
      Yup.object().shape({
        data: Yup.string()
          .email("Düzgün email daxil edin")
          .required("Email daxil edilməlidir")
          // .test(
          //   "check-mail",
          //   "Bu email artıq istifadə edilib",
          //   async function (value) {
          //     const [email] = this.parent;
          //     const response = await handleUniqueness("email", email, employeeId);
          //     return response;
          //   }
          // )
          // .test(
          //   "check-email",
          //   "Bu email artıq istifadə edilib və yaxud heç bir istifadəçiyə aid deyil",
          //   async function (value, { parent }) {
          //     const isAllEmailsSame = isEmailUnique(parent);
          //     return !isAllEmailsSame;
          //   }
          // )
          .max(150, "Email maksimum 150 simvol olmalıdır"),
      })
    ),
  });
};

export const EducationValidation = (employeeId) => {
  return Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        edType: Yup.string().required("Təhsil növünü seçin"),
        institution: Yup.string().required("Təhsil müəssisəsini seçin"),
        faculty: Yup.string()
          .required("Təhsil sahəsini seçin")
          .when("edType", {
            is: (val) => hasFaculties(val),
            then: Yup.string(),
          }),
        startDate: Yup.date()
          .transform((value, originalValue) => {
            const parsedDate = new Date(originalValue);
            return isValid(parsedDate) ? parsedDate : null;
          })
          .min(
            Yup.ref("startDate"),
            "Təhsilin başlama tarixi təhsilin bitiş tarixidən əvvəl olmalıdır"
          )
          .max(
            Yup.ref("endDate"),
            "Təhsilin başlama tarixi təhsilin bitiş tarixidən əvvəl olmalıdır"
          )
          .max(new Date(), "Təhsilin başlama tarixi gələcək ola bilməz.")
          .nullable()
          .required("Başlama tarixini daxil edin"),
        endDate: Yup.date()
          .transform((value, originalValue) => {
            const parsedDate = new Date(originalValue);
            return isValid(parsedDate) ? parsedDate : null;
          })
          .when(["stillStudying"], (stillStudying, schema) => {
            return stillStudying
              ? schema
              : schema
                  .min(
                    Yup.ref("startDate"),
                    "Təhsilin başlama tarixi bitmə tarixindən sonra ola bilməz."
                  )
                  .nullable();
          }),
      })
    ),
  });
};

export const academicDegreeValidation = (employeeId) => {
  return Yup.object().shape({
    academicDegrees: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Elmi dərəcə adı zəruridir"),
        issuer: Yup.string().required("Verən müəssisə zəruridir"),
        date: Yup.date()
          .required("Tarix zəruridir")
          .min(new Date(1940, 0, 1), "Tarix 1940 tarixindən sonra olmalıdır")
          .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
      })
    ),
  });
};

export const socialStatusValidation = (employeeId) => {
  return Yup.object().shape({
    socialStatus: Yup.array().of(
      Yup.object().shape({
        statusType: Yup.string().required("Sosial Status növü zəruridir"),
        date: Yup.date()
          .required("Tarix zəruridir")
          .min(new Date(1940, 0, 1), "Tarix 1940 tarixindən sonra olmalıdır")
          .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
      })
    ),
  });
};

export const supplementalWagesValidation = (employeeId) => {
  return Yup.object().shape({
    supplementalWages: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Əməkhaqqına əlavənin səbəbi zəruridir"),
        value: Yup.number()
          .typeError("Əməkhaqqına əlavəsi rəqəm olmalıdır")
          .min(0, "Əməkhaqqına əlavəsi 0-dan kiçik ola bilməz"),
      })
    ),
  });
};

export const reprimandRewardValidation = (employeeId) => {
  return Yup.object().shape({
    reprimandsAndRewards: Yup.array().of(
      Yup.lazy((value, { parent }) => {
        return Yup.object().shape({
          rType: Yup.string().required(
            "Mükafat / Təltif / Tənbeh növü zəruridir"
          ),
          description: Yup.string()
            .required("Mükafat / Təltif / Tənbeh məzmunu zəruridir")
            .max(
              RewardsReprimandsLeng,
              "Mükafat / Təltif / Tənbeh məzmunu maksimum 500 hərf olmalıdır"
            ),
          // .test(
          //   "unique-description",
          //   "Bu məzmunu artıq istifadə edilib və ya heç bir istifadəçiyə aid deyil",
          //   function (value) {
          //     if (parent.length > 1) {
          //       const descriptions = parent.map((item) => item.description);
          //       return (
          //         descriptions.filter((desc) => desc === value).length <= 1
          //       );
          //     } else {
          //       return true;
          //     }
          //   }
          // ),
          date: Yup.date()
            .required("Tarix zəruridir")
            .min(new Date(1940, 0, 1), "Tarix 1940 tarixindən sonra olmalıdır")
            .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
          organization: Yup.string().required(
            "Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə zəruridir"
          ),
        });
      })
    ),
  });
};

export const documentsValidation = (employeeId) => {
  return Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        docType: Yup.string().required("Sənədin növü zəruridir"),
        filename: Yup.string(),
        fileBase64: Yup.string().when("filename", {
          is: (filename) => !filename || filename.trim() === "",
          then: Yup.string().required("Şəkil əlavə edin"),
        }),
        issuanceDate: Yup.date()
          .required("Verilmə tarixi zəruridir")
          .min(
            new Date(1940, 0, 1),
            "Verilmə tarixi 1940 tarixindən sonra olmalıdır"
          )
          .max(new Date(), "Verilmə tarixi cari tarixdən böyük ola bilməz"),
        expirationDate: Yup.date().min(
          new Date(),
          "Verilmə tarixi cari tarixdən kiçik ola bilməz"
        ),
      })
    ),
  });
};
