import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
// React Router DOM
import { useNavigate } from "react-router-dom";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { saveTemplateData } from "../../../../redux/features/slices/template.slice";
// Components
import Breadcrumb from "../../../../components/layouts/breadcrumb/Breadcrumb";
import Header from "../../../../components/layouts/header/Header";
import SimpleDataTable from "../../../../components/layouts/dataTable/SimpleDataTable";
// Services
import * as templateServices from "../../../../services/documentations/TemplateServices";
// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider as MuiDivider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
// Utilities
import {
  correctTemplateExtensions,
  templateTypes,
} from "../../../../constants";
import {
  removeEmptyStrings,
  findExtensionOfFile,
} from "../../../../utils/helperFunctions";
// React Toastify
import { toast, ToastContainer } from "react-toastify";

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TemplateCreate = () => {
  const disptach = useDispatch();
  const navigate = useNavigate();
  const saveTemplateDatas = useSelector((state) => state.template.templateData);
  const [loading, setLoading] = useState(false);
  const [isExistWrongFile, setIsExistWrongFile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Məcburi xanadır"),
    code: Yup.string().trim().required("Məcburi xanadır"),
    type: Yup.string().required("Məcburi xanadır"),
    fields: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Məcburi xanadır"),
        label: Yup.string().required("Məcburi xanadır"),
        min: Yup.number().min(1, "Minimum qiymət 1"),
        max: Yup.number()
          .min(1, "Maksimum qiymət 1")
          .when("min", (minValue, schema) => {
            return schema.min(
              minValue || 1,
              "Maksimum qiymət minimum qiymətdən böyük olmalıdır"
            );
          }),
        // .test(
        //   "isLarger",
        //   "Maksimum dəyər minumumdan dəyər böyük olmalıdır",
        //   (value, _context) => {
        //     if (_context.parent.min >= value) return false;
        //     return true;
        //   }
        // ),
        // defValue: Yup.string().ensure().when("isRequired", {
        //   is: false,
        //   then: Yup.string().required("Məcburi xanadır")
        // })
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      type: "",
      fields: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { validateForm }) => {
      try {
        const postData = {
          ...values,
          templateName: saveTemplateDatas.fileName,
        };
        const fields = removeEmptyStrings(postData.fields);
        const payload = { ...postData, fields };
        const _response = await templateServices.createTemplate({
          ...payload,
          label: payload.name,
          name: payload.name,
        });
        if (_response) {
          toast.success("Yeni şablon uğurla yaradıldı!");
          formik.resetForm();
          // navigate("/documentation/template");
          navigate(-1);
        } else {
          toast.error("Hər hansı bir xəta baş verdi");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleFileToBase64 = async (file) => {
    if (!file) return null;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Exclude the data URI prefix
        resolve(base64String);
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };
  const handleUploadFile = async (e) => {
    try {
      const { target } = e;
      const file = target.files[0];
      const extension = findExtensionOfFile(file);
      let base64_file = await handleFileToBase64(file);
      if (!correctTemplateExtensions.includes(extension)) {
        setIsExistWrongFile(true);
        setIsOpen(true);
        return;
      }
      setLoading(true);
      setUploadFile(file);
      setIsExistWrongFile(false);
      setIsOpen(false);
      const data = await templateServices.saveTemplate(base64_file);
      if (data.errors) {
        data.errors.map((item) => {
          item.message === "docNumber keyword required" &&
            toast.error("Sənəddə docNumber açar sözü vacibdir,");
        });
      }

      if (data && data?.data?.keywords?.length > 0) {
        disptach(saveTemplateData(data?.data));
        formik.values.fields = [];
        for (const item of data?.data?.keywords) {
          formik.values.fields.push({
            name: item,
            type: item === "docNumber" ? "string" : "",
            min: "",
            max: "",
            defValue: "",
            isRequired: true,
            source: "custom",
            label: "",
          });
        }
        formik.setFieldValue("fields", formik.values.fields);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };
  const headerData = {
    title: "Şablon yarat",
    variant: "h3",
  };

  const headerCells = [
    "Name",
    "Type",
    "Min",
    "Max",
    // "Default Value",
    // "IsRequired",
    // "Source",
    "Label",
  ];

  const normalizeType = (type) => {
    switch (type) {
      case "order":
        return "Əmr";
      case "agreement":
        return "Müqavilə";
      case "application":
        return "Ərizə";
      case "presentation-essay":
        return "Xidməti yazı";
      default:
        return "";
    }
  };
  return (
    <>
      <ToastContainer />
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        aria-labelledby="simple-dialog-title"
        fullWidth
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" ml={2}>
              Zəhmət olmasa düzgün formatda(word) fayl seçin.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <form onSubmit={formik.handleSubmit}>
        <Card mb={6}>
          <CardContent>
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item>
                <Header data={headerData} />
              </Grid>
            </Grid>

            <Divider my={6} />

            <Typography variant="h3" textAlign="center" mb={5}>
              Yeni Şablon əlavə et
            </Typography>
            <Grid container spacing={6} mb={3}>
              <Grid item md={6}>
                <TextField
                  name="name"
                  label="Şablonun adı*"
                  fullWidth
                  variant="outlined"
                  mt={3}
                  my={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.touched?.name && formik.errors?.name)}
                  helperText={formik.touched?.name && formik.errors?.name}
                  value={formik.values?.name}
                  FormHelperTextProps={{
                    sx: {
                      width: "100%",
                      height: "20px",
                      display: "flex",
                      // alignItems: "center",
                      // whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      position: "absolute",
                      top: "52px",
                      transition: "opacity 0.3s ease-in-out",
                    },
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="code"
                  label="Şablonun kodu*"
                  fullWidth
                  variant="outlined"
                  mt={3}
                  my={2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.touched?.code && formik.errors?.code)}
                  helperText={formik.touched?.code && formik.errors?.code}
                  value={formik.values?.code}
                  FormHelperTextProps={{
                    sx: {
                      width: "100%",
                      height: "20px",
                      display: "flex",
                      // alignItems: "center",
                      // whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      position: "absolute",
                      top: "52px",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} mb={3}>
              <Grid item md={6} mt={3}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="max-width"
                    style={
                      formik.touched?.type && formik.errors?.type
                        ? { color: "#f44336" }
                        : null
                    }
                  >
                    Şablonun tipi
                  </InputLabel>
                  <Select
                    name="type"
                    label="Şablonun tipi*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched?.type && formik.errors?.type)}
                    value={formik.values?.type}
                  >
                    {templateTypes.length > 0
                      ? templateTypes.map((type, index) => {
                          if (type !== "presentation-essay") {
                            return (
                              <MenuItem value={type} key={index + 1}>
                                {normalizeType(type)}
                              </MenuItem>
                            );
                          }
                        })
                      : null}
                  </Select>
                  <FormHelperText
                    sx={{
                      width: "100%",
                      height: "20px",
                      display: "flex",
                      // alignItems: "center",
                      // whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      position: "absolute",
                      top: "52px",
                    }}
                  >
                    {formik.touched?.type && (
                      <span
                        style={
                          formik.touched?.type ? { color: "#f44336" } : null
                        }
                      >
                        {formik.errors?.type}
                      </span>
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item mt={"10px"} md={6}>
                <input
                  type="file"
                  id="raised-button-file"
                  name="templateFile"
                  style={{ display: "none" }}
                  onChange={handleUploadFile}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    style={{ height: "90%", marginTop: "2px" }}
                  >
                    <CloudUpload mr={2} />
                    {uploadFile && !isExistWrongFile
                      ? "1 Fayl əlavə edildi"
                      : "Şablon fayl əlavə edin*"}
                  </Button>
                </label>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {uploadFile && !isExistWrongFile ? (
          <Card mb={6} style={{ marginTop: "25px" }}>
            <CardContent>
              {loading ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : saveTemplateDatas &&
                saveTemplateDatas?.keywords &&
                saveTemplateDatas?.keywords?.length > 0 ? (
                <>
                  {formik.values.fields?.length > 0 && (
                    <SimpleDataTable
                      data={[...formik.values?.fields]}
                      headers={headerCells}
                      formik={formik}
                    />
                  )}
                  <Grid container justifyContent="flex-end" mt={5}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit(formik.values);
                      }}
                      // type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={!formik.isValid}
                    >
                      Yadda saxla
                    </Button>
                  </Grid>
                </>
              ) : (
                <Typography variant="h3" textAlign="center">
                  Hər hansı bir məlumat tapılmadı və ya fayl yükləyərkən hər
                  hansı bir xəta baş verdi. Zəhmət olmasa, yenidən sınayın.
                </Typography>
              )}
            </CardContent>
          </Card>
        ) : null}
      </form>
    </>
  );
};

export default TemplateCreate;
