import React, { useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const RegulationPropertyRelation = ({ setFieldValue, values }) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddChip = (setFieldValue, values) => {
    if (inputValue.trim() !== "") {
      setFieldValue(
        "regulationPropertyRelation.personalPropertiesUsedByEmployee",
        [
          ...values?.regulationPropertyRelation
            ?.personalPropertiesUsedByEmployee,
          inputValue.trim(),
        ]
      );
      setInputValue("");
    }
  };

  const handleDeleteChip = (chipToDelete, setFieldValue, values) => {
    setFieldValue(
      "regulationPropertyRelation.personalPropertiesUsedByEmployee",
      values?.regulationPropertyRelation?.personalPropertiesUsedByEmployee.filter(
        (chip) => chip !== chipToDelete
      )
    );
  };
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Mülkiyyət münasibətlərinin tənzimlənməsi</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              type="number"
              label="Müəssisənin mülkiyyətdə payı"
              value={
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              }
              fullWidth
              name="regulationPropertyRelation.shareOwnershipOfEnterprise"
              onWheel={(e) => e.target.blur()}
              onChange={(event) => {
                setFieldValue(
                  "regulationPropertyRelation.shareOwnershipOfEnterprise",
                  event.target.value
                );
              }}
            />
            <TextField
              type="number"
              label="Divident məbləği"
              value={values?.regulationPropertyRelation?.dividendAmount}
              fullWidth
              name="regulationPropertyRelation.dividendAmount"
              onWheel={(e) => e.target.blur()}
              onChange={(event) => {
                setFieldValue(
                  "regulationPropertyRelation.dividendAmount",
                  event.target.value
                );
              }}
            />
            <>
              <TextField
                type="text"
                label=" İşçi əmək funksiyasının icrası zamanı özünün aşağıdakı şəxsi əmlakından istifadə edir"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleAddChip(setFieldValue, values);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleAddChip(setFieldValue, values)}
                      disabled={!inputValue.trim()}
                    >
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />

              {/* Render chips */}
              <Paper
                component="ul"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "8px",
                  marginTop: "16px",
                }}
              >
                {values?.regulationPropertyRelation?.personalPropertiesUsedByEmployee.map(
                  (func, index) => (
                    <li key={index} style={{ margin: "4px" }}>
                      <Chip
                        label={func}
                        onDelete={() =>
                          handleDeleteChip(func, setFieldValue, values)
                        }
                        deleteIcon={<CloseIcon />}
                      />
                    </li>
                  )
                )}
              </Paper>
            </>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default RegulationPropertyRelation;
