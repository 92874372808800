import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
// React Router DOM
import { useNavigate, useParams } from "react-router-dom";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getTemplateDataById } from "../../../../redux/features/slices/template.slice";
// Components
import Breadcrumb from "../../../../components/layouts/breadcrumb/Breadcrumb";
import Header from "../../../../components/layouts/header/Header";
import SimpleDataTable from "../../../../components/layouts/dataTable/SimpleDataTable";
// Services
import * as templateServices from "../../../../services/documentations/TemplateServices";
// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider as MuiDivider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  CloudUpload as MuiCloudUpload,
  CloudDownload as MuiCloudDownload,
} from "@mui/icons-material";
// Utilities
import {
  BASE_URL,
  correctTemplateExtensions,
  templateTypes,
} from "../../../../constants";
import {
  findExtensionOfFile,
  storeSingleArrayKeywords,
  customizeTemplateArrStructure,
  removeEmptyStrings,
  convertMinMaxToNumber,
} from "../../../../utils/helperFunctions";
// React Toastify
import { toast } from "react-toastify";
import DialogHeader from "../../../../components/dialogs/documentDialog/dialogParts/DialogHeader";

const CloudUpload = styled(MuiCloudUpload)(spacing);
const CloudDownload = styled(MuiCloudDownload)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TemplateView = ({ id, close }) => {
  const disptach = useDispatch();
  // const navigate = useNavigate();
  // const { id } = useParams();
  const { templateDataById } = useSelector((state) => state.template);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isExistWrongFile, setIsExistWrongFile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required("Məcburi xanadır"),
    code: Yup.string().required("Məcburi xanadır"),
    type: Yup.string().required("Məcburi xanadır"),
    fields: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Məcburi xanadır"),
        label: Yup.string().required("Məcburi xanadır"),
        min: Yup.number().min(1, "Minimum qiymət 1"),
        max: Yup.number()
          .min(1, "Minimum qiymət 1")
          .test("isLarger", "Max mindən böyük olmalıdır", (value, _context) => {
            if (_context.parent.min >= value) return false;
            return true;
          }),
        // defValue: Yup.string()
        //   .ensure()
        //   .when("isRequired", {
        //     is: false,
        //     then: Yup.string().required("Məcburi xanadır"),
        //   }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      type: "",
      fields: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const updateData = { ...values };
        delete updateData["id"];
        const inputFields = removeEmptyStrings(updateData.fields);
        const fields = convertMinMaxToNumber(inputFields);
        const payload = { ...updateData, fields, label: updateData.name };
        const _response = await templateServices.updateTemplate(
          templateDataById._id,
          payload
        );
        if (_response) {
          toast.success("Şablon uğurla redaktə edildi");
          formik.resetForm();
          // navigate(-1);
        } else {
          toast.error("Hər hansı bir xəta baş verdi");
        }
      } catch (error) {
        console.log(error);
      } finally {
        close();
      }
    },
  });

  useEffect(() => {
    (async function getTypeById() {
      try {
        setPageLoading(true);
        const data = await templateServices.getTypeById(id);
        if (data) {
          disptach(getTemplateDataById(data));
          formik.setValues({
            name: data?.name,
            code: data?.code,
            label: data?.label,
            type: data?.type,
            templateName: data?.templateName,
            id: data?._id,
            fields: data?.fields,
          });
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setTimeout(() => {
          setPageLoading(false);
        }, 1500);
      }
    })();
  }, [id]);

  const handleFileToBase64 = async (file) => {
    if (!file) return null;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Exclude the data URI prefix
        resolve(base64String);
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const handleUploadFile = async (e) => {
    try {
      const { target } = e;
      const file = target.files[0];
      const extension = findExtensionOfFile(file);
      let base64_file = await handleFileToBase64(file);
      if (!correctTemplateExtensions.includes(extension)) {
        setIsExistWrongFile(true);
        setIsOpen(true);
        return;
      }

      setLoading(true);
      setUploadFile(file);
      setIsExistWrongFile(false);
      setIsOpen(false);

      const data = await templateServices.saveTemplate(base64_file, id);

      if (data && data?.data?.keywords?.length > 0) {
        formik.setFieldValue("templateName", data?.fileName);
        // if (isStatic) {
        //   formik.setFieldValue("fields", templateDataById?.fields);
        // }
        const helperArray = storeSingleArrayKeywords(templateDataById?.fields);
        const differenceArr = data.keywords.filter(
          (x) => !helperArray.includes(x)
        );
        let concatedData = [];

        if (differenceArr.length > 0) {
          const finalArray = customizeTemplateArrStructure(differenceArr);
          concatedData = [...templateDataById?.fields, ...finalArray];
          formik.setFieldValue("fields", concatedData);
          return;
        }

        formik.setFieldValue("fields", templateDataById?.fields);
      } else {
        formik.setFieldValue("fields", []);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const headerData = {
    title: "Şablon redaktə et",
    variant: "h3",
  };

  const headerCells = ["Name", "Type", "Min", "Max", "Label"];

  const normalizeType = (type) => {
    switch (type) {
      case "order":
        return "Əmr";
      case "agreement":
        return "Müqavilə";
      case "application":
        return "Ərizə";
      case "presentation-essay":
        return "Xidməti yazı";
      default:
        return "";
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        aria-labelledby="simple-dialog-title"
        fullWidth
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" ml={2}>
              Zəhmət olmasa düzgün formatda(word) fayl seçin.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          sx={{
            // height: "90vh",
            width: "100%",
            zIndex: 999,
          }}
        >
          <Grid
            justifyContent="space-between"
            container
            spacing={10}
            sx={{
              paddingTop: "5px",
              height: "10%",
              position: "relative",
              zIndex: 9999,
            }}
          >
            <Grid item width={"100%"}>
              <DialogHeader title={formik.values?.label} onClose={close} />
            </Grid>
          </Grid>
          <Grid sx={{ height: "90%", overflowY: "scroll" }}>
            <Card mb={6}>
              <CardContent>
                {pageLoading ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Divider my={6} />

                    {formik.values?.name !== "" ? (
                      <>
                        <Grid>
                          <Grid container spacing={3} mb={3}>
                            <Grid item md={6}>
                              <TextField
                                name="name"
                                disabled
                                label="Şablonun adı*"
                                fullWidth
                                variant="outlined"
                                size="medium"
                                my={2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(
                                  formik.touched?.name && formik.errors?.name
                                )}
                                helperText={
                                  formik.touched?.name && formik.errors?.name
                                }
                                value={formik.values?.name}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                name="code"
                                disabled
                                label="Şablonun kodu*"
                                fullWidth
                                variant="outlined"
                                my={2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(
                                  formik.touched?.code && formik.errors?.code
                                )}
                                helperText={
                                  formik.touched?.code && formik.errors?.code
                                }
                                value={formik.values?.code}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={3} mb={3}>
                            <Grid item md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  htmlFor="max-width"
                                  style={
                                    formik.touched?.type && formik.errors?.type
                                      ? { color: "#f44336" }
                                      : null
                                  }
                                >
                                  Şablonun tipi
                                </InputLabel>
                                <Select
                                  name="type"
                                  disabled
                                  label="Şablonun tipi*"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={Boolean(
                                    formik.touched?.type && formik.errors?.type
                                  )}
                                  value={formik.values?.type}
                                >
                                  {templateTypes.length > 0
                                    ? templateTypes.map((type, index) => (
                                        <MenuItem value={type} key={index + 1}>
                                          {normalizeType(type)}
                                        </MenuItem>
                                      ))
                                    : null}
                                </Select>
                                <FormHelperText>
                                  {formik.touched?.type && (
                                    <span
                                      style={
                                        formik.touched?.type
                                          ? { color: "#f44336" }
                                          : null
                                      }
                                    >
                                      {formik.errors?.type}
                                    </span>
                                  )}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            {/* <Grid
                              item
                              md={6}
                              style={{ display: "flex", gap: "8px" }}
                            >
                              <Grid item md={6} mr={3}>
                                <input
                                  type="file"
                                  id="raised-button-file"
                                  name="templateFile"
                                  style={{ display: "none" }}
                                  onChange={handleUploadFile}
                                />
                                <label htmlFor="raised-button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    fullWidth
                                    style={{
                                      height: "90%",
                                      marginTop: "2px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <CloudUpload mr={2} />
                                    {uploadFile && !isExistWrongFile
                                      ? "1 Fayl əlavə edildi"
                                      : "Fayl əlavə et"}
                                  </Button>
                                </label>
                              </Grid>
                              <Grid item md={6}>
                                <a
                                  href={`${BASE_URL}/employee/contract/type/currentTemplate/${templateDataById?.templateName}`}
                                  download
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    fullWidth
                                    style={{ height: "90%", marginTop: "2px" }}
                                  >
                                    <CloudDownload mr={2} />
                                    {"Faylı endir"}
                                  </Button>
                                </a>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Typography variant="h3" textAlign="center">
                        {
                          "Hər hansı bir server xətası baş verdi. Yenidən cəhd edin"
                        }
                      </Typography>
                    )}
                  </>
                )}
              </CardContent>
            </Card>

            {!pageLoading && !isExistWrongFile && formik.values?.name !== "" ? (
              <Card
                mb={6}
                style={{ marginTop: "25px" }}
                sx={{ pointerEvents: "none" }}
              >
                <CardContent>
                  {loading ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : formik.values?.fields &&
                    formik.values?.fields?.length > 0 ? (
                    <>
                      <SimpleDataTable
                        data={[...formik.values?.fields]}
                        headers={headerCells}
                        formik={formik}
                      />
                    </>
                  ) : (
                    <Typography variant="h3" textAlign="center">
                      Hər hansı bir məlumat tapılmadı və ya fayl yükləyərkən hər
                      hansı bir xəta baş verdi. Zəhmət olmasa, yenidən sınayın.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default TemplateView;
