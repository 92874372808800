import { useState } from "react";
import { getGraphicSettings } from "../api/graphicSettings";

function useGraphicForm(onDataReceived) {
  const [open, setOpen] = useState(false);
  const [showInputs, setShowInputs] = useState(true);
  const [inputSets, setInputSets] = useState(1);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(5);
  const [isHoliday, setIsHoliday] = useState([false]);
  const [label, setLabel] = useState("");
  const [scheduleType, setScheduleType] = useState("");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    workType: "",
    workDays: "",
    jobEndTime: "",
    restStartTime: "",
    restEndTime: "",
    jobStartTime: "",
  });

  const [numGroups, setNumGroups] = useState(2);

  const handleOpen = () => {
    setOpen(true);
  };
  async function getData() {
    try {
      let response = await getGraphicSettings({ limit: limit, offset: offset });
      setData(response?.data?.records);
    } catch (error) {
      console.log(error);
    }
  }
  const handleClose = () => {
    setFormData({
      workType: "",
      scheduleType: "",
      workDays: "",
      jobEndTime: "",
      restStartTime: "",
      restEndTime: "",
      jobStartTime: "",
    });
    setOpen(false);
    setShowInputs(false);
  };

  function handleCountInputs() {
    if (formData.scheduleType !== "shift") {
      setInputSets(1);
    }
  }

  function handleChangeCheckBox(index) {
    setIsHoliday((prevChecked) => {
      const updatedChecked = [...prevChecked];
      updatedChecked[index] = !prevChecked[index];
      return updatedChecked;
    });
    const updatedFormData = { ...formData };

    if (updatedFormData[`jobStartTime${index}`]) {
      updatedFormData[`jobStartTime${index}`] = "";
    }
    if (updatedFormData[`jobEndTime${index}`]) {
      updatedFormData[`jobEndTime${index}`] = "";
    }

    // Update the formData state with the updated values
    setFormData(updatedFormData);
  }

  const handleChangeLabe = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "2 növbəli") {
      setNumGroups(2);
    } else if (selectedValue === "3 növbəli") {
      setNumGroups(3);
    } else if (selectedValue === "4 növbəli") {
      setNumGroups(4);
    }
  };

  const handleAddClick = () => {
    setInputSets((prevSets) => prevSets + 1);
  };

  const handleReduceInputs = (index, updatedFormData = {}) => {
    setInputSets((prevSets) => prevSets - 1);
    const updatedIsHoliday = [...isHoliday];
    updatedIsHoliday.splice(index, 1);
    const updatedFormDataCopy = { ...updatedFormData };
    delete updatedFormDataCopy[`jobStartTime${index}`];
    delete updatedFormDataCopy[`jobEndTime${index}`];
    setFormData(updatedFormDataCopy);
    setIsHoliday(updatedIsHoliday);
  };

  const handleChangeType = (event) => {
    const { value } = event.target;
    setScheduleType(value);
    setNumGroups(2);
    handleCountInputs();
    setIsHoliday([false]);
    setShowInputs(true);
    setFormData((prevFormData) => {
      const clearedFormData = {
        workType: "",
        scheduleType: "",
        workDays: "",
        jobEndTime: "",
        restStartTime: "",
        restEndTime: "",
        jobStartTime: "",
      };
      for (let i = 0; i < numGroups; i++) {
        clearedFormData[`jobStartTime${i}`] = "";
        clearedFormData[`jobEndTime${i}`] = "";
      }
      for (let i = 0; i < inputSets; i++) {
        clearedFormData[`jobStartTime${i}`] = "";
        clearedFormData[`jobEndTime${i}`] = "";
      }
      return clearedFormData;
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return {
    open,
    data,
    label,
    limit,
    offset,
    filters,
    setData,
    getData,
    formData,
    setLimit,
    setOffset,
    isHoliday,
    numGroups,
    inputSets,
    showInputs,
    setFilters,
    setFormData,
    setInputSets,
    setNumGroups,
    handleOpen,
    handleClose,
    scheduleType,
    handleChange,
    handleAddClick,
    handleChangeLabe,
    handleChangeType,
    handleReduceInputs,
    handleChangeCheckBox,
  };
}

export default useGraphicForm;
