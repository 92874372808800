import { api } from "./axiosInterceptor";
import { handleApiError } from "./changeRequests";

// ⇩ ⇩ ⇩  Labour Contract Requests; ⇩ ⇩ ⇩

export const getLabourContractEMAS_API = async (id) => {
  return await api.get(`/employee/labour-contract/hr/${id}?mapFields=false`);
};

export const getLabourContractDoc_API = async (id) => {
  try {
    return await api.get(`employee/labour-contract/hr/${id}?groupBy=true`);
  } catch (err) {
    handleApiError(err);
    throw err;
  }
};

export const downloadLabourContractChangesCSV_API = async (
  filter,
  columns,
  isWithFilter
) => {
  const url = isWithFilter
    ? `employee/hr/labour-contract-update/download?filter=${filter}&columns=${columns}`
    : `employee/hr/labour-contract-update/download?columns=${columns}`;

  return await api.get(url, { responseType: "blob" });
};

export const getLabourContractChangeStatistics_API = async (
  startDate,
  endDate
) => {
  return await api.get(
    `/employee/hr/labour-contract-update/statistics?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getLabourContractUpdates_API = async (limit, offset, filter) => {
  return await api.get(`/employee/hr/labour-contract-update/contracts`, {
    params: {
      limit: limit,
      offset: offset,
      filter: filter,
    },
  });
};
export const getLabourContractDetails_API = async (id) => {
  return await api.get(`/employee/contract/${id}/details`);
};

export const getLabourContractUpdate_API = async (id, isGroup = false) => {
  return await api.get(
    `/employee/hr/labour-contract-update/${id}?groupBy=${isGroup}`
  );
};

export const getEmployeesForLabourContractByID_API = async () => {
  return await api.get(`/employee/hr/labour-contract-update/employees`);
};

export const getStructureEmployeesForLabourContractByID_API = async (
  structureId,
  partId = undefined
) => {
  let url = partId
    ? `/employee/hr/labour-contract-update/structure-employees?partId=${partId}`
    : `/employee/hr/labour-contract-update/structure-employees?structureId=${structureId}`;
  return await api.get(url);
};

export const getStructureForLabourContractChanges_API = async (
  structureId = undefined,
  partId = undefined
) => {
  let url = `/employee/hr/labour-contract-update/nested-children`;
  if (structureId)
    url = `/employee/hr/labour-contract-update/nested-children?structure=${structureId}`;
  else if (partId)
    url = `/employee/hr/labour-contract-update/nested-children?parent=${partId}`;

  return await api.get(url);
};

export const updateLabourContractAfterEMAS_API = async (payload) => {
  return await api.post(`/employee/hr/labour-contract-update`, payload);
};

// ⇧ ⇧ ⇧ Labour Contract Requests; ⇧ ⇧ ⇧
