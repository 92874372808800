import { toast } from "react-toastify";
import { getEmployeeById } from "../api/api";
import {
  createFillingVacantPositionOrder_API,
  getCommand_API,
  getEmployeesForRecallData,
  getEmployeeTable_API,
  getEmployeeWithDetails,
  getFillingVacantPositionOrder_API,
  updateVacantOrder_API,
} from "../api/ordersRequests";
import { api } from "../api/axiosInterceptor";

export const postFillingVacantPositionOrder_SERVICE = async (
  contractName,
  payload
) => {
  try {
    const response = await createFillingVacantPositionOrder_API(
      contractName,
      payload
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getFillingVacantPositionOrder_SERVICE = async (filter) => {
  try {
    const response = await getFillingVacantPositionOrder_API(filter);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getActiveStructuresEmptyStaff = async (payload) => {
  try {
    const response = await api.get("employee/s/staff?isVerified=true", {
      params: payload,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error("Hazırda heç bir strukturda boş ştat yoxdur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
export const getEmployeeId_API_SERVICE = async (id) => {
  try {
    const response = await getEmployeeById(id);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getCommand_API_SERVICE = async (id, contractName) => {
  try {
    const response = await getCommand_API(id, contractName);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getTableEmployee_API_SEVICE = async (id) => {
  try {
    const response = await getEmployeeTable_API(id);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const putVacantOrder_SERVICE = async (contractName, id, payload) => {
  try {
    const response = await updateVacantOrder_API(contractName, id, payload);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const employeesForRecall_SERVICE = async () => {
  try {
    const response = await getEmployeesForRecallData();
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getEmployeeWithDetails_SERVICE = async () => {
  try {
    const response = await getEmployeeWithDetails();
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
