import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import NestedSelect from "../../../../../utils/NestedSelect";
import useOrders from "../../../../../hooks/useOrders";

const Divider = styled(MuiDivider)(spacing);

const EmptyPosition = () => {
  const {
    data,
    errors,
    persent,
    loading,
    employee,
    navigate,
    setPersent,
    setStaffId,
    commandData,
    isFormValid,
    structureId,
    handleSubmit,
    contractData,
    oldStructure,
    setPositionId,
    allStructures,
    setStructureId,
    nestedChildren,
    setContractName,
    updateVacantorder,
    emptyPositionDatas,
    currentContractData,
    setContractPosition,
    handleEmployeeChange,
    handleCurrentContract,
    setEmptyPositionDatas,
    handleInputChange_emptyPosition,
    createFillingVacantPositionOrder,
  } = useOrders();

  useEffect(() => {
    setContractName("filling-vacant-position-order");
  }, []);

  return (
    <form onsubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "700px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <CardContent>
            <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => navigate("/documentation/orders")}
                variant="text"
                sx={{ color: "#fff" }}
              >
                {" "}
                <CloseIcon />
              </Button>
            </Grid>
            <Typography variant="h3" textAlign="center" mt={5} mb={7}>
              Boş vəzifənin həvalə edilməsi
            </Typography>
            <Divider my={6} />
            <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
              <Grid sx={{ width: "50%" }}>
                <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                  Əməkdaş Məlumatları
                </Typography>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                  <Select
                    name="Əməkdaş(A.S.A)"
                    fullWidth
                    label="Əməkdaş(A.S.A)"
                    value={employee.employeeId || ""}
                    onChange={(e) => handleEmployeeChange(e.target.value)}
                  >
                    {data &&
                      data.map((d, i) => (
                        <MenuItem key={i} value={d?._id}>
                          {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Grid sx={{ display: "flex", gap: "25px" }}>
                  <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                    <InputLabel id="Müqavilə nömrəsi">
                      Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      name="Müqavilə nömrəsi"
                      labelId="Müqavilə nömrəsi"
                      label="Müqavilə nömrəsi"
                      fullWidth
                      variant="outlined"
                      value={
                        employee?.empStaffId?.value
                          ? employee?.empStaffId?.value || ""
                          : employee?.empStaffId || ""
                      }
                      onChange={(e) => handleCurrentContract(e.target.value)}
                      disabled={!employee.employeeId}
                    >
                      {contractData &&
                        contractData?.map((d, i) => (
                          <MenuItem
                            key={i}
                            value={d?.contract?.staffs?.[0]?._id}
                          >
                            {d?.contract?.data?.docNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                      pointerEvents: "none",
                    }}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={
                        currentContractData?.contract?.decisionDate || null
                      }
                      label="Müqavilənin tarixi"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </FormControl>
                </Grid>
                <TextField
                  name="Tabel nömrəsi"
                  label="Tabel nömrəsi"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  my={2}
                  value={employee?.tabelNumber || ""}
                />
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                  <Select
                    name="Təşkilat"
                    labelId="Təşkilat"
                    label="Təşkilat"
                    fullWidth
                    variant="outlined"
                    value={
                      currentContractData?.structure?.organization?.name || ""
                    }
                  >
                    <MenuItem
                      value={currentContractData?.structure?.organization?.name}
                    >
                      {currentContractData?.structure?.organization?.name}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <InputLabel id="Struktur">Struktur</InputLabel>
                  <Select
                    name="Struktur"
                    labelId="Struktur"
                    label="Struktur"
                    fullWidth
                    variant="outlined"
                    value={
                      currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name || ""
                    }
                  >
                    <MenuItem
                      value={
                        currentContractData?.contract?.topPartOfStructures?.[0]
                          ?.source?.name
                      }
                    >
                      {
                        currentContractData?.contract?.topPartOfStructures?.[0]
                          ?.source?.name
                      }
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <InputLabel id="Substruktur">Substruktur</InputLabel>
                  <Select
                    name="Substruktur"
                    labelId="Substruktur"
                    label="Substruktur"
                    fullWidth
                    variant="outlined"
                    value={
                      currentContractData?.part?.parent?.source?.name || ""
                    }
                  >
                    <MenuItem
                      value={currentContractData?.part?.parent?.source?.name}
                    >
                      {currentContractData?.part?.parent?.source?.name}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                  <Select
                    name="Vəzifə"
                    labelId="Vəzifə"
                    label="Vəzifə"
                    fullWidth
                    variant="outlined"
                    value={currentContractData?.part?.source.name || ""}
                  >
                    <MenuItem value={currentContractData?.part?.source.name}>
                      {currentContractData &&
                        currentContractData?.part?.source.name}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                  Boş vəzifə məlumatları
                </Typography>
                <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
                  <FormControl sx={{ width: "50%" }} error={!!errors.startDate}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      label="Başlama tarixi"
                      name="startDate"
                      value={
                        emptyPositionDatas?.startDate?.value
                          ? emptyPositionDatas?.startDate?.value
                          : emptyPositionDatas?.startDate
                      }
                      minDate={new Date()}
                      onChange={(date) =>
                        handleInputChange_emptyPosition("startDate", date)
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                    {errors.startDate && (
                      <FormHelperText>{errors.startDate}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl sx={{ width: "50%" }} error={!!errors.endDate}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      label="Bitmə tarixi"
                      name="endDate"
                      minDate={new Date()}
                      value={
                        emptyPositionDatas?.endDate?.value
                          ? emptyPositionDatas?.endDate?.value
                          : emptyPositionDatas?.endDate
                      }
                      onChange={(date) =>
                        handleInputChange_emptyPosition("endDate", date)
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                    {errors.endDate && (
                      <FormHelperText>{errors.endDate}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Ödənişin növü</InputLabel>
                  <Select
                    name="typeOfPayment"
                    labelId="Ödənişin növü"
                    label="Ödənişin növü"
                    fullWidth
                    variant="outlined"
                    value={
                      emptyPositionDatas?.typeOfPayment?.value
                        ? emptyPositionDatas?.typeOfPayment?.value || "cash"
                        : emptyPositionDatas?.typeOfPayment || "cash"
                    }
                    onChange={(e) => {
                      handleInputChange_emptyPosition(
                        "typeOfPayment",
                        e.target.value
                      );
                      setPersent(e.target.value === "percent" ? true : false);
                    }}
                  >
                    <MenuItem value="cash">Nağd</MenuItem>
                    <MenuItem value="percent">Faiz</MenuItem>
                  </Select>
                </FormControl>
                <Grid
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    display: "flex",
                    gap: "25px",
                  }}
                >
                  <TextField
                    label="Əmək haqqına əlavə"
                    name="additionToSalary"
                    value={
                      emptyPositionDatas?.additionToSalary?.value
                        ? emptyPositionDatas?.additionToSalary?.value
                        : emptyPositionDatas?.additionToSalary
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "+" ||
                        e.key === "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      // Sadəcə nöqtə və rəqəmə icazə ver
                      if (!/^\d*\.?\d*$/.test(value)) {
                        return;
                      }

                      if (value.length <= 7) {
                        handleInputChange_emptyPosition(
                          "additionToSalary",
                          value
                        );
                      }
                    }}
                    sx={{
                      width:
                        emptyPositionDatas?.typeOfPayment !== "percent" &&
                        !persent
                          ? "70%"
                          : "100%",
                    }}
                    variant="outlined"
                    my={2}
                  />
                  {emptyPositionDatas?.typeOfPayment !== "percent" && !persent && (
                    <FormControl
                      error={!!errors.currency}
                      sx={{ width: "30%" }}
                    >
                      <InputLabel>Valyuta</InputLabel>
                      <Select
                        name="currency"
                        value={"AZN"}
                        // onChange={(e) =>
                        //   handleInputChange_emptyPosition("currency", e.target.value)
                        // }
                        labelId="Valyuta"
                        label="Valyuta"
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value="AZN">AZN</MenuItem>
                      </Select>
                      {errors.currency && (
                        <FormHelperText>{errors.currency}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Grid>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="Təşkilat-label">Təşkilat</InputLabel>
                  <Select
                    name="Təşkilat"
                    labelId="Təşkilat-label"
                    label="Təşkilat"
                    fullWidth
                    variant="outlined"
                    value={
                      structureId ? emptyPositionDatas?.structure : oldStructure
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setStructureId(value?._id);
                      setEmptyPositionDatas({
                        ...emptyPositionDatas,
                        structure: value,
                      });
                    }}
                  >
                    <MenuItem value={oldStructure} hidden>
                      {oldStructure?.organization?.name || "Seçin"}
                    </MenuItem>
                    {allStructures.map(
                      (structure, index) =>
                        structure?.organization?.name && (
                          <MenuItem key={index} value={structure}>
                            {structure?.organization?.name}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
                <NestedSelect
                  setStaffId={setStaffId}
                  structureId={structureId}
                  setPositionId={setPositionId}
                  nestedChildren={nestedChildren}
                  setContractPosition={setContractPosition}
                />
              </Grid>
            </Grid>
            <Grid mb={4}>
              <TextField
                name="Əmək məcəlləsinin maddəsi"
                label="Əmək məcəlləsinin maddəsi"
                fullWidth
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid mb={10}>
              <TextField
                name="note"
                value={
                  emptyPositionDatas?.note?.value
                    ? emptyPositionDatas?.note?.value
                    : emptyPositionDatas?.note
                }
                onChange={(e) =>
                  handleInputChange_emptyPosition("note", e.target.value)
                }
                inputProps={{ maxLength: 1000 }}
                label="Qeyd"
                fullWidth
                variant="outlined"
                my={2}
              />
            </Grid>
            <Divider my={6} />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Button
                title="İmtina et"
                variant="contained"
                sx={{ height: "100%" }}
                color="error"
                onClick={() => navigate("/documentation/orders")}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Geri
                </p>
              </Button>
              <Button
                disabled={
                  commandData?._id ? false : isFormValid("emptyPosition")
                }
                title="Kənarlaşdırma yarat"
                variant="contained"
                sx={{ height: "100%" }}
                onClick={
                  commandData?._id
                    ? updateVacantorder
                    : createFillingVacantPositionOrder
                }
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Təsdiq et
                </p>
              </Button>
            </Grid>
          </CardContent>
        )}
      </Card>
    </form>
  );
};

export default EmptyPosition;
