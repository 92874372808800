/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  // setCheckedItems,
  setIsClickExportRequest,
  setStructureDataById,
} from "../../../redux/features/slices/structure.slice";
// Services
import * as companyPartServices from "../../../services/companyPart/CompanyPartServices";
import * as employeeServices from "../../../services/employees/EmployeeServices";
import * as managementServices from "../../../services/management/ManagementServices";
import * as staffServices from "../../../services/staff/StaffServices";
import * as structureServices from "../../../services/structure/StructureServices";
// Material UI and Icons
import styled from "@emotion/styled";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add as AddIcon,
  Check,
  Clear,
  DoneAll,
  ExpandMore as ExpandMoreIcon,
  CheckCircle,
  RunningWithErrors
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Card as MuiCard,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import excel_icon from "../../.././assets/icons/excel.svg";
// Sweetalert
import swal from "sweetalert";
// React Toastify
import { ToastContainer, toast } from "react-toastify";
// Utilities
// import { Tooltip  } from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import AccordionItem from "../../../components/companyStructure/accordion/AccordionItem";
import BaseLevelAccordion from "../../../components/companyStructure/accordion/BaseLevelAccordion";
import EmployeeDialog from "../../../components/companyStructure/employeeDialog";
import OrderDialog from "../../../components/companyStructure/orderDialog";
import StaffDialog from "../../../components/companyStructure/staffDialog";
import StructureSelectCard from "../../../components/companyStructure/structureSelectCard";
import CheckboxInput from "../../../components/layouts/checkbox/Checkbox";
import style from "./../structure.module.css"
import {
  docModelTypes,
  structureNamingsEnum,
} from "../../../constants";
import { downloadFile } from "../../../utils/downloadFile";
import StaffTable from "../StaffTable";
import ApproveRequestForm from "./ApproveRequestForm";
import { setTriggingRefresh } from "../../../redux/features/slices/commonThings.slice";
import ApproveDialog from "../../../components/companyStructure/ApproveDialog/ApproveDialog";
import { CUSTOM_BUTTON } from "../structure.styled";

const Card = styled(MuiCard)(spacing);

const CompanyStructureDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  // Selectors
  const { structureNames } = useSelector((state) => state.structureName);
  const {
    structureById,
    // checkedItems,
    isClickExportRequest,
  } = useSelector((state) => state.structure);
  const common = useSelector((state) => state.commonThings);
  const nav = useNavigate();
  // States
  const [loading, setLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [CHILD_EXPANDED, set_CHILD_EXPANDED] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPositionModal, setOpenPositionModal] = useState(false);
  const [isSubStructure, setIsSubStructure] = useState(false);
  const [structureNameDatas, setStructureNameDatas] = useState([]);
  const [subStructureId, setSubStructureId] = useState(null);
  const [activePositions, setActivePositions] = useState({});
  const [active_item, set_active_item] = useState(null);
  const [changes_done, set_changes_done] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState({
    isSelected: false,
    item: null,
  });
  const [order_dialog, set_order_dialog] = useState(false);
  const [isCompletedChosen, setIsCompletedChosen] = useState(false);
  const [isCompletedRequestApprove, setIsCompletedRequestApprove] =
    useState(false);
  const [norecruiter, set_norecruiter] = useState(false);
  const validationSchema = Yup.object({
    count: Yup.number()
      .required("Bu xana məcburidir")
      .min(1, "Minimum dəyər 1 ola bilər"),
    expectedType: Yup.string().required("Bu xana məcburidir"),
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [staffDialogOpen, setStaffDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open_dialogforCommon, set_open_dialogforCommon] = useState(false);
  const [exportTypeBtns, set_exportTypeBtns] = useState("");
  const [selectedStaff, set_selectedStaff] = useState({
    staff_id: null,
    position_id: null,
    staff_unit: null,
    supplemental_wage: null
  });
  const [isClickChangeApproveRequest, setIsClickChangeApproveRequest] =
    useState(false);

  const [checkedItems, set_checkedItems] = useState([]);
  const [checkedExportItems, set_checkedExportItems] = useState([]);
  const [isShowAppDialog, setIsShowAppDialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      parentId: "",
      count: "1",
      expectedType: "",
    },
    validationSchema: validationSchema,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenPositionModal(false);
    setSelectedPosition({
      isSelected: false,
      item: "",
    });
    setActivePositions({});
    formik.resetForm();
  };

  const handleCloseEmployeeDialog = () => {
    setOpenModal(false);
    setDialogOpen(false);
    setSelectedPosition({
      isSelected: false,
      item: "",
    });
    // setActivePositions({});
    formik.resetForm();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const parentId = !isSubStructure
        ? structureById?.organization?._id
        : subStructureId;
      const payload = {
        ...formik.values,
        count: +formik.values.count,
        parentId,
      };

      const data = await structureServices.addChild(id, payload);
      if (data) {
        toast.success("Struktur uğurla əlavə edildi!");

        getStructure();
        setOpenModal(false);
        formik.resetForm();
        set_CHILD_EXPANDED(true);
      } else {
        toast.error("Hər hansı bir xəta baş verdi");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  async function deleteChildRelation(id) {
    try {
      let response = await structureServices.deleteChildRel(id);
      if (response) {
        getStructure();
        enqueueSnackbar("Struktur hissəsi silindi!", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    }
  }

  async function deleteInnerChild(childId) {
    try {
      let response = await structureServices.deleteChild(childId, id);
      if (response) {
        getStructure();
        enqueueSnackbar("Struktur hissə silindi", { variant: "success" });
      }
    } catch (error) {
      if (error.message === "Request already exist") {
        enqueueSnackbar("Hazırda dəyişiklik sorğusu mövcuddur", {
          variant: "error",
        });
      } else enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    }
  }

  const saveDoneChanges = async () => {
    try {
      const data = await structureServices.makeChangesDone(id);
      if (data) {
        toast.success("Struktur dəyişiklikləri uğurla yeniləndi!");
        setOpenModal(false);
        getStructure();
        formik.resetForm();
      } else {
        toast.error("Hər hansı bir xəta baş verdi");
        setOpenModal(false);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const createStructure = () => {
    setOpenModal(true);
    setIsSubStructure(false);
    if (structureNames && structureNames?.length > 0) {
      const data = structureNames.filter(
        (item) =>
          item.name !== structureNamingsEnum.ORGANIZATION &&
          item.name !== structureNamingsEnum.POSITION
      );
      setStructureNameDatas(data);
    }
  };

  const createSubStructure = (e, id) => {
    e.stopPropagation();
    setOpenModal(true);
    setIsSubStructure(true);
    if (structureNames && structureNames?.length > 0) {
      const data = structureNames.filter(
        (item) =>
          item.name !== structureNamingsEnum.ORGANIZATION &&
          item.name !== structureNamingsEnum.POSITION &&
          item.name !== structureNamingsEnum.MANAGEMENT
      );
      setStructureNameDatas(data);
      setSubStructureId(id);
    }
  };
  const createPosition = async (e, parentId) => {
    try {
      setLoading(true);
      e.stopPropagation();
      const willAdded = await swal({
        title: "Əminsiniz mi?",
        text: "Yeni vəzifə əlavə etmək üçün əminsiniz mi?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Əlavə et"],
      });

      if (willAdded) {
        // setOpenPositionModal(false);
        const expectedType = structureNames.find(
          (item) => item.name === structureNamingsEnum.POSITION
        )?._id;
        const payload = { parentId, expectedType, count: 1 };
        const data = await structureServices.addChild(id, payload);
        if (data) {
          /*   let current_parent = data?.children?.find(
            (d) => d?.child?._id === parentId
          );
          getStructure();
          if (current_parent !== undefined) {
            setActivePositions({
              ...activePositions,
              positions: current_parent?.children,
            });*/
          let positions = data?.children?.filter(
            (ch) => ch?.child?.expectedType?.name === "position"
          );
          getStructure();
          // if (positions !== undefined) {
          //   setActivePositions({ ...activePositions, positions });
          // }
          swal("Əlavə edildi!", "Yeni vəzifə uğurla əlavə olundu!", "success");
        }
        // setTrigger(!trigger);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };
  const handleViewPositions = (e, positionDatas, parentItem) => {
    e.stopPropagation();
    setOpenPositionModal(true);
    set_active_item(parentItem?.child?._id)
    setActivePositions({
      ...activePositions,
      positions: positionDatas,
      parentItem: parentItem?.child?._id,
      parentName: parentItem?.child?.part?.name,
      editable: parentItem?.child?.editable,
      docModel: parentItem?.child?.docModel,
      _id: parentItem?.child?._id,
    });
  };

  const loadOptionsForCompanyParts = async (
    _search,
    loadedOptions,
    item,
    isOnlyUnused,
    isBaseLevel,
    childData,
    includedId
  ) => {
    const _start = loadedOptions.length;
    if (item?.expectedType?.name !== structureNamingsEnum.MANAGEMENT) {
      const { parts, total } = await companyPartServices.getCompanyStructures({
        limit: 10,
        skip: _start,
        name: _search,
        type: item?.expectedType?._id,
        notUsed: isOnlyUnused,
        parentRel: isBaseLevel
          ? structureById?.organization?._id
          : activePositions?.parentItem ?? childData?._id,
        includedId: includedId,
      });
      return {
        options: parts.map((item) => ({
          value: item._id,
          // label: item.name + " - " + generateCapatilazeWord(item?.type?.name),
          label: item.name,
        })),
        hasMore: _start < total - 1,
      };
    } else {
      const { managements, total } = await managementServices.getManagements({
        limit: 10,
        skip: _start,
        title: _search,
        notUsed: isOnlyUnused,
        parentRel: isBaseLevel
          ? structureById?.organization?._id
          : childData?._id,
      });
      return {
        options: managements.map((item) => ({
          value: item._id,
          label: item.employee
            ? item.employee?.firstname +
            " " +
            item.employee?.lastname +
            " " +
            item.employee?.fathername +
            " - " +
            item?.title
            : item?.title,
        })),
        hasMore: _start < total - 1,
      };
    }
  };

  const handleSaveCompanyParts = async (sourceId, relId, expectedType) => {
    try {
      const payload = {
        source: sourceId,
        sourceType:
          expectedType === structureNamingsEnum.MANAGEMENT
            ? "management"
            : "part",
      };
      const data = await structureServices.addSourceToRel(relId, payload);
      if (data) {
        toast.success("Uğurla əlavə edildi!");
        getStructure();
        setOpenPositionModal(false);
      } else {
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
    }
  };

  const handleOnChangeStructurePositions = async (form) => {
    try {
      const { data } = await structureServices.updateStructureChild({
        structureId: structureById?._id,
        payload: {
          relId: form.relId,
          partId: form.position,
          max_salary: form.max_salary,
        },
      });
      data && dispatch(setTriggingRefresh(!common.refreshTrigger));
      // data && dispatch(setTriggingRefresh(!common.refreshTrigger));
      // console.log(partId);
    } catch (error) {
      console.log("err", error);
      // swal("Xəta baş verdi", { title: "Xəta" });
    }
  };

  async function deleteStaff(child) {
    const resp = await employeeServices.removeStaff(child?._id);
    if (resp && Object.keys(resp).length > 0) {
      getStructure();
      toast.success("Ştat uğurla silindi!");
    }
  }

  const handleChangeStructureCheckbox = (event) => {
    event.stopPropagation();
    // event.preventDefault();
    const { id, name, checked } = event.target;
    if (checked) {
      // dispatch(setCheckedItems([...checkedItems, { id, name }]));
      set_checkedItems([...checkedItems, { id, key: name, value: "" }])
    } else {
      const filteredItems = checkedItems.filter((item) => item.id !== id);
      // dispatch(setCheckedItems(filteredItems));
      set_checkedItems(filteredItems)
    }
  };

  const handleExportCheckbox = (event) => {
    event.stopPropagation();
    const { id, checked } = event.target;
    if (checked) {
      let arr = [...checkedExportItems];
      arr.push(id);
      set_checkedExportItems(arr)
    } else {
      const filteredItems = checkedExportItems.filter((item) => item !== id);
      set_checkedExportItems(filteredItems)
    }
  };

  const handleCreateChangeApproveRequest = () => {
    setIsClickChangeApproveRequest(true);
  };

  const handleExportRequest = () => {
    dispatch(setIsClickExportRequest(true));
  };
  const cancelExport = () => {
    dispatch(setIsClickExportRequest(false));
  };

  const handleCompleteChoosen = () => {
    setIsCompletedChosen(true);
  };
  const handleExport = async (type) => {
    try {
      const data = await staffServices.exportStaff(
        checkedExportItems,
        structureById?._id,
        type
      );
      if (data) {
        downloadFile(
          data,
          `${moment(new Date()).format("DD/mm/YYYY")}-report`,
          "xlsx"
        );
        enqueueSnackbar("Eksport sorğusu göndərildi", { variant: "success" });
        dispatch(setIsClickExportRequest(false));
        // dispatch(setCheckedItems([]));
        set_checkedExportItems([])
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      console.log(error, "error");
    }
  };

  const handleRequestApprove = async (requestPayload) => {
    try {
      const data = await structureServices.createEditRequest(requestPayload);
      if (data) {
        enqueueSnackbar(
          "Dəyişiklik sorğusu yaradıldı, imzalanması gözlənilir",
          {
            variant: "success",
          }
        );
      }
      setIsCompletedRequestApprove(true);
      setIsCompletedChosen(false);
      // dispatch(setCheckedItems([]));
      set_checkedItems([])
      setIsClickChangeApproveRequest(false);
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getStructure();
  }, [common.refreshTrigger, isUpdated]);

  async function getStructure() {
    try {
      setLoading(true);
      const response = await structureServices.getStructureById(id);
      if (response) {
        setLoading(false);
        if (response?._id) {
          let current_structure = findChildById(response?.children, active_item);
          setActivePositions({
            ...activePositions,
            positions: current_structure?.children,
            parentItem: current_structure?.child?._id,
            parentName: current_structure?.child?.part?.name,
            editable: current_structure?.child?.editable,
            docModel: current_structure?.child?.docModel,
            _id: current_structure?.child?._id,
          });
          set_norecruiter(response?.recruiterStaff ? false : true);
          set_changes_done(JSON.stringify(response).includes("editable"));
          dispatch(setStructureDataById(response));
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  }

  function findChildById(children, item_id) {
    for (let childPair of children) {
      if (childPair.child._id === item_id) {
        return childPair; // Found the matching child
      }

      // If the child has children, search recursively
      if (childPair.children && childPair.children.length > 0) {
        const result = findChildById(childPair.children, item_id);
        if (result) {
          return result;
        }
      }
    }

    return null; // If no match is found
  }

  const [expanded_table, set_expanded_table] = useState({
    expanded: false,
    index: [],
  });

  const handleOpenStaffDialog = (item) => {
    setStaffDialogOpen(true);
    setSelectedItem(item);
  }

  const handleOpenEmployeeDialog = (
    staff_id,
    position_id,
    staff_unit,
    supplemental_wage
  ) => {
    // setSelectedStaffUnit(staffUnit);
    setDialogOpen(true);
    // setSelectedStaffId(staff_id);
    // setSelectedPositionId(position_id);
    set_selectedStaff({
      staff_id,
      position_id,
      staff_unit,
      supplemental_wage
    })
  };

  const cancelAllEditProcess = () => {
    // dispatch(setCheckedItems([]));
    set_checkedItems([])
    setIsClickChangeApproveRequest(false);
  };

  const [data, set_data] = useState([]);
  const loadOptionsForCommand = async (selectedStaffId) => {
    try {
      // const _start = loadedOptions?.length;
      const response = await employeeServices.checkOrder(selectedStaffId);
      if (response) {
        set_data(response);
      }
    } catch (error) {
      console.log("error: ", error);
      return {
        options: {
          value: "",
          label: "",
        },
        hasMore: false,
      };
    }
  };

  const handleOpenOrderDialog = (staff_id) => {
    set_order_dialog(true);
    loadOptionsForCommand(staff_id);
    // setSelectedStaffId(staff_id);
    set_selectedStaff({ ...selectedStaff, staff_id })
  };

  const handleCloseOrderDialog = () => {
    set_order_dialog(false);
  };

  // Approve dialog
  const handlerOpenDialogApprove = () => {
    setIsShowAppDialog(true);
  };

  const handlerCloseDialogApprove = () => {
    setIsShowAppDialog(false);
  };
  const handleAddItemClick = (event) => {
    event.stopPropagation();
    set_open_dialogforCommon(true);
  };
  return (
    <>
      <ToastContainer />
      <Dialog
        open={openPositionModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="dialog"
        style={{ backgroundColor: "#ffffff21" }}
      >

        <div className="d-flex aic pt-4 pe-4 justify-content-end">

          <div
            id="form-dialog-title"
            className={`${classes.fs_23} text-center`}
            style={{
              width:
                activePositions?.docModel === docModelTypes.COMPANYPART &&
                  activePositions?.editable
                  ? "87%"
                  : "100%",
            }}
          >
            {!structureById?.isVerified &&
              <Tooltip title={applyProcess} sx={{ tooltip: { fontSize: "16px" } }}><RunningWithErrors style={{ color: "#ffae00", marginRight: "10px" }} /></Tooltip>}
            Ştat cədvəli - {activePositions?.parentName}
          </div>
          <div>
            {/* <Button variant="outlined">Vəzifə əlavə et</Button> */}
            {activePositions?.docModel === docModelTypes.COMPANYPART &&
              activePositions?.editable ? (
              <Button
                variant="outlined"
                color="primary"
                className={`${activePositions?.editable ? "mx-3" : ""}`}
                onClick={(e) => createPosition(e, activePositions?._id)}
              >
                <AddIcon />
                Yeni Vəzifə yarat
              </Button>
            ) : null}
          </div>
        </div>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <StaffTable
              isClickChangeApproveRequest={isClickChangeApproveRequest}
              loadOptionsForCompanyParts={loadOptionsForCompanyParts}
              // saveNewPosition={saveNewPosition}
              activePositions={activePositions}
              setActivePositions={setActivePositions}
              set_expanded_table={set_expanded_table}
              // set_new_position={set_new_position}
              // new_position={new_position}
              handleOpenStaffDialog={handleOpenStaffDialog}
              // handleNewPosition={handleNewPosition}
              expanded_table={expanded_table}
              handleOpenOrderDialog={handleOpenOrderDialog}
              structureById={structureById}
              handleChangeStructureCheckbox={handleChangeStructureCheckbox}
              handleOpenEmployeeDialog={handleOpenEmployeeDialog}
              checkedItems={checkedItems}
              classes={classes}
              // positionBtn={{
              //   set: set_positionBtnDisable,
              //   state: positionBtnDisable,
              // }}
              deleteStaff={deleteStaff}
              getStructure={getStructure}
              handleUpdateStructure={handleOnChangeStructurePositions}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <StaffDialog
        classes={classes}
        open={staffDialogOpen}
        onClose={() => setStaffDialogOpen(false)}
        item={selectedItem}
        setActivePositions={setActivePositions}
        getStructure={getStructure}
        handleAddItemClick={handleAddItemClick}
      />
      {
        dialogOpen &&
        <EmployeeDialog
          classes={classes}
          open={dialogOpen}
          onClose={handleCloseEmployeeDialog}
          getStructure={getStructure}
          selectedStaff={selectedStaff}
          activePositions={activePositions}
          setActivePositions={setActivePositions}
        />
      }
      <OrderDialog
        classes={classes}
        open={order_dialog}
        onClose={handleCloseOrderDialog}
        data={data}
        selectedStaff={selectedStaff}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
      />
      <ApproveDialog
        open={isShowAppDialog}
        structureId={structureById?._id}
        onClose={handlerCloseDialogApprove}
        onSuccess={async () => {
          toast.success("İşə götürən və struktur uğurla təsdiqləndi!");
          await getStructure();
          setIsShowAppDialog(false);
        }}
      />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          my={6}
          style={{ height: "45vh" }}
        >
          <CircularProgress />
        </Box>
      ) : !isCompletedChosen ? (
        <>
        <Button variant="outlined" onClick={() => nav("/company-structure")}>Geri</Button>
          <div className="d-flex justify-content-between mb-5">
            <div className="d-flex align-items-center">
              <Typography variant="h2" className="d-flex align-items-baseline">
                {structureById?.isVerified ?
                  <Tooltip style={{ fontSize: "16px" }} title={verifiedStructure}><CheckCircle style={{ color: "greenyellow" }} className="me-2" /></Tooltip> :
                  <Tooltip title={applyProcess} sx={{ tooltip: { fontSize: "16px" } }}><RunningWithErrors style={{ color: "#ffae00" }} className="me-2" /></Tooltip>}
                {structureById?.organization?.name}
              </Typography>
              {isClickChangeApproveRequest &&
                (!structureById?.pendingChange ? (
                  // <span className="ms-4">
                  //   <CheckboxInput
                  //     labelContent={"Dəyişiləcək"}
                  //     name={structureById?.organization?.name}
                  //     id={structureById?._id}
                  //     onChange={handleChangeStructureCheckbox}
                  //     checked={checkedItems.some(
                  //       (checkedItem) => checkedItem?.id === structureById?._id
                  //     )}
                  //   />
                  // </span>
                  <Tooltip title="Dəyişiklik etmək üçün seçin" className="ms-2">
                    <Checkbox
                      name={structureById?.organization?.name}
                      id={structureById?._id}
                      onChange={handleChangeStructureCheckbox}
                      checked={checkedItems.some(
                        (checkedItem) => checkedItem?.id === structureById?._id
                      )} />
                  </Tooltip>
                ) : (
                  <div
                    className="ms-3"
                    style={{ color: "#9ce99c", fontSize: "16px" }}
                  >
                    {structureById?.requestedUser &&
                      `${structureById?.requestedUser} hal hazırda dəyişiklik edir`}
                  </div>
                ))}
            </div>
            <div className="d-flex w-75 justify-content-end">
              <>
                <div
                  className={`${isClickChangeApproveRequest
                    ? "d-flex align-items-center w-50"
                    : ""
                    }`}
                >
                  {!structureById?.isVerified && (
                    <div className="mb-2">
                      <Tooltip title={structureById?.hasSourcelessRels ? "Zəhmət olmasa yaradıılmış strukturları adlandırın" :
                        structureById?.areThereUncompletedContracts ? "Zəhmət olmasa bütün Ə.M. sistemə daxil edin" : ""}>
                        <span>
                          <button
                            className={style?.custom_button}
                            onClick={handlerOpenDialogApprove}
                            disabled={structureById?.hasSourcelessRels || structureById?.areThereUncompletedContracts}
                          ><Check /> Strukturu Təsdiq et</button>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {structureById?.editable && (
                    <div className="d-flex justify-content-end mb-2">
                      <button
                        className={style?.custom_button}
                        onClick={createStructure}
                      ><AddIcon /> Struktur Əlavə Et</button>
                    </div>
                  )}
                  <div className="me-4">
                    {isClickChangeApproveRequest && (
                      <Tooltip bgColor={"rgba(132, 80, 199, 0.35)"}>
                        Siz hal-hazırda “dəyişiklik seçimi” modundasınız. Xahiş
                        olunur dəyişəcəyiniz TS vahidlərini seçəsiniz.
                        {isClickChangeApproveRequest && (
                          <div className="mt-2 text-right">
                            <button
                              className={reject_button}
                              onClick={cancelAllEditProcess}
                            >İmtina</button>
                          </div>
                        )}
                      </Tooltip>
                    )}
                  </div>

                  <div className={isClickChangeApproveRequest ? "w-50" : ""}>
                    <div className="text-end d-flex flex-column">
                      {!isClickChangeApproveRequest && !changes_done && (
                        <div className="mb-2">
                          <Tooltip title={norecruiter || structureById?.isThereEditRequest && "Zəhmət olmasa mövcud dəyişiklik sorğunuzu yekunlaşdırın"}>
                            <CUSTOM_BUTTON
                              className={style?.custom_button}
                              $height="38px"
                              disabled={
                                norecruiter || structureById?.isThereEditRequest || isCompletedRequestApprove
                                  ? true
                                  : false
                              }
                              onClick={handleCreateChangeApproveRequest}
                            >Dəyişiklik Sorğusu Yarat <DoneAll /></CUSTOM_BUTTON>
                          </Tooltip>
                        </div>
                      )}
                      {isClickChangeApproveRequest &&
                        structureById?.recruiterStaff && (
                          <div className="mb-2">
                            <CUSTOM_BUTTON
                              $height="38px"
                              className={style?.custom_button}
                              disabled={!checkedItems.length}
                              onClick={handleCompleteChoosen}
                            >Seçimi Bitirdim <DoneAll /></CUSTOM_BUTTON>
                          </div>
                        )}
                      {changes_done && structureById?.recruiterStaff && (
                        <div className="mb-2">
                          <Tooltip title={structureById?.hasSourcelessRels ? "Zəhmət olmasa yarımçıq struktur dəyişikliyinizi yekunlaşdırın" : "Başladılmış dəyişiklik sorğusunu başa çatdırırsınız"}>
                            <CUSTOM_BUTTON
                              $height="38px"
                              className={style?.custom_button}
                              disabled={structureById?.hasSourcelessRels}
                              onClick={saveDoneChanges}
                            >Dəyişikliyi Bitirdim <DoneAll /></CUSTOM_BUTTON>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div>
                      {structureById?.children?.length ? (
                        !isClickExportRequest && !exportTypeBtns ? (
                          <CUSTOM_BUTTON
                            $height="38px"
                            className={style?.custom_button}
                            onClick={() => {
                              set_exportTypeBtns();
                              handleExportRequest();
                            }}
                          ><img src={excel_icon} alt="" className="me-2" /> Eksport et</CUSTOM_BUTTON>
                        ) : (
                          <div className="d-flex">
                            <>
                              <CUSTOM_BUTTON
                                $height="38px"
                                className={reject_button}
                                onClick={() => {
                                  set_exportTypeBtns(false);
                                  cancelExport();
                                  set_checkedExportItems([])
                                }}
                              >İmtina</CUSTOM_BUTTON>
                              <CUSTOM_BUTTON
                                $height="38px"
                                className={`${style?.custom_button} ms-2 me-2`}
                                onClick={() => handleExport("named")}
                              >Adlı</CUSTOM_BUTTON>
                              <CUSTOM_BUTTON
                                $height="38px"
                                className={style?.custom_button}
                                onClick={() => handleExport("nameless")}
                              >Adsız</CUSTOM_BUTTON>
                            </>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            </div>

            <Dialog
              open={openModal}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {isSubStructure ? "Sub-struktur əlavə et" : "Struktur əlavə et"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.mb_2}>
                  Zəhmət olmasa əlavə etmək istədiyiniz{" "}
                  {isSubStructure ? "sub-" : ""}strukturun sayını və{" "}
                  {isSubStructure ? "sub-" : ""}strukturun tipini boş xanalara
                  daxil edin
                </DialogContentText>
                <div className="mt-3 mb-3">
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="max-width"
                      style={
                        formik.touched?.type && formik.errors?.type
                          ? { color: "#f44336" }
                          : null
                      }
                    >
                      {isSubStructure ? "Sub-" : ""}Struktur tipi
                    </InputLabel>
                    <Select
                      name="expectedType"
                      label={`${isSubStructure ? "Sub-" : ""}Struktur tipi*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched?.expectedType &&
                        formik.errors?.expectedType
                      )}
                      value={formik.values?.expectedType}
                    >
                      {structureNameDatas.length > 0
                        ? structureNameDatas.map((item, index) => (
                          <MenuItem
                            value={item._id}
                            key={`${item._id} ${index + 1}`}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    <FormHelperText>
                      {formik.touched?.expectedType && (
                        <span
                          style={
                            formik.touched?.expectedType
                              ? { color: "#f44336" }
                              : null
                          }
                        >
                          {formik.errors?.expectedType}
                        </span>
                      )}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    margin="dense"
                    id="count"
                    name="count"
                    label={`${isSubStructure ? "Sub-" : ""}Struktur sayı`}
                    type="number"
                    className={classes.input}
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "count",
                        /^[^.,]*$/.test(e.target.value) && e.target.value
                      )
                    }
                    value={formik.values.count}
                    defaultValue={1}
                    error={Boolean(
                      formik.touched?.count && formik.errors?.count
                    )}
                    helperText={formik.touched?.count && formik.errors?.count}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button type="button" onClick={handleClose} color="primary">
                  Ləğv et
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={handleSubmit}
                >
                  Əlavə et
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {structureById?.children && structureById?.children.length > 0 ? (
            structureById.children.map((item, index) => {
              return (
                <div key={`${item}${index + 1}`}>
                  {item?.child?.type && item?.child?.type?.name ? (
                    <div className="mb-3">
                      <Accordion
                        expanded={expanded === index}
                        onChange={
                          item?.children?.filter(
                            (data) =>
                              data?.child?.expectedType?.name !==
                              structureNamingsEnum.POSITION
                          )?.length > 0
                            ? handleChange(index)
                            : () => { }
                        }
                        className={classes.accordion}
                      >
                        <AccordionSummary
                          expandIcon={
                            item?.children?.filter(
                              (data) =>
                                data?.child?.expectedType?.name !==
                                structureNamingsEnum.POSITION
                            )?.length > 0 ? (
                              <ExpandMoreIcon />
                            ) : (
                              <div style={{ width: "20px", height: "20px" }}>
                                {" "}
                              </div>
                            )
                          }
                          style={{
                            fontFamily:
                              item?.child?.type?.level?.style?.font?.name,
                            backgroundColor:
                              item?.child?.type?.level?.style?.backgroundColor,
                            border: `${item?.child?.type?.level?.style?.border?.width}px solid ${item?.child?.type?.level?.style?.border?.color}`,
                          }}
                          className={`${item?.child?.docModel === docModelTypes.COMPANYPART
                            // ? classes.accordion_baseBg
                            // : classes.accordion_managament_bg
                            } ${classes.accordion_summary}`}
                        >
                          <BaseLevelAccordion
                            classes={classes}
                            structure={structureById}
                            item={item}
                            isClickChangeApproveRequest={
                              isClickChangeApproveRequest
                            }
                            checkedItems={checkedItems}
                            checkedExportItems={checkedExportItems}
                            handleExportCheckbox={handleExportCheckbox}
                            createSubStructure={createSubStructure}
                            createPosition={createPosition}
                            handleViewPositions={handleViewPositions}
                            handleChangeStructureCheckbox={handleChangeStructureCheckbox}
                            structureById={structureById}
                            activePositions={activePositions}
                          />
                          {!item?.children?.length && item?.child?.editable && (
                            <Tooltip title="Struktur hissəni sil">
                              <Clear
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "10px",
                                }}
                                onClick={() => {
                                  !structureById?.recruiterStaff ||
                                    !item?.child?.isAccepted
                                    ? deleteInnerChild(item?.child?._id)
                                    : deleteChildRelation(item?.child?._id);
                                }}
                              />
                            </Tooltip>
                          )}
                        </AccordionSummary>
                        <AccordionDetails className="accordion_details">
                          {item?.children
                            .filter(
                              (item) =>
                                item?.child?.expectedType?.name !==
                                structureNamingsEnum?.POSITION
                            )
                            .map((data, index) => {
                              return data?.child?.type &&
                                data?.child?.type?.name ? (
                                <AccordionItem
                                  key={data._id}
                                  structure={structureById}
                                  classes={classes}
                                  data={data}
                                  createPosition={createPosition}
                                  createSubStructure={createSubStructure}
                                  loadOptionsForCompanyParts={
                                    loadOptionsForCompanyParts
                                  }
                                  isClickChangeApproveRequest={
                                    isClickChangeApproveRequest
                                  }
                                  handleSaveCompanyParts={
                                    handleSaveCompanyParts
                                  }
                                  handleViewPositions={handleViewPositions}
                                  parentItem={item}
                                  index={index}
                                  expanded={CHILD_EXPANDED}
                                  handleChangeStructureCheckbox={
                                    handleChangeStructureCheckbox
                                  }
                                  checkedItems={checkedItems}
                                />
                              ) : (
                                <StructureSelectCard
                                  classes={classes}
                                  item={data}
                                  isExistLeft={true}
                                  loadOptionsForCompanyParts={
                                    loadOptionsForCompanyParts
                                  }
                                  handleSaveCompanyParts={
                                    handleSaveCompanyParts
                                  }
                                  childData={item?.child}
                                  isContentLeft={true}
                                  structure_id={structureById?._id}
                                />
                              );
                            })}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ) : (
                    <>
                      <StructureSelectCard
                        classes={classes}
                        isExistLeft={true}
                        item={item}
                        loadOptionsForCompanyParts={loadOptionsForCompanyParts}
                        handleSaveCompanyParts={handleSaveCompanyParts}
                        isBaseLevel={true}
                        structure_id={structureById?._id}
                      />
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <Card>
              <CardContent>
                <Typography variant="h3" textAlign="center">
                  Hər hansı bir məlumat tapılmadı və ya xəta baş verdi
                </Typography>
              </CardContent>
            </Card>
          )}
        </>
      ) : (
        <ApproveRequestForm
          setIsCompletedChosen={setIsCompletedChosen}
          checkedItems={{ state: checkedItems, set: set_checkedItems }}
          handleRequestApprove={handleRequestApprove}
        />
      )
      }
    </>
  );
};

export default CompanyStructureDetail;

const applyProcess = "Cari struktur tətbiq prosesindədir. Bu halda sorğu yaratmadan struktur üzrə istənilən dəyişiklik edilə bilər."
const verifiedStructure = "Cari struktur təsdiqlənmişdir. Bu halda yalnız dəyişiklik sorğusu yaradaraq struktur üzrə istənilən dəyişiklik edilə bilər."
const reject_button = [
  style?.custom_button,
  style?.red
].join(" ");

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  mb_2: {
    marginBottom: "15px !important",
  },
  accordion: {
    backgroundColor: "transparent !important",
  },
  structureAuthor: {
    color: "#D5D7D8 !important",
    fontSize: "14px",
  },
  card_content: {
    marginBottom: "15px",
    padding: "16px",
  },
  select_styles: {
    // position: "absolute",
    color: "#333",
    // marginTop: "10px",
  },
  select_styles_left: {
    left: "15px",
  },
  accordion_summary: {
    borderRadius: "15px !important",
    // border: "1px solid #2a3889 !important",
  },
  accordion_baseBg: {
    backgroundColor: "#233044 !important",
    border: "1px solid #2a3889 !important",
  },
  accordion_inner_bg: {
    backgroundColor: "#41608F !important",
    border: "1px solid #2a3889 !important",
  },
  accordion_managament_bg: {
    backgroundColor: "#147177bf !important",
    border: "1px solid #2a3889 !important",
  },
  managament_create_sub_structure_button: {
    backgroundColor: "#7f7e1b !important",
  },
  line: {
    width: "4px",
    height: "auto",
    backgroundColor: "#fff",
  },
  card_wrap: {
    marginLeft: "25px",
  },
  card_wrap_icon: {
    fontSize: "30px",
    marginBottom: "20px",
  },
  mr_35: {
    marginRight: "35px",
  },
  fs_18: {
    fontSize: "18px",
  },
  fs_23: {
    fontSize: "23px !important",
  },
  sub_customize_buttons: {
    backgroundColor: "transparent !important",
    border: "1px solid #fff !important",
    fontSize: "14px !important",
  },
  pr_15: {
    paddingRight: "15px",
  },
  positionCardWrapper: {
    background: "#FFF",
    boxShadow: "0px 0px 14px 0px rgba(53, 64, 82, 0.05)",
    padding: "5px 15px !important",
    marginBottom: "15px",
  },
  positionCardUp: {
    padding: "20px 5px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  positionCardDown: {
    borderTop: "1px solid #E0E0E0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px 5px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  cursor: {
    cursor: "pointer",
  },
  cursor_auto: {
    cursor: "auto !important",
  },
  staffSalary: {
    padding: "5px 5px 8px",
    fontSize: "22px",
  },
  dis: {
    backgroundColor: "5px 5px 8px",
    fontSize: "22px",
  },
});
