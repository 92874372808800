import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/layouts/header/Header";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  overtimeColumns,
  overtimeMockData,
  overTimeValidationSchema,
} from "./mockOverTime";
import Table from "../../components/table/Table";
import CustomAccordion from "../../components/ui/Accordion/Accordion";

const OverTime = () => {
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [offset, setOffset] = useState(0);
  const [empData, setEmpData] = useState(["Fazil", "Mazil"]);
  const [limit, setLimit] = useState(5);
  const headerData = {
    title: "İş vaxtından artıq işə çıxma",
    variant: "h3",
  };
  const initialValues = {
    overtime: [
      {
        employee: "",
        contract: "",
        contractDate: "",
        structure: "",
        subStructure: "",
        position: "",
        reason: "",
        contractItem: [],
        footer: [
          {
            date: "",
            hour: "",
            paymentType: "2x",
          },
        ],
      },
    ],
    type: "",
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-end">
          <Button
            title="Yarat"
            onClick={() => {
              set_show_Accordion(!show_Accordion);
              setIsDate(false);
            }}
            variant="contained"
            sx={{ height: "100%" }}
          >
            <p
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Yarat <AddIcon />
            </p>
          </Button>
        </div>
        {show_Accordion && (
          <Formik
            initialValues={initialValues}
            validationSchema={overTimeValidationSchema}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              setErrors,
              dirty,
            }) => (
              <>
                {console.log(values)}
                <Form>
                  <FieldArray name="overtime">
                    {({ push, remove }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Grid sx={{ width: "33%" }}>
                          <FormControl
                            fullWidth
                            error={
                              touched?.type &&
                              errors?.type &&
                              Boolean(errors?.type)
                            }
                          >
                            <InputLabel id={`type`}>Növ</InputLabel>
                            <Field
                              as={Select}
                              name={`type`}
                              id={`type`}
                              labelId={`type`}
                              label="Növ"
                              defaultValue=""
                              fullWidth
                              error={
                                touched?.type &&
                                errors?.type &&
                                Boolean(errors?.type)
                              }
                            >
                              {overtimeMockData?.[0]?.overtimeTypes.map(
                                (type, index) => {
                                  return (
                                    <MenuItem value={type?.value}>
                                      {type?.label}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Field>
                            <ErrorMessage
                              name={`type`}
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        {values?.type &&
                          values.overtime.map((_, index) => (
                            <div>
                              <Accordion defaultExpanded>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Typography mt={3} mb={3} variant="h6">
                                    {index + 1}) Əməkdaş
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div>
                                    <Grid
                                      key={index}
                                      sx={{
                                        gap: "10px",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        mb: 2,
                                      }}
                                    >
                                      <Grid
                                        sx={{
                                          gap: "10px",
                                          width: "100%",
                                          display: "flex",
                                        }}
                                      >
                                        <Grid sx={{ width: "33%" }}>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors?.overtime?.[index]
                                                ?.employee &&
                                              touched?.overtime?.[index]
                                                ?.employee &&
                                              Boolean(
                                                errors?.overtime?.[index]
                                                  ?.employee
                                              )
                                            }
                                          >
                                            <Field
                                              name={`overtime[${index}].employee`}
                                            >
                                              {({ field, form }) => (
                                                <Autocomplete
                                                  options={empData || []}
                                                  getOptionLabel={(option) =>
                                                    `${option || ""}`
                                                  }
                                                  value={
                                                    values?.overtime?.[index]
                                                      ?.employee || null
                                                  }
                                                  onChange={(
                                                    event,
                                                    selectedOption
                                                  ) => {
                                                    form.setFieldValue(
                                                      `overtime[${index}].employee`,
                                                      selectedOption
                                                        ? selectedOption
                                                        : ""
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Əməkdaş"
                                                      variant="outlined"
                                                      error={
                                                        Boolean(
                                                          errors?.overtime?.[
                                                            index
                                                          ]?.employee
                                                        ) &&
                                                        Boolean(
                                                          touched?.overtime?.[
                                                            index
                                                          ]?.employee
                                                        )
                                                      }
                                                    />
                                                  )}
                                                />
                                              )}
                                            </Field>

                                            <ErrorMessage
                                              name={`overtime[${index}].employee`}
                                              component="div"
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid sx={{ width: "33%" }}>
                                          <FormControl
                                            fullWidth
                                            error={
                                              errors?.overtime?.[index]
                                                ?.contract &&
                                              touched?.overtime?.[index]
                                                ?.contract &&
                                              Boolean(
                                                errors?.overtime?.[index]
                                                  ?.contract
                                              )
                                            }
                                          >
                                            <InputLabel>
                                              Əmək müqaviləsi
                                            </InputLabel>
                                            <Field
                                              as={Select}
                                              name={`overtime[${index}].contract`}
                                              label="Əmək müqaviləsi"
                                              defaultValue=""
                                              fullWidth
                                              disabled={
                                                !values?.overtime?.[index]
                                                  ?.employee
                                              }
                                              error={
                                                errors?.overtime?.[index]
                                                  ?.contract &&
                                                touched?.overtime?.[index]
                                                  ?.contract &&
                                                Boolean(
                                                  errors?.overtime?.[index]
                                                    ?.contract
                                                )
                                              }
                                            >
                                              <MenuItem value="ƏM8000">
                                                ƏM8000
                                              </MenuItem>
                                            </Field>
                                            <ErrorMessage
                                              name={`overtime[${index}].contract`}
                                              component="div"
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid
                                          sx={{
                                            width: "33%",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          <Field
                                            name={`overtime[${index}].contractDate`}
                                          >
                                            {({ field }) => (
                                              <DatePicker
                                                {...field}
                                                label="Müqavilənin tarixi"
                                                inputFormat="dd/MM/yyyy"
                                                value={field.value || null}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        sx={{
                                          gap: "10px",
                                          width: "100%",
                                          display: "flex",
                                        }}
                                      >
                                        <Grid
                                          sx={{
                                            width: "33%",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          <Field
                                            type="text"
                                            name={`overtime[${index}].structure`}
                                            as={TextField}
                                            fullWidth
                                            label="Struktur"
                                          />
                                        </Grid>
                                        <Grid
                                          sx={{
                                            width: "33%",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          <Field
                                            type="text"
                                            name={`overtime[${index}].subStructure`}
                                            as={TextField}
                                            fullWidth
                                            label="Substruktur"
                                          />
                                        </Grid>
                                        <Grid
                                          sx={{
                                            width: "33%",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          <Field
                                            type="text"
                                            name={`overtime[${index}].position`}
                                            as={TextField}
                                            fullWidth
                                            label="Vəzifə"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        sx={{
                                          gap: "10px",
                                          width: "100%",
                                          display: "flex",
                                        }}
                                      >
                                        <Grid sx={{ width: "50%" }}>
                                          <FormControl
                                            fullWidth
                                            error={
                                              values?.overtime?.[index]
                                                ?.contract &&
                                              errors?.overtime?.[index]
                                                ?.contractItem &&
                                              touched?.overtime?.[index]
                                                ?.contractItem &&
                                              values?.overtime?.[index]
                                                ?.contractItem?.length === 0
                                            }
                                          >
                                            <InputLabel
                                              id={`overtime[${index}].contractItem`}
                                            >
                                              Əmək məcəlləsinin maddəsi
                                            </InputLabel>
                                            <Field
                                              fullWidth
                                              as={Select}
                                              multiple
                                              defaultValue=""
                                              disabled={
                                                !values?.overtime?.[index]
                                                  ?.contract
                                              }
                                              value={
                                                values?.overtime?.[index]
                                                  ?.contractItem || []
                                              }
                                              label="Əmək məcəlləsinin maddəsi"
                                              id={`overtime[${index}].contractItem`}
                                              name={`overtime[${index}].contractItem`}
                                              labelId={`overtime[${index}].contractItem`}
                                              onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue(
                                                  `overtime[${index}].contractItem`,
                                                  value
                                                );
                                              }}
                                              renderValue={(selected) => {
                                                const labels = selected
                                                  .map(
                                                    (sel) =>
                                                      overtimeMockData?.[0]?.contractItemTypes?.[
                                                        values?.type
                                                      ]?.find(
                                                        (item) =>
                                                          item.value === sel
                                                      )?.label
                                                  )
                                                  .filter(Boolean);

                                                return labels.length > 0
                                                  ? labels.join(", ")
                                                  : "Seçilməyib";
                                              }}
                                              MenuProps={{
                                                PaperProps: {
                                                  sx: { width: 200 },
                                                  style: { maxHeight: 300 },
                                                },
                                                MenuListProps: {
                                                  style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  },
                                                },
                                              }}
                                            >
                                              {overtimeMockData?.[0]?.contractItemTypes?.[
                                                values?.type
                                              ].map((item, contIndex) => (
                                                <MenuItem
                                                  key={contIndex}
                                                  value={item?.value}
                                                >
                                                  <Checkbox
                                                    checked={
                                                      values?.overtime?.[
                                                        index
                                                      ]?.contractItem?.includes(
                                                        item?.value
                                                      ) || false
                                                    }
                                                  />
                                                  <Tooltip
                                                    title={item?.label}
                                                    followCursor
                                                  >
                                                    <ListItemText
                                                      primaryTypographyProps={{
                                                        component: "span",
                                                        style: {
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                          display:
                                                            "inline-block",
                                                          width: "100%",
                                                        },
                                                      }}
                                                      primary={item?.label}
                                                    />
                                                  </Tooltip>
                                                </MenuItem>
                                              ))}
                                            </Field>
                                            {values?.overtime?.[index]
                                              ?.contract &&
                                              errors?.overtime?.[index]
                                                ?.contractItem &&
                                              touched?.overtime?.[index]
                                                ?.contractItem && (
                                                <ErrorMessage
                                                  name={`overtime[${index}].contractItem`}
                                                  component="div"
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                />
                                              )}
                                          </FormControl>
                                        </Grid>
                                        <Grid sx={{ width: "50%" }}>
                                          <Field
                                            type="text"
                                            name={`overtime[${index}].reason`}
                                            as={TextField}
                                            disabled={
                                              !values?.overtime?.[index]
                                                ?.contract
                                            }
                                            error={
                                              touched?.overtime?.[index]
                                                ?.reason &&
                                              Boolean(
                                                errors?.overtime?.[index]
                                                  ?.reason
                                              )
                                            }
                                            helperText={
                                              touched?.overtime?.[index]
                                                ?.reason &&
                                              errors?.overtime?.[index]?.reason
                                            }
                                            fullWidth
                                            label="Səbəb"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        sx={{
                                          gap: "10px",
                                          width: "100%",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <FieldArray
                                          name={`overtime[${index}].footer`}
                                        >
                                          {({ push, remove }) => (
                                            <>
                                              {values.overtime?.[
                                                index
                                              ]?.footer.map((foot, ind) => (
                                                <Grid
                                                  container
                                                  spacing={2}
                                                  key={ind}
                                                >
                                                  <Grid
                                                    item
                                                    xs={4}
                                                    onClick={() =>
                                                      setIsDate(true)
                                                    }
                                                  >
                                                    <Field
                                                      name={`overtime[${index}].footer[${ind}].date`}
                                                    >
                                                      {({ field, form }) => {
                                                        const error =
                                                          form.errors
                                                            .overtime?.[index]
                                                            ?.footer?.[ind]
                                                            ?.date &&
                                                          form.errors
                                                            .overtime?.[index]
                                                            ?.footer?.[ind]
                                                            ?.date;

                                                        return (
                                                          <>
                                                            <DatePicker
                                                              {...field}
                                                              label="Tarix"
                                                              inputFormat="dd/MM/yyyy"
                                                              value={
                                                                field.value ||
                                                                null
                                                              }
                                                              disabled={
                                                                !values
                                                                  ?.overtime?.[
                                                                  index
                                                                ]?.contract
                                                              }
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                form.setFieldValue(
                                                                  field.name,
                                                                  value
                                                                )
                                                              }
                                                              minDate={
                                                                new Date()
                                                              }
                                                              maxDate={
                                                                new Date(
                                                                  2099,
                                                                  11,
                                                                  31
                                                                )
                                                              }
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                  fullWidth
                                                                  error={
                                                                    isDate &&
                                                                    !!error
                                                                  }
                                                                  helperText={
                                                                    isDate &&
                                                                    error
                                                                      ? error
                                                                      : ""
                                                                  }
                                                                />
                                                              )}
                                                            />
                                                          </>
                                                        );
                                                      }}
                                                    </Field>
                                                  </Grid>

                                                  <Grid item xs={4}>
                                                    <Field
                                                      name={`overtime[${index}].footer[${ind}].hour`}
                                                    >
                                                      {({ field, form }) => {
                                                        const error =
                                                          form.touched
                                                            .overtime &&
                                                          form.touched
                                                            .overtime?.[index]
                                                            ?.footer?.[ind]
                                                            ?.hour &&
                                                          form.errors
                                                            .overtime &&
                                                          form.errors
                                                            .overtime?.[index]
                                                            ?.footer?.[ind]
                                                            ?.hour;

                                                        return (
                                                          <TextField
                                                            {...field}
                                                            fullWidth
                                                            disabled={
                                                              !values
                                                                ?.overtime?.[
                                                                index
                                                              ]?.contract
                                                            }
                                                            label="Saat"
                                                            error={!!error}
                                                            helperText={
                                                              error ? error : ""
                                                            }
                                                          />
                                                        );
                                                      }}
                                                    </Field>
                                                  </Grid>

                                                  <Grid item xs={4}>
                                                    <FormControl
                                                      component="fieldset"
                                                      variant="standard"
                                                    >
                                                      <RadioGroup
                                                        name={`overtime[${index}].footer[${ind}].paymentType`}
                                                        row
                                                        defaultValue="2x"
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            `overtime[${index}].footer[${ind}].paymentType`,
                                                            e?.target?.value
                                                          );
                                                        }}
                                                        value={
                                                          values?.overtime?.[
                                                            index
                                                          ]?.footer?.[ind]
                                                            ?.paymentType
                                                        }
                                                      >
                                                        <FormControlLabel
                                                          value="2x"
                                                          control={
                                                            <Radio color="primary" />
                                                          }
                                                          label="2X (2 qat)"
                                                        />
                                                        {values?.type !==
                                                          "overtime" && (
                                                          <FormControlLabel
                                                            value="replacementDay"
                                                            control={
                                                              <Radio color="primary" />
                                                            }
                                                            label="Əvəzgün"
                                                          />
                                                        )}
                                                      </RadioGroup>
                                                    </FormControl>
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                      display: "flex",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    {ind ===
                                                      values?.overtime?.[index]
                                                        ?.footer.length -
                                                        1 &&
                                                      values?.overtime?.[index]
                                                        ?.footer.length <
                                                        30 && (
                                                        <Button
                                                          type="button"
                                                          variant="outlined"
                                                          onClick={() => {
                                                            push({
                                                              date: "",
                                                              hour: "",
                                                              paymentType: "2x",
                                                            });
                                                            setIsDate(false);
                                                          }}
                                                        >
                                                          + Əlavə et
                                                        </Button>
                                                      )}

                                                    {values?.overtime?.[index]
                                                      ?.footer.length === 1
                                                      ? ind > 0
                                                      : ind >= 0 && (
                                                          <Button
                                                            type="button"
                                                            color="error"
                                                            variant="outlined"
                                                            onClick={() => {
                                                              remove(ind);
                                                              setIsDate(false);
                                                            }}
                                                          >
                                                            - Sil
                                                          </Button>
                                                        )}
                                                  </Grid>
                                                </Grid>
                                              ))}
                                            </>
                                          )}
                                        </FieldArray>
                                      </Grid>

                                      <Grid>
                                        <Button
                                          color="error"
                                          variant="outlined"
                                          onClick={() => {
                                            remove(index);
                                            setIsDate(false);
                                          }}
                                        >
                                          Əməkdaş Sil
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          ))}

                        <Grid
                          sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {values?.type && values?.overtime?.length < 50 && (
                            <Button
                              variant="contained"
                              onClick={() => {
                                push({
                                  employee: "",
                                  contract: "",
                                  contractDate: "",
                                  structure: "",
                                  subStructure: "",
                                  position: "",
                                  reason: "",
                                  type: "",
                                  contractItem: "",
                                  date: "",
                                  hour: "",
                                  paymentType: "2x",
                                  footer: [
                                    {
                                      date: "",
                                      hour: "",
                                      paymentType: "2x",
                                    },
                                  ],
                                });
                                setIsDate(false);
                              }}
                            >
                              Əməkdaş əlavə et <AddIcon />
                            </Button>
                          )}
                          <Button variant="contained">Yadda saxla</Button>
                        </Grid>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              </>
            )}
          </Formik>
        )}
        <Table
          data={overtimeMockData}
          columns={overtimeColumns}
          offset={offset}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          setOffset={(value) => setOffset(value)}
        />
      </Box>
    </React.Fragment>
  );
};

export default OverTime;
