/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { downloadFile } from "../../utils/downloadCSV";
import Header from "../../components/layouts/header/Header";
import StatusGenerator from "../../components/table/StatusGenerator";
import Table from "../../components/table/Table";
import { DISSMISAL_FOR_FILTER, OPERATORS_CONTAINING } from "../../constants";
import Column from "../../models/table/Column";
import { getCommand } from "../../services/employees/EmployeeServices";
import {
  approveRejectStatus,
  download,
  downloadRemovalApps,
  getRemovals,
} from "../../services/leaves/LeaveServices";
import { getDescendantProp, normalizeDate } from "../../utils/helperFunctions";
import "./style.css";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import Operator from "../../models/table/Operator";
import { Close, RemoveRedEye } from "@mui/icons-material";
import CustomDialog from "./Dialog";

export default function Dismissal() {
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState([]);
  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState();

  const [is_sent_status, set_is_sent_status] = useState(false);
  const [status_description, set_status_description] = useState("");
  const [selected_row, set_selected_row] = useState(0);
  const [view_order_detail, set_view_order_detail] = useState(false);
  const [order_preview_detail, set_order_preview_detail] = useState({});

  const [show_version_dialog, set_show_version_dialog] = useState(false);
  const [version_data, set_version_data] = useState({});

  const [all_leaves, set_all_leaves] = useState({
    count: 0,
    records: [],
  });
  const [show_dialog, set_show_dialog] = useState(false);
  let default_ = {
    column: "docModel",
    operator: "equalsTo",
    value: "ExclusionApplication",
  };

  useEffect(() => {
    let filterReady;
    if (filter.length > 0) {
      let filtered = filter?.map((f) => {
        return {
          column: f?.column,
          operator: f?.operator,
          value: f?.value,
        };
      });
      filtered.push(default_);
      filterReady = encodeURIComponent(JSON.stringify(filtered));
    } else {
      filterReady = encodeURIComponent(JSON.stringify(default_));
    }
    if (filter.length > 0) {
      async function getData() {
        try {
          setIsLoading(true);

          let response = await getRemovals({
            offset: offset || 0,
            limit: limit || 5,
            filter: filterReady,
          });
          let records = response?.records?.map((r) => ({
            ...r,
            status: r?.status,
            createdOrder: r?.originalApplication?.order,
            updated: r?.updated,
            activity: r?.activity?.status,
            createdBy: r?.createdBy,
            clause: r?.originalApplication?.clause,
            endDate: r?.originalApplication?.endDate,
            startDate: r?.originalApplication?.startDate,
            reason: r?.originalApplication?.reason,
            id: r?._id,
            firstname: r?.employee?.firstname,
            lastname: r?.employee?.lastname,
            fathername: r?.employee?.fathername,
          }));
          set_all_leaves({ ...all_leaves, records, count: response?.count });
          setTotal(response.total);
        } catch (error) {
          console.log(error, "err");
        } finally {
          setIsLoading(false);
        }
      }
      getData();
    } else {
      get_removals();
    }
  }, [filter, offset, limit]);

  const downloadTableCSVHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filter.length > 0 && filter[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filter));
      const resp = await downloadRemovalApps({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });
      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const get_removals = async () => {
    try {
      setIsLoading(true);

      let response = await getRemovals({
        offset: offset || 0,
        limit: limit || 5,
        filter: encodeURIComponent(JSON.stringify([default_])),
      });
      let records = response?.records?.map((r) => ({
        ...r,
        status: r?.status,
        createdOrder: r?.originalApplication?.createdOrder?.order,
        updated: r?.updated,
        activity: r?.activity,
        createdBy: r?.createdBy,
        oldVersions: r?.oldVersions,
        clause: r?.originalApplication?.clause,
        endDate: r?.originalApplication?.endDate,
        startDate: r?.originalApplication?.startDate,
        reason: r?.originalApplication?.reason,
        id: r?._id,
        firstname: r?.employee?.firstname,
        lastname: r?.employee?.lastname,
        fathername: r?.employee?.fathername,
      }));
      set_all_leaves({ ...all_leaves, records, count: response?.count });
      setTotal(response.total);
    } catch (error) {
      console.log(error, "err");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_removals();
  }, []);

  const handle_view = (id) => {
    set_selected_row(id);
    set_show_dialog(true);
  };

  const handle_version_dialog = (version_data) => {
    set_show_version_dialog(true);
    set_version_data(version_data);
  };

  const handle_preview_order_detail = async (id) => {
    try {
      let response = await getCommand(id);
      if (response) {
        set_order_preview_detail(response);
        set_view_order_detail(true);
      }
    } catch (error) {
      console.log(error, "err");
    }
  };

  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      unhidableColumn: true,
      disableColSearch: true,
      widthOfColumn: 200,
      renderCell: (row, col) => {
        let send_dis = false;
        let upload_dis = false;
        let status = row?.status;

        if (status === "rejected") {
          send_dis = false;
          upload_dis = false;
        } else {
          switch (status) {
            case "explanationSentByEmployee":
              send_dis = true;
              upload_dis = true;
              break;
            case "requestedToEmployee":
              send_dis = true;
              upload_dis = true;
              break;
            case "explanationSentByManager":
              send_dis = true;
              upload_dis = false;
              break;
            case "rejectedOrderApprove":
              send_dis = true;
              upload_dis = true;
              break;
            default:
              break;
          }
        }

        let operators =
          status === "pendingOrder"
            ? [
                new Operator({
                  icon: (
                    <Tooltip title="Bax">
                      <RemoveRedEye />
                    </Tooltip>
                  ),
                  onClick: () => {
                    handle_view(row._id);
                  },
                }),
              ]
            : status === "pendingOrderApprove" || status === "active"
            ? [
                new Operator({
                  icon: (
                    <Tooltip title="Bax">
                      <RemoveRedEye />
                    </Tooltip>
                  ),
                  onClick: () => {
                    handle_view(row._id);
                  },
                }),
                new Operator({
                  icon: (
                    <Tooltip title="Əmrə bax">
                      <Button
                        disabled={send_dis}
                        style={{
                          border: 0,
                          background: "unset",
                          color: send_dis ? "#ffffff29" : "#407ad6",
                        }}
                      >
                        Əmrə bax
                      </Button>
                    </Tooltip>
                  ),
                  onClick: () =>
                    handle_preview_order_detail(row?.createdOrder?._id),
                }),
              ]
            : [
                new Operator({
                  icon: (
                    <Tooltip title="Bax">
                      <RemoveRedEye />
                    </Tooltip>
                  ),
                  onClick: () => {
                    handle_view(row._id);
                  },
                }),
              ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "originalApplication.startDate",
      headerName: "Başlama tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalApplication.endDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalApplication.clause",
      headerName: "ƏM Əsası",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          <div>
            <Tooltip title={row?.originalApplication?.clause} followCursor>
              <p className="specialText">{row?.originalApplication?.clause}</p>
            </Tooltip>
          </div>
        );
      },
    }),
    new Column({
      path: "originalApplication.reason",
      headerName: "Səbəbi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "status",
      headerName: "Cari status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: DISSMISAL_FOR_FILTER,

      renderCell: (row, column) => {
        return (
          <StatusGenerator
            value={getDescendantProp(row, column.path)}
            docType={"dissmisal"}
          />
        );
      },
    }),
  ];

  const headerData = {
    title: "İşdən Kənarlaşdırmalar",
    variant: "h3",
  };

  let selected_row_ = all_leaves?.records?.find((r) => r?.id === selected_row);

  let view_order_button = [
    {
      text: "Bağla",
      onClick: () => set_view_order_detail(false),
    },
  ];
  const handleApproveStatus = async (id, status) => {
    try {
      let response = await approveRejectStatus(id, status);
      if (response) {
        enqueueSnackbar("Təsdiq göndərildi", { variant: "success" });
        set_show_dialog(false);
        set_show_version_dialog(false);
        set_is_sent_status(false);
        get_removals();
      }
    } catch (error) {
      console.log(error, "err");
    }
  };
  const handleRejectStatus = async (id, status) => {
    try {
      let response = await approveRejectStatus(id, status, status_description);
      if (response) {
        enqueueSnackbar("İmtina göndərildi", { variant: "success" });
        set_show_dialog(false);
        set_show_version_dialog(false);
        set_is_sent_status(false);
        get_removals();
      }
    } catch (error) {
      console.log(error, "err");
    }
  };
  const download_file = async (id, mimeType, nameUsingDocNumber) => {
    try {
      let response = await download(id);
      if (response) {
        downloadFile(response, nameUsingDocNumber, mimeType);
        set_show_version_dialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      console.log(error, "err");
    }
  };

  return (
    <React.Fragment>
      <Box>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
        </Grid>

        <div className="mt-4">
          <Table
            columns={columns}
            data={all_leaves.records || []}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={isLoading}
            applyFiltersHandler={(filter) => setFilter(filter)}
            cancelAppliedFilters={() => setFilter([])}
            downloadHandler={(cols) => downloadTableCSVHandler(cols)}
          />
        </div>
        <Dialog
          open={show_dialog}
          onClose={() => set_show_dialog(false)}
          aria-labelledby="alert-dialog-title"
          className="root-d-2"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Hamısına bax</DialogTitle>
          <DialogContent>
            <div className="d-flex flex-column">
              <Button
                variant="contained"
                onClick={() =>
                  handle_version_dialog({
                    application: {
                      firstname: selected_row_?.firstname,
                      lastname: selected_row_?.lastname,
                      fathername: selected_row_?.fathername,
                      reason: selected_row_?.reason,
                      clause: selected_row_?.clause,
                      startDate: selected_row_?.startDate,
                      endDate: selected_row_?.endDate,
                      id: selected_row_?.id,
                    },
                    activity: selected_row_?.activity,
                    status: selected_row_?.status,
                    version: "Son",
                  })
                }
              >
                Son versiya
              </Button>
              {selected_row_?.oldVersions?.length > 0 &&
                selected_row_?.oldVersions?.map((old, i) => (
                  <Button
                    className="mt-2"
                    variant="contained"
                    onClick={() =>
                      handle_version_dialog({
                        application: {
                          firstname: selected_row_?.firstname,
                          lastname: selected_row_?.lastname,
                          fathername: selected_row_?.fathername,
                          reason: old?.originalApplication?.reason,
                          clause: old?.originalApplication?.clause,
                          startDate: old?.originalApplication?.startDate,
                          endDate: old?.originalApplication?.endDate,
                          id: selected_row_?.id,
                        },
                        activity: selected_row_?.activity,
                        status: selected_row_?.status,
                        version: "Köhnə",
                      })
                    }
                  >
                    Köhnə versiya - {1}
                  </Button>
                ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => set_show_dialog(false)}>Bağla</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={show_version_dialog}
          onClose={() => set_show_version_dialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="root-d"
        >
          <DialogTitle id="alert-dialog-title" className="text-center">
            {version_data?.version} versiya
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div>Ad:</div>
                <div>{version_data?.application?.firstname}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Soyad:</div>
                <div>{version_data?.application?.lastname}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Ata Adı:</div>
                <div>{version_data?.application?.fathername}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Səbəb:</div>
                <div>{version_data?.application?.reason}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Başlama tarixi:</div>
                <div>
                  {moment(version_data?.application?.startDate).format(
                    "MM-DD-YYYY, h:mm:ss"
                  )}
                </div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Bitmə tarixi:</div>
                <div>
                  {moment(version_data?.application?.endDate).format(
                    "MM-DD-YYYY, h:mm:ss"
                  )}
                </div>
              </div>

              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>ƏM Maddələri:</div>
                <div className="w-75">
                  {version_data?.application?.clause?.length &&
                    version_data?.application?.clause?.map((c) => (
                      <>
                        <div>{c}</div>
                        <hr />
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="d-flex">
              {version_data?.activity?.explanationAsFile && (
                <Button
                  style={{
                    width: !is_sent_status ? "49%" : "80%",
                    margin: "0 auto",
                  }}
                  variant="contained"
                  onClick={() =>
                    download_file(
                      version_data?.application?.id,
                      version_data?.activity?.explanationAsFile?.mimeType,
                      version_data?.activity?.explanationAsFile.docNumber
                    )
                  }
                >
                  İzahatı yüklə
                </Button>
              )}
              {version_data?.version === "Son" &&
                version_data?.status === "explanationSentByEmployee" && (
                  <Button
                    style={{
                      width: !is_sent_status ? "49%" : "",
                      margin: "0 auto",
                    }}
                    variant="contained"
                    onClick={() => set_is_sent_status(!is_sent_status)}
                  >
                    {!is_sent_status ? "Cavabla" : <Close />}
                  </Button>
                )}
            </div>
            <div>
              {is_sent_status && (
                <div className="p-3">
                  Açıqlama:
                  <TextField
                    fullWidth
                    multiline
                    value={status_description}
                    onChange={(e) => set_status_description(e.target.value)}
                    className="mt-2"
                  />
                  <DialogActions>
                    <Button
                      disabled={!status_description?.length}
                      variant="contained"
                      onClick={() =>
                        handleApproveStatus(
                          version_data?.application?.id,
                          "approve"
                        )
                      }
                    >
                      Təsdiqlə
                    </Button>
                    <Button
                      disabled={!status_description?.length}
                      style={{
                        background: status_description?.length ? "red" : "",
                      }}
                      variant="contained"
                      onClick={() =>
                        handleRejectStatus(
                          version_data?.application?.id,
                          "reject"
                        )
                      }
                    >
                      Rədd et
                    </Button>
                  </DialogActions>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => set_show_version_dialog(false)}>
              Bağla
            </Button>
          </DialogActions>
        </Dialog>
        {Object.keys(order_preview_detail).length !== 0 && (
          <CustomDialog
            data={order_preview_detail?.data}
            view_dialog={{
              set: set_view_order_detail,
              state: view_order_detail,
            }}
            buttons={view_order_button}
            title="Əmrə Bax"
            type="preview-order"
          />
        )}
      </Box>
    </React.Fragment>
  );
}
