import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveStaffs, getWorkYears } from "../services/staff/StaffServices";
import moment from "moment";
import { removeEmptyStrings } from "../utils/removeEmptyString";
import {
  createVacationRequest,
  getEmployeeVacationDays,
} from "../api/vacationRequests/vacationRequests";
import {
  createStaticDocument,
  downloadContract,
} from "../api/contractRequests/contractRequests";
import { getFitlersData } from "../services/filter/filterService";
import dayjs from "dayjs";
import * as Yup from "yup";
import { reasonArr } from "../constants";
const INPUT_DATE = "dd/MM/yyyy";
const ISO_DATE = "DD/MM/YYYY";
const TODAY = new Date();

export const useCreateVacation = () => {
  const formikRef = useRef();
  const navigate = useNavigate();
  const [allVacationReports, setAllVacationReports] = useState({
    staffId: "",
    vacationTrackingId: "",
    sourceOfVacationId: "",
    startDate: "",
    totalVacationDays: "",
    endDate: "",
    jobStartDate: "",
    vacationType: "",
    sourceOfVacation: [],
    remainingWorkYears: [],
    positionName: "",
    reason: "",
  });
  const [contractId, setContractId] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [progress, setProgress] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const [activeEmps, setActiveEmps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [disabledRanges, setDisabledRanges] = useState([]);
  const [disabledReasonMessage, setDisabledReasonMessage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const { currentEmployee } = useSelector((state) => state?.commonThings);
  const [selectedStructureByStaff, setSelectedStructureByStaff] =
    useState(null);

  useEffect(() => {
    isEdited && setProgress(0);
    isEdited && setResponseStatus();
  }, [isEdited]);

  useEffect(() => {
    // getActiveEmployees();
    getInitialValues();
  }, []);

  const getActiveEmployees = async (selectedStructureByStaff) => {
    let limit = 999,
      offset = 0;
    try {
      const { data } = await getActiveStaffs(
        limit,
        offset,
        selectedStructureByStaff
      );
      data && setActiveEmps(data?.records);
    } catch (error) {
      console.log("err", error);
    }
  };
  const handleFileChange = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setSelectedFile(base64String);
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    selectedStructureByStaff && getActiveEmployees(selectedStructureByStaff);
  }, [selectedStructureByStaff]);

  const getInitialValues = async () => {
    try {
      const { data } = await getWorkYears();
      data &&
        data.map((item, index) => {
          if (item?.vacationReports?.length) {
            setAllVacationReports((prevState) => ({
              ...prevState,
              sourceOfVacation: item?.vacationReports,
              vacationTrackingId: item?._id,
              staffId: item?.staff?._id,
              positionName: item?.position?.source?.name,
            }));
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const contractName = "vacation-application";

  // Create vacation request
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { ...restValues } = values;

    const modifiedValues = {
      ...restValues,
      endDate: allVacationReports?.endDate
        ? moment(allVacationReports?.endDate).format("MM/DD/YYYY")
        : "",
      startDate: allVacationReports?.startDate
        ? moment(allVacationReports?.startDate).format("MM/DD/YYYY")
        : "",
      jobStartDate: allVacationReports?.jobStartDate
        ? moment(allVacationReports?.jobStartDate).format("MM/DD/YYYY")
        : "",
      replacementEmployeeId: restValues?.replacementStaffId
        ? restValues?.replacementStaffId
        : "",
      file: selectedFile ? selectedFile : "",
      reason: allVacationReports?.reason ? allVacationReports?.reason : "",
      contractId: contractId,
    };
    const result = removeEmptyStrings(modifiedValues);
    const response = await createVacationRequest(result);
    setSubmitting(false);
    if (response && response.data) {
      setTimeout(() => {
        navigate("/emp-vacation-requests");
      }, 2000);
    }
  };
  function downloadWordFile(res, fileName) {
    // Create a Blob from the response data
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append the link to the body, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the Blob URL to free up memory
    window.URL.revokeObjectURL(url);
  }
  // Create vacation contract
  const handleVacationContract = async (values) => {
    const isValid = await handleManualValidation();

    if (!isValid) return;

    setProgress(0);

    const vacationContract = {
      docModel: "Employee",
      subjects: [currentEmployee?._id],
      data: {
        staffId: allVacationReports?.staffId,
        startDate: moment(allVacationReports?.startDate).format("MM/DD/YYYY"),
        endDate: moment(allVacationReports?.endDate).format("MM/DD/YYYY"),
        jobStartDate: moment(allVacationReports?.jobStartDate).format(
          "MM/DD/YYYY"
        ),
        vacationType: allVacationReports?.vacationType,
        reason: allVacationReports?.reason,
      },
    };
    let result = removeEmptyStrings(vacationContract);
    try {
      setIsLoading(true);
      const response = await createStaticDocument(
        `${allVacationReports?.vacationType}-${contractName}`,
        result
      );
      if (response) {
        setResponseStatus(response?.status);
        setContractId(response?.data?._id);
        if (response?.data?._id) {
          const res = await downloadContract(response?.data?._id);
          if (res) {
            downloadWordFile(res, "contract.docx");
          }
        }
      }
      if (response?.status === 201) {
        const timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              clearInterval(timer);
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 100);
      }
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
    setIsEdited(false);
  };

  const handleManualValidation = async () => {
    try {
      if (formikRef?.current) {
        const errors = await formikRef?.current.validateForm();
        const isValid = Object.keys(errors).length === 0;
        return isValid;
      }
      return false;
    } catch (error) {
      console.log("err", error);
    }
  };

  const parseDate = (dateStr) => moment(dateStr, ISO_DATE).toDate();

  const getVacationDays = async () => {
    const filters = {
      staffId: allVacationReports?.staffId,
      startDate: moment(allVacationReports?.startDate).format("MM/DD/YYYY"),
      endDate: moment(allVacationReports?.endDate).format("MM/DD/YYYY"),
      vacationType: allVacationReports?.vacationType,
      reason: allVacationReports?.reason,
    };

    let result = removeEmptyStrings(filters);
    if (
      allVacationReports?.staffId &&
      allVacationReports?.startDate &&
      allVacationReports?.endDate &&
      allVacationReports?.vacationType
    ) {
      try {
        const res = await getEmployeeVacationDays(
          encodeURIComponent(JSON.stringify(result))
        );

        if (res) {
          setAllVacationReports({
            ...allVacationReports,
            jobStartDate: res?.data?.data?.jobStartDate,
            totalVacationDays: res?.data?.data?.totalVacationDays,
            remainingWorkYears: res?.data?.data?.remainingWorkYears,
          });
          if (
            moment(res?.data?.data?.startDate).format("DD/MM/YYYY") !==
            moment(allVacationReports?.startDate).format("DD/MM/YYYY")
          ) {
            setAllVacationReports({
              ...allVacationReports,
              startDate: res?.data?.data?.startDate,
            });
          }
          if (
            moment(res?.data?.data?.endDate).format("DD/MM/YYYY") !==
            moment(allVacationReports?.endDate).format("DD/MM/YYYY")
          ) {
            setAllVacationReports({
              ...allVacationReports,
              endDate: res?.data?.data?.endDate,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getDateList = async () => {
    let es = [
      {
        employeeId: currentEmployee?._id,
        staffId: allVacationReports?.staffId,
      },
    ];
    if (currentEmployee?._id && allVacationReports?.staffId) {
      const dateList = await getFitlersData({
        es: es,
        dataGridName: "vacation",
      });
      setDateList(dateList?.data);
    }
  };
  const processDisabledRangesAndMessages = () => {
    setDisabledRanges([]);
    setDisabledReasonMessage([]);
    for (let index = 0; index < dateList?.length; index++) {
      const element = dateList?.[index];

      if (element?.unactiveDates?.length > 0) {
        for (const item of element?.unactiveDates) {
          for (const key in item) {
            const range = {};
            if (item?.[key]?.startDate) {
              range.start = dayjs(item?.[key]?.startDate).toISOString();
            }
            if (item?.[key]?.endDate) {
              range.end = dayjs(item?.[key]?.endDate).toISOString();
            }

            if (Object.keys(range).length > 0) {
              setDisabledRanges((prevRanges) => [...prevRanges, range]);
            }
            setDisabledReasonMessage((prevMessages) => [
              ...prevMessages,
              item?.[key]?.message,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    processDisabledRangesAndMessages();
  }, [dateList]);
  useEffect(() => {
    getDateList();
  }, [currentEmployee?._id, allVacationReports?.staffId]);

  const shouldDisableDate = (date) => {
    const targetDate = new Date(date);
    const targetUTCDate = Date.UTC(
      targetDate.getUTCFullYear(),
      targetDate.getUTCMonth(),
      targetDate.getUTCDate()
    );

    return disabledRanges.some((range) => {
      if (range.start && range.end) {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);

        startDate.setUTCDate(startDate.getUTCDate() - 1);
        endDate.setUTCDate(endDate.getUTCDate() - 1);

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );
        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate && targetUTCDate <= endUTCDate;
      }
      if (range.start) {
        const startDate = new Date(range.start);
        startDate.setUTCDate(startDate.getUTCDate() - 1);

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate;
      }
      if (range.end) {
        const endDate = new Date(range.end);
        endDate.setUTCDate(endDate.getUTCDate() - 1);

        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate <= endUTCDate;
      }

      return false;
    });
  };
  useEffect(() => {
    allVacationReports?.vacationType === "labor" ||
    allVacationReports?.vacationType === "partialPaidSocial"
      ? getVacationDays()
      : allVacationReports?.reason && getVacationDays();
  }, [
    allVacationReports?.staffId,
    allVacationReports?.startDate,
    allVacationReports?.reason,
    allVacationReports?.endDate,
  ]);

  return {
    TODAY,
    progress,
    ISO_DATE,
    formikRef,
    isLoading,
    parseDate,
    reasonArr,
    INPUT_DATE,
    activeEmps,
    setIsEdited,
    handleSubmit,
    responseStatus,
    currentEmployee,
    validationSchema,
    handleFileChange,
    shouldDisableDate,
    allVacationReports,
    disabledReasonMessage,
    setAllVacationReports,
    handleVacationContract,
    setSelectedStructureByStaff,
  };
};

export const validationSchema = Yup.object().shape({
  vacationType: Yup.string().required("Məzuniyyətin növü mütləqdir"),
  staffId: Yup.string().required("Müqavilə mütləqdir"),
  reason: Yup.string().when("vacationType", {
    is: (val) => !["labor", "partialPaidSocial"].includes(val),
    then: (schema) => schema.required("Məzuniyyətin səbəbi mütləqdir"),
    otherwise: (schema) => schema.nullable(),
  }),
  startDate: Yup.string()
    .required("Məzuniyyətin başlama tarixi mütləqdir")
    .test(
      "is-valid-date",
      "Başlama tarixi düzgün formatda olmalıdır",
      (value) => {
        return moment(value, ISO_DATE, true).isValid();
      }
    ),
  endDate: Yup.string()
    .required("Məzuniyyətin bitmə tarixi mütləqdir")
    .test(
      "is-valid-date",
      "Bitmə tarixi düzgün formatda olmalıdır",
      (value) => {
        return moment(value, ISO_DATE, true).isValid();
      }
    )
    .test(
      "is-end-date-after-start-date",
      "Məzuniyyətin bitmə tarixi, başlama tarixindən əvvəl ola bilməz",
      function (value) {
        const { startDate } = this.parent;
        const startDateObj = moment(startDate, ISO_DATE);
        const endDateObj = moment(value, ISO_DATE);

        return endDateObj.isAfter(startDateObj, "day");
      }
    ),
});
