import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { vacationTrackingRequest } from "../api/employeeRequests/employeeRequests";
import {
  getCommand_API_SERVICE,
  getEmployeeId_API_SERVICE,
  getFillingVacantPositionOrder_SERVICE,
  getTableEmployee_API_SEVICE,
  postFillingVacantPositionOrder_SERVICE,
  putVacantOrder_SERVICE,
} from "../services/ordersServices";
import { enqueueSnackbar } from "notistack";

function useCompensation() {
  const [value, setValue] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const value = searchParams.get("value");
    setValue(value);
  }, [searchParams]);
  const navigate = useNavigate();
  const [total, setTotal] = useState();
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [contractData, setContractData] = useState();
  const [commandData, setCommandData] = useState([]);
  const [contractName, setContractName] = useState("");
  const [currentContractData, setCurrentContract] = useState(null);
  const [employee, setEmployee] = useState({
    employeeId: "",
    tabelNumber: "",
    empStaffId: "",
  });

  const [compensationData, setCompensationData] = useState({
    note: "",
    staffId: "",
    currency: "AZN",
    residueDaysFromVacation: "",
    isCompensationForTermination: false,
    newContractDate: null,
  });

  // Əmr xana dolmamışsa button disabled üçün
  const isFormValid = (typeOfOrder) => {
    return (
      !employee?.employeeId ||
      !compensationData?.endDate ||
      !compensationData?.startDate ||
      !compensationData?.typeOfPayment ||
      !compensationData?.residueDaysFromVacation
      // (persent && !emptyPositionDatas?.currency)
    );
  };
  const createFillingVacantPositionOrder = async () => {
    try {
      const payload = {
        ...compensationData,
        // vacantStaffId: staffId,
        staffId: employee?.empStaffId,
      };

      //   if (emptyPositionDatas.additionToSalary) {
      //     payload.additionToSalary = Number(payload.additionToSalary);
      //   }

      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...payload,
        },
      };
      let response = await postFillingVacantPositionOrder_SERVICE(
        contractName,
        final
      );
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      //   validateForm();
    }
  };
  // Table əməkdaşları gətirmək üçün
  const getVacation = async (filters) => {
    if (!filters) {
      filters = [];
    }
    try {
      setLoading(true);
      const response = await vacationTrackingRequest(filters, limit, offset);
      if (response && response?.data) {
        const vacationTrackingData = response?.data?.records;
        // setTableData(vacationTrackingData);
        setTotal(response?.data?.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // Əməkdaşları gətirmək üçün
  const getFillingVacantPositionOrder = async () => {
    const filter = [
      { column: "status", operator: "equalsTo", value: "active" },
    ];

    try {
      const res = await getFillingVacantPositionOrder_SERVICE(
        encodeURIComponent(JSON.stringify(filter))
      );
      if (res) {
        setData(res.data.employees);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Əməkdaşın id-ə görə məlumatlarını götürmək üçün
  const handleEmployeeChange = (value) => {
    const emp = data?.find((e) => e?._id === value);
    setEmployee({
      ...employee,
      employeeId: value || "",
      tabelNumber: emp?.tableNumber || "",
    });
  };

  const getTableEmployee = async () => {
    try {
      const res = await getTableEmployee_API_SEVICE(employee.employeeId);
      setTableData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTableEmployee();
  }, [employee.employeeId]);
  // Edit zamani seçilən əməkdaşın datalarını gətirmək üçün
  const getCommandApi = async () => {
    try {
      if (value) {
        const res = await getCommand_API_SERVICE(value, contractName);
        setCommandData(res.data);
        setEmployee({
          ...employee,
          employeeId: res?.data?.subjects?.[0]?.subject?._id,
          tabelNumber: res?.data?.subjects?.[0]?.subject?.tableNumber,
          empStaffId: res?.data?.data?.staffId?.value,
        });
        setCompensationData({
          ...compensationData,
          residueDaysFromVacation: res?.data?.data?.compensationDays?.value,
          note: res?.data?.data?.note?.value,
          currency: res?.data?.data?.currency?.value,
        });
        if (commandData.length > 0) {
          setCurrentContract(
            contractData?.find((s) =>
              s?._id === commandData?.data?.staffId?.value?.value
                ? commandData?.data?.staffId?.value?.value
                : commandData?.data?.staffId?.value
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Əmr update etmək
  const updateVacantorder = async () => {
    try {
      const payload = {
        ...compensationData,
        // vacantStaffId: staffId,
        currency: compensationData?.currency,
        staffId: employee?.empStaffId,
      };
      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...payload,
        },
      };
      let response = await putVacantOrder_SERVICE(contractName, value, final);
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      // validateForm();
    }
  };

  // Müqavilə nömrələrini gətirmək üçün
  const getContract = async () => {
    try {
      const res = await getEmployeeId_API_SERVICE(employee.employeeId);
      if (res) {
        setContractData(res.data?.staffs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createFillingVacantPositionOrder();
    // if (validateForm()) {
    //   setIsLoading(true);
    //   setIsLoading(false);
    // }
  };

  const handleCurrentContract = (id) => {
    const value = contractData?.find((s) => s?._id === id);
    setCurrentContract(contractData?.find((s) => s?._id === id));
    setEmployee({
      ...employee,
      empStaffId: value?.contract?.data?.staffId,
    });
  };

  const handleInputChange = (name, value) => {
    setCompensationData({
      ...compensationData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (value) {
      getCommandApi();
    }
  }, [value]);
  useEffect(() => {
    getFillingVacantPositionOrder();
  }, []);

  useEffect(() => {
    employee?.employeeId && getContract();
  }, [employee?.employeeId]);
  useEffect(() => {
    if (commandData?.data?.staffId?.value && contractData?.length > 0) {
      const matchingContract = contractData?.find((s) =>
        s?._id === commandData?.data?.staffId?.value?.value
          ? commandData?.data?.staffId?.value?.value
          : commandData?.data?.staffId?.value
      );

      if (matchingContract) {
        setCurrentContract(matchingContract);
      }
    }
  }, [commandData, contractData]);
  useEffect(() => {
    getVacation(filters);
  }, [filters, offset, limit]);
  return {
    data,
    employee,
    tableData,
    commandData,
    contractData,
    handleSubmit,
    setContractName,
    compensationData,
    updateVacantorder,
    handleInputChange,
    currentContractData,
    handleEmployeeChange,
    handleCurrentContract,
    createFillingVacantPositionOrder,
  };
}

export default useCompensation;
