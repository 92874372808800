import { useEffect, useRef, useState } from "react";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import { GENERAL_BLOCK } from "../../../styled";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import { getDescendantProp } from "../../../utils/helperFunctions";
import {
  APP_EXP_DOCMODEL,
  OPERATORS_CONTAINING,
  STAFFS_STATUS_TYPE,
  STAFF_DROPDOWN_VALUE,
} from "../../../constants";
import ButtonCustom from "../../../components/ui/Button/ButtonCustom";
import { Close, RemoveRedEye } from "@mui/icons-material";
import {
  fetchExplanation_ApplicationRequests_SERVICE,
  sendExplanation_ApplicationRequest_SERVICE,
} from "../../../services/cabinetServices";
import moment from "moment";
import StatusGenerator from "../../../components/table/StatusGenerator";

export default function ApplicationsRequests({ sendExplanationData }) {
  const [appReq, setAppReq] = useState([]);
  const [totalAppReqRow, setTotalAppReqRow] = useState();
  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [contId, setContId] = useState("");
  const [showContDialog, setShowContDialog] = useState(false);
  const [viewDialogText, setViewDialogText] = useState({
    content: "",
    date: "",
  });

  let exp_desc = useRef();
  console.log(exp_desc);
  const handlePaginationChange = (pageSize, table) => {
    if (!pageSize) return;
    const value = pageSize.page * pageSize.pageSize;
    setOffset(value);
    setLimit(parseInt(pageSize.pageSize));
  };

  function contentConverter(data) {
    switch (data) {
      case "ExclusionApplication":
        return "Kənarlaşdırma";
      case "UnknownReasonApplication":
        return "Naməlum səbəb";
      default:
        return "";
    }
  }

  useEffect(() => {
    paginationHandler();
  }, [limit, offset, filters]);

  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        applicationRequestType: "application",
        filter: filters,
      };
      const { data } = await fetchExplanation_ApplicationRequests_SERVICE({
        ...payload,
      });
      setAppReq(data?.records);
      setTotalAppReqRow(data?.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
      return;
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const handleRowDialogBtnClick = async (row) => {
    setContId(row?._id);
    setViewDialogText({
      ...viewDialogText,
      content: row?.docModel,
      date: row?.createdAt,
    });
    setShowContDialog(true);
  };
  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      disableColSearch: true,
      renderCell: (row, column) => {
        return (
          <div
            style={{
              position: "relative",
              display: "grid",
              columnGap: "5px",
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
              height: "100%",
            }}
          >
            <ButtonCustom
              onClick={() => {
                handleRowDialogBtnClick(row);
              }}
              children={<RemoveRedEye />}
              style={{ height: "100%", background: "none" }}
              styleContainer={{ height: "auto" }}
            />
            <ButtonCustom
              onClick={() => {
                handleRowDialogBtnClick(row);
              }}
              children={<div>Ərizə yarat</div>}
              style={{ height: "100%", background: "none" }}
              styleContainer={{ height: "auto" }}
            />
          </div>
        );
      },
    }),
    new Column({
      path: "activity.requestNO",
      headerName: "Sorğu no",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "createdBy.firstname",
      headerName: "Kimdən ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "createdBy.lastname",
      headerName: "Kimdən soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "createdAt",
      headerName: "Tarix",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) => {
        return (
          <div className="text-center">
            {moment(row.createdAt)?.format("MM/DD/YYYY")}
          </div>
        );
      },
    }),
    new Column({
      path: "docModel",
      headerName: "Məzmun",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row, column) => {
        return (
          <div className="text-center">{APP_EXP_DOCMODEL[row.docModel]}</div>
        );
      },
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row, column) => {
        return (
          <StatusGenerator
            value={getDescendantProp(row, column.path)}
            docType={"dissmisal"}
          />
        );
      },
    }),
  ];

  async function sendApplicationData() {
    try {
      setIsLoading(true);
      const { data } = await sendExplanation_ApplicationRequest_SERVICE(
        contId,
        exp_desc.current
      );
      setShowContDialog(false);
      fetchData();
    } catch (error) {
      setShowContDialog(false);
      console.log(error);
    }
  }
  const closeHandler = () => {
    setShowContDialog(false);
  };

  return (
    <>
      <>
        <Table
          columns={columns}
          data={appReq}
          totalRows={totalAppReqRow}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
        />
        <Dialog
          open={showContDialog}
          onClose={() => setShowContDialog(false)}
          PaperProps={{
            style: {
              width: "900px",
              padding: "20px",
            },
          }}
        >
          <Grid>
            <Grid>
              <Grid className="text-right">
                <IconButton onClick={closeHandler}>
                  <Close />
                </IconButton>
              </Grid>
              <Grid>
                <Typography
                  paragraph
                  fontSize={30}
                  fontWeight={"500"}
                  textAlign={"center"}
                  sx={{ width: "100%" }}
                >
                  Ərizə yarat
                </Typography>
              </Grid>
              <Grid>
                <Grid>Məzmun: {APP_EXP_DOCMODEL[viewDialogText?.content]}</Grid>
                <Grid>
                  Yaradılma tarixi :{" "}
                  {moment(viewDialogText?.date)?.format("MM/DD/YYYY")}
                </Grid>
                <Grid className="mt-4">
                  <TextField
                    label="İzahat"
                    multiline
                    fullWidth
                    onChange={(e) => {
                      exp_desc.current = e.target.value;
                    }}
                  />
                </Grid>
                <Grid className="text-right mt-4">
                  <Button variant="contained" color="warning">
                    Sənədə bax
                  </Button>
                  <Button
                    variant="contained"
                    className="ms-2"
                    onClick={sendApplicationData}
                  >
                    Göndər
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </>
    </>
  );
}
