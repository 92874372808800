import { api } from "./axiosInterceptor";

// ⇩ ⇩ ⇩  Cabinet Change Requestd; ⇩ ⇩ ⇩
export const getChangeRequests = async (filter, offset, limit) => {
  const url = filter
    ? `employee/cabinet/change-request?offset=${offset}&limit=${limit}&filter=${filter}`
    : `employee/cabinet/change-request?offset=${offset}&limit=${limit}`;

  return await api.get(url);
};

export const getChangeRequest = async (id) => {
  const url = `employee/cabinet/change-request/${id}`;
  return await api.get(url);
};

export const getExplanation_Application_API = async (payload) => {
  return await api.get("/employee/employee-application/pending", {
    params: { ...payload },
  });
};

export const getExplanation_ApplicationRequest_API = async (applicationId) => {
  return await api.get(`/employee/employee-application/${applicationId}`);
};

export const postExplanation_Application_API = async (
  applicationId,
  explanation
) => {
  return await api.put(
    `/employee/employee-application/${applicationId}/sendExplanation`,
    { explanation }
  );
};
