// React
import { useEffect, useState } from "react";

// Material UI
import { Grid } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";

import {
  CHANGE_REQUESTS_FOR_FILTER,
  OPERATORS_CONTAINING,
} from "../../../constants";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import {
  fetchChangeRequest,
  fetchChangeRequests,
} from "../../../services/cabinetServices";
import { handleApiError } from "../../../api/changeRequests";
import moment from "moment";
import ButtonCustom from "../../../components/ui/Button/ButtonCustom";
import StatusGenerator from "../../../components/table/StatusGenerator";
import { getDescendantProp } from "../../../utils/helperFunctions";

export default function MCRequestsTable() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalRow, setTotalRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filters, setFilters] = useState([]);
  const [contId, setContId] = useState("");
  const [showContDialog, setShowContDialog] = useState(false);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    handleClosePopover();
  };

  useEffect(() => {
    paginationFetchingHandler();
  }, [filters, limit, offset]);

  const paginationFetchingHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const { data } = await fetchChangeRequests(filters, offset, limit);
      console.log(data);
      setTotal(data.total);
      setData([...data?.records]);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };
  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const handleRowDialogBtnClick = async (id) => {
    let { data } = await fetchChangeRequest(id);
    console.log(data);
    setContId(id);
    setShowContDialog(true);
  };

  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      disableColSearch: true,
      renderCell: (row, column) => {
        return (
          <div
            style={{
              position: "relative",
              display: "grid",
              columnGap: "5px",
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
              height: "100%",
            }}
          >
            <ButtonCustom
              onClick={() => {
                handleRowDialogBtnClick(row._id);
              }}
              children={<RemoveRedEye />}
              style={{ height: "100%", background: "none" }}
              styleContainer={{ height: "auto" }}
            />
          </div>
        );
      },
    }),
    new Column({
      path: "requestNO",
      headerName: "Sorğu nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return <div className="text-center">{row?.creator?.firstname}</div>;
      },
    }),
    new Column({
      path: "employee",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return <div className="text-center">{row?.creator?.lastname}</div>;
      },
    }),
    new Column({
      path: "employee",
      headerName: "Ata Adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return <div className="text-center">{row?.creator?.fathername}</div>;
      },
    }),
    new Column({
      path: "section",
      headerName: "Təsnifat",
    }),
    new Column({
      path: "createdAt",
      headerName: "Yaranma tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row) => {
        return (
          <div className="text-center">
            {moment(row?.createdAt)?.format("MM/DD/YY")}
          </div>
        );
      },
    }),
    new Column({
      path: "description",
      headerName: "İzah",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          <div className="text-center">{row?.approvers?.[0]?.description}</div>
        );
      },
    }),
    new Column({
      path: "status",
      headerName: "Cari status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: CHANGE_REQUESTS_FOR_FILTER,
      renderCell: (row, column) => {
        const isVerified = row?.baseStructures?.[0]?.isVerified;
        const approvers = [];
        row.staffs &&
          row.staffs.map((item, index) => {
            let status;
            if (item.status === "contract_in_draft") {
              if (isVerified) {
                status = "pendingSignature";
              } else {
                status = "pendingApprove";
              }
            } else {
              status = item.status;
            }

            approvers.push({
              index: index + 1,
              employee: row.data.employeeFullName.value,
              status: status,
            });
          });
        return (
          <StatusGenerator
            isVerified={isVerified}
            data={row}
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"staffStatus"}
          />
        );
      },
    }),
  ];

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        <Table
          columns={columns}
          data={data}
          totalRows={2}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
