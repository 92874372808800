import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import { parse } from "date-fns";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { useCreateVacation } from "../../../hooks/useCreateVacation";

const CreateVacationForm = ({ reasonArray }) => {
  const {
    TODAY,
    progress,
    ISO_DATE,
    formikRef,
    isLoading,
    reasonArr,
    parseDate,
    INPUT_DATE,
    activeEmps,
    setIsEdited,
    handleSubmit,
    responseStatus,
    currentEmployee,
    handleFileChange,
    validationSchema,
    shouldDisableDate,
    allVacationReports,
    disabledReasonMessage,
    setAllVacationReports,
    handleVacationContract,
    setSelectedStructureByStaff,
  } = useCreateVacation();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        sourceOfVacation: "",
        vacationType: "",
        replacementStaffId: "",
        startDate: "",
        endDate: "",
        staffId: "",
        reason: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        isValid,
        touched,
        isSubmitting,
        setFieldValue,
        values,
        handleChange,
        dirty,
      }) => (
        <Form>
          {/* EmployeeId and StartDate */}
          <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
            <Grid sx={{ width: "50%" }}>
              <Field
                type="text"
                name="employeeId"
                as={TextField}
                fullWidth
                disabled
                onChange={(e) => {
                  handleChange(e);
                  setIsEdited(true);
                }}
                label={
                  currentEmployee?.firstname + " " + currentEmployee?.lastname
                }
                error={Boolean(errors?.firstname)}
                helperText={errors?.firstname && errors?.firstname}
              />
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Field name="startDate">
                {({ field, form }) => {
                  return (
                    <DatePicker
                      {...field}
                      label="Məzuniyyətin başlama tarixi"
                      inputFormat={INPUT_DATE}
                      disabled={
                        values?.vacationType === "labor" ||
                        values?.vacationType === "partialPaidSocial"
                          ? !values?.staffId
                          : !values?.staffId || !values?.reason
                      }
                      value={
                        field.value instanceof Date
                          ? field?.value
                          : parseDate(field?.value)
                      }
                      shouldDisableDate={shouldDisableDate}
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          const formattedDate = moment(date).format(ISO_DATE);
                          form.setFieldValue("startDate", formattedDate);
                          setAllVacationReports((prevState) => ({
                            ...prevState,
                            startDate: date,
                          }));
                        } else {
                          form.setFieldValue("startDate", "");
                        }
                      }}
                      // minDate={TODAY}
                      // maxDate={allVacationReports?.endDate}
                      // disabled={
                      //   values?.vacationType === "" ||
                      //   (values?.vacationType === "labor" &&
                      //     values?.sourceOfVacation === "")
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            values?.vacationType === "labor" ||
                            values?.vacationType === "partialPaidSocial"
                              ? values?.staffId && form?.errors?.startDate
                              : values?.staffId &&
                                values?.reason &&
                                form?.errors?.startDate
                          }
                          error={
                            values?.vacationType === "labor" ||
                            values?.vacationType === "partialPaidSocial"
                              ? values?.staffId &&
                                form?.errors?.startDate &&
                                Boolean(form?.errors?.startDate)
                              : values?.staffId &&
                                values?.reason &&
                                form?.errors?.startDate &&
                                Boolean(form?.errors?.startDate)
                          }
                          fullWidth
                        />
                      )}
                    />
                  );
                }}
              </Field>
            </Grid>
          </Grid>

          {/* VacationType and EndDate */}
          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
            <Grid sx={{ width: "50%" }}>
              <FormControl error={Boolean(errors?.vacationType)} fullWidth>
                <InputLabel id="vacationType">Məzuniyyətin növü</InputLabel>
                <Field
                  as={Select}
                  name="vacationType"
                  labelId="vacationType"
                  label="Məzuniyyətin növü"
                  id="vacationType-select"
                  onChange={(e) => {
                    const selectedItem = e?.target?.value;
                    setAllVacationReports((prevState) => ({
                      ...prevState,
                      vacationType: selectedItem,
                      reason: "",
                    }));
                    setFieldValue("reason", "");
                    // Mark the form as edited
                    setIsEdited(true);

                    // Lastly, handle Formik's internal change
                    handleChange(e);
                  }}
                  fullWidth
                  error={Boolean(errors?.vacationType)}
                >
                  {reasonArray.map((item, index) => {
                    if (
                      currentEmployee?.gender === "male" &&
                      item?.value === "maternity"
                    )
                      return;
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Field>
                {errors?.vacationType && errors?.vacationType && (
                  <FormHelperText error>{errors?.vacationType}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Field name="endDate">
                {({ field, form }) => {
                  return (
                    <DatePicker
                      {...field}
                      label="Məzuniyyətin bitmə tarixi"
                      inputFormat={INPUT_DATE}
                      disabled={
                        values?.vacationType === "labor" ||
                        values?.vacationType === "partialPaidSocial"
                          ? !values?.staffId
                          : !values?.staffId || !values?.reason
                      }
                      value={
                        field?.value instanceof Date
                          ? field?.value
                          : parse(field?.value, INPUT_DATE, TODAY)
                      }
                      shouldDisableDate={shouldDisableDate}
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          const formattedDate = moment(date).format(ISO_DATE);
                          form.setFieldValue("endDate", formattedDate);
                          setAllVacationReports((prevState) => ({
                            ...prevState,
                            endDate: date,
                          }));
                        } else {
                          form.setFieldValue("endDate", "");
                        }
                        setIsEdited(true);
                      }}
                      minDate={allVacationReports?.startDate}
                      maxDate={
                        form?.values?.jobStartDate &&
                        values.vacationType === "labor"
                          ? parse(form?.values?.jobStartDate, INPUT_DATE, TODAY)
                          : null
                      }
                      // disabled={
                      //   values?.vacationType === "" ||
                      //   (values?.vacationType === "labor" &&
                      //     values?.sourceOfVacation === "")
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            values?.vacationType === "labor" ||
                            values?.vacationType === "partialPaidSocial"
                              ? values?.staffId && form?.errors?.endDate
                              : values?.staffId &&
                                values?.reason &&
                                form?.errors?.endDate &&
                                form?.errors?.endDate
                          }
                          error={
                            values?.vacationType === "labor" ||
                            values?.vacationType === "partialPaidSocial"
                              ? values?.staffId &&
                                form?.errors?.endDate &&
                                Boolean(form?.errors?.endDate)
                              : values?.staffId &&
                                values?.reason &&
                                form?.errors?.endDate &&
                                Boolean(form?.errors?.endDate)
                          }
                          fullWidth
                        />
                      )}
                    />
                  );
                }}
              </Field>
            </Grid>
          </Grid>

          {/*StaffId  and JobStartDate*/}
          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
            <Grid
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap:
                  allVacationReports?.vacationType !== "partialPaidSocial" &&
                  "12px",
              }}
            >
              {allVacationReports?.vacationType !== "labor" &&
                allVacationReports?.vacationType !== "partialPaidSocial" && (
                  <FormControl
                    style={{
                      marginBottom: "12px",
                    }}
                    error={Boolean(errors?.reason)}
                  >
                    <InputLabel id="reason">Məzuniyyətin səbəbi</InputLabel>
                    <Field
                      as={Select}
                      name="reason"
                      labelId="reason"
                      label="Məzuniyyətin səbəbi"
                      id="vacationReason-select"
                      onChange={(e) => {
                        const selectedItem = e?.target?.value;
                        setFieldValue("reason", selectedItem);
                        setAllVacationReports((prevState) => ({
                          ...prevState,
                          reason: selectedItem,
                        }));
                      }}
                      error={Boolean(errors?.reason)}
                      fullWidth
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            width: 200,
                          },
                          style: {
                            maxHeight: 300,
                          },
                        },
                        MenuListProps: {
                          style: {
                            display: "flex",
                            flexDirection: "column",
                          },
                        },
                      }}
                    >
                      {(
                        reasonArr?.[
                          `${allVacationReports?.vacationType}VacationReason`
                        ] || []
                      ).map((item, index) => (
                        <MenuItem
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                          }}
                          key={index}
                          value={item?.value}
                        >
                          <Tooltip title={item?.label} followCursor>
                            <span>{item?.label}</span>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Field>
                    {errors?.reason && (
                      <FormHelperText error>{errors?.reason}</FormHelperText>
                    )}
                  </FormControl>
                )}
              <FormControl
                sx={{ mb: 3 }}
                error={Boolean(errors?.staffId)}
                fullWidth
              >
                <InputLabel id="staffId">Müqavilə seç</InputLabel>
                <Field
                  as={Select}
                  name="staffId"
                  labelId="staffId"
                  label="Müqavilə seç"
                  id="staffId"
                  onChange={(val) => {
                    let value = currentEmployee?.staffs.filter((item) => {
                      return item?._id === val?.target?.value;
                    });

                    setSelectedStructureByStaff(value?.[0]?.structure?._id);
                    setAllVacationReports({
                      ...allVacationReports,
                      staffId: value?.[0]?._id,
                    });

                    handleChange(val);
                  }}
                  value={values?.staffId}
                  fullWidth
                  error={Boolean(errors?.staffId)}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      paddingLeft: "8px",
                    }}
                    disabled
                  >
                    Müqavilə
                  </MenuItem>
                  {currentEmployee?.staffs?.map(
                    (item, index) =>
                      item?.status === "active" && (
                        <MenuItem key={index} value={item?._id}>
                          {item?.contract?.data?.docNumber}
                        </MenuItem>
                      )
                  )}
                </Field>

                {errors?.staffId && (
                  <FormHelperText error>{errors?.staffId}</FormHelperText>
                )}
              </FormControl>
              {allVacationReports?.vacationType === "partialPaidSocial" && (
                <Grid sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id="replacementStaffId">
                      Əvəzləyici şəxs
                    </InputLabel>
                    <Field
                      as={Select}
                      name="replacementStaffId"
                      labelId="replacementStaffId"
                      label="Əvəzləyici şəxs"
                      id="replacementStaffId-select"
                      disabled={
                        values?.vacationType === "labor" ||
                        values?.vacationType === "partialPaidSocial"
                          ? !values?.staffId
                          : !values?.staffId || !values?.reason
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setIsEdited(true);
                      }}
                      fullWidth
                    >
                      {activeEmps?.length > 0 &&
                        activeEmps.map((item, index) => {
                          const emp = item?.employee[0];

                          if (
                            emp?.id === currentEmployee?.id ||
                            emp === undefined
                          )
                            return;
                          return (
                            <MenuItem key={index} value={item?._id}>
                              {emp?.firstname +
                                " " +
                                emp?.lastname +
                                ` ( ${
                                  item.position ? item?.position?.name : ""
                                } )`}
                            </MenuItem>
                          );
                        })}
                    </Field>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap:
                  allVacationReports?.vacationType !== "partialPaidSocial"
                    ? "12px"
                    : "0",
              }}
            >
              <Grid sx={{ pointerEvents: "none", marginBottom: "12px" }}>
                <Field name={`jobStartDate`}>
                  {({ field, form }) => {
                    return (
                      <DatePicker
                        {...field}
                        label="İşə başlama tarixi *"
                        disabled={
                          values?.vacationType === "labor" ||
                          values?.vacationType === "partialPaidSocial"
                            ? !values?.staffId
                            : !values?.staffId || !values?.reason
                        }
                        inputFormat={INPUT_DATE}
                        value={allVacationReports?.jobStartDate}
                        // disabled={
                        //   values?.vacationType === "" ||
                        //   (values?.vacationType === "labor" &&
                        //     values?.sourceOfVacation === "")
                        // }
                        renderInput={(params) => (
                          <TextField {...params} error={false} fullWidth />
                        )}
                      />
                    );
                  }}
                </Field>
              </Grid>
              {allVacationReports?.vacationType !== "labor" && (
                <Grid sx={{ width: "100%" }}>
                  <TextField
                    type="file"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled={
                      values?.vacationType === "labor" ||
                      values?.vacationType === "partialPaidSocial"
                        ? !values?.staffId
                        : !values?.staffId || !values?.reason
                    }
                    inputProps={{
                      accept: "application/pdf,image/jpeg",
                    }}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const maxFileSize = 10 * 1024 * 1024;

                      if (file) {
                        if (file.size > maxFileSize) {
                          alert("Faylın ölçüsü 10 MB-dan çox olmamalıdır.");
                          event.target.value = null;
                        } else if (
                          !["application/pdf", "image/jpeg"].includes(file.type)
                        ) {
                          alert("Yalnız PDF və JPEG fayllarına icazə verilir.");
                          event.target.value = null;
                        }
                        if (
                          file.size < maxFileSize &&
                          ["application/pdf", "image/jpeg"].includes(file.type)
                        ) {
                          handleFileChange(file);
                        }
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*ReplacementStaffId  and Progress*/}
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", gap: 3, mb: 3 }}
          >
            {allVacationReports?.vacationType !== "partialPaidSocial" && (
              <Grid sx={{ width: "50%" }}>
                <FormControl fullWidth>
                  <InputLabel id="replacementStaffId">
                    Əvəzləyici şəxs
                  </InputLabel>
                  <Field
                    as={Select}
                    name="replacementStaffId"
                    labelId="replacementStaffId"
                    label="Əvəzləyici şəxs"
                    id="replacementStaffId-select"
                    disabled={
                      values?.vacationType === "labor" ||
                      values?.vacationType === "partialPaidSocial"
                        ? !values?.staffId
                        : !values?.staffId || !values?.reason
                    }
                    onChange={(e) => {
                      handleChange(e);
                      setIsEdited(true);
                    }}
                    fullWidth
                  >
                    {activeEmps?.length > 0 &&
                      activeEmps.map((item, index) => {
                        const emp = item?.employee[0];

                        if (
                          emp?.id === currentEmployee?.id ||
                          emp === undefined
                        )
                          return;
                        return (
                          <MenuItem key={index} value={item?._id}>
                            {emp?.firstname +
                              " " +
                              emp?.lastname +
                              ` ( ${
                                item.position ? item?.position?.name : ""
                              } )`}
                          </MenuItem>
                        );
                      })}
                  </Field>
                </FormControl>
              </Grid>
            )}
            <Grid
              sx={{
                width: "50%",
                display: "flex",
                gap: 3,
              }}
            >
              <Button
                variant="outlined"
                color={progress === 100 ? "success" : "primary"}
                disabled={responseStatus === 201 || !isValid || !dirty}
                fullWidth
                sx={{
                  height: "100%",
                }}
                startIcon={<CreateNewFolderIcon />}
                onClick={() => {
                  handleVacationContract(values);
                }}
                // disabled={progress !== 0}
              >
                Məzuniyyət Ərizəsi Yarat
              </Button>
              {isLoading && <CircularProgress />}
            </Grid>
          </Grid>
          <Typography width={"100%"} textAlign={"center"} fontSize={18}>
            Məzuniyyət günlərinin sayı:{" "}
            <span>
              {allVacationReports?.totalVacationDays
                ? allVacationReports?.totalVacationDays
                : "başlama və bitmə tarixi təyin edilməyib"}
            </span>
          </Typography>
          {/* <Typography width={"100%"} textAlign={"center"} fontSize={18}>
            Məzuniyyət illəri:{" "}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {allVacationReports?.remainingWorkYears &&
                allVacationReports?.remainingWorkYears.map((item, index) => (
                  <div>
                    <p>
                      {`İş ili: ${moment(item?.startWorkYear).format(
                        "DD/MM/YYYY"
                      )} - ${moment(item?.endWorkYear).format("DD/MM/YYYY")}
                      `}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span>{`Əsas: ${item?.used?.main}`}</span>
                      <span>{`Əlavə: ${item?.used?.additional}`}</span>
                    </div>
                  </div>
                ))}
            </div>
          </Typography> */}
          {disabledReasonMessage?.length > 0 && (
            <div className="d-flex flex-column w-100 gap-3 mt-2">
              {disabledReasonMessage &&
                disabledReasonMessage.map((item, index) => {
                  return (
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <Typography
                        sx={{ color: "#ff9800" }}
                        key={`${item?.az}-${index}`}
                      >
                        {item?.az}
                      </Typography>
                    </Paper>
                  );
                })}
            </div>
          )}
          {/* Submit button */}
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !isValid ||
                isSubmitting ||
                responseStatus !== 201 ||
                progress !== 100
              }
            >
              Məzuniyyət Sorğusu Yarat
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateVacationForm;
