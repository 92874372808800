// React
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTheme } from "@emotion/react";
import SwipeableViews from "react-swipeable-views";

// Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import MyCabinetNavbar from "./MyCabinetNavbar";
import ExplanationsRequests from "./Explanations/ExplanationsRequests";
import Explanations from "./Explanations/Explanations";

// Material UI
import {
  Divider,
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";

// CSS
import style from "./myProfileStyle.module.css";
import Applications from "./Explanations/Applications";
import ExplanationsDialog from "./Explanations/ExplanationsDialog";
import ApplicationsDialog from "./Explanations/ApplicationsDialog";
import ExplanationData from "./Explanations/ExplanationData";
import ApplicationsRequests from "./Explanations/ApplicationRequests";
import Navbar from "./Navbar";
import { fetchExplanation_ApplicationRequest_SERVICE } from "../../services/cabinetServices";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function ExplanationsApplications() {
  const theme = useTheme();
  const [navbarStatus, setNavbarStatus] = useState(false);
  const [appDialog, setAppDialog] = useState(false);
  const [viewExpDialog, setViewExpDialog] = useState(false);
  const [expData, setExpData] = useState(false);
  const [openExpCreateDialog, setOpenExpCreateDialog] = useState(false);
  const [rowData, setRowData] = useState({});
  const [expID, setExpID] = useState();
  const [row, setrow] = useState();
  const [viewDialogText, setViewDialogText] = useState({
    content: "",
    date: "",
  });
  const [value, setValue] = useState(0);
  function appDialogSwitcher(paramsrow) {
    console.log(paramsrow);
    setrow(paramsrow);
    setAppDialog(!appDialog);
  }
  function expDialogSwitcher(paramsrow) {
    // console.log(paramsrow);
    // setrow(paramsrow);
    setViewExpDialog(!viewExpDialog);
  }

  function sendExplanationData(paramsrow) {
    setExpID(paramsrow);
    setExpData(!expData);
  }

  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const headerData = {
    title: "Ərizə və izahatlar",
    variant: "h3",
  };
  console.log(expID);
  async function fetchExplanationApplication(id) {
    try {
      // setIsLoading(true);
      const { data } = await fetchExplanation_ApplicationRequest_SERVICE(id);
      setRowData(data);
      // setTotalAppRow(data.total);
      // setApplications(data.contracts);
    } catch (error) {
      console.log("error", error);
    }
    // paginationHandler();
  }
  // const showHandler = () => setNavbarStatus(!navbarStatus);

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <HistoryEduIcon sx={{ width: "95%", height: "95%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            className={
              style.infoSection +
              " " +
              (navbarStatus ? style.animation2 : style.animation1)
            }
          >
            <Grid item xs={12} mb={6}>
              <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{
                    backgroundColor: "#4782da",
                    color: "#fff",
                    marginTop: "10px",
                  }}
                >
                  Ərizə yarat
                </Button>
              </Grid>
              <Box>
                <Box>
                  <Tabs value={value} onChange={handleChangeTab}>
                    <Tab
                      label="Mənə yönləndirilən ərizə və izahat sorğularım"
                      value={0}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Yazdığım ərizə və izahatlar"
                      value={1}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
              </Box>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  <Grid maxWidth={"100%"}>
                    <Grid sx={{ fontSize: "30px", textAlign: "center" }}>
                      Ərizə sorğuları
                    </Grid>
                    <ApplicationsRequests
                      sendExplanationData={sendExplanationData}
                    />
                  </Grid>
                  {/* {!loading && ( */}
                  <Grid maxWidth={"100%"} sx={{ marginBottom: "50px" }}>
                    <Grid sx={{ fontSize: "30px", textAlign: "center" }}>
                      İzahat sorğuları
                    </Grid>
                    <ExplanationsRequests
                      fetchExplanationApplication={fetchExplanationApplication}
                      setViewExpDialog={setViewExpDialog}
                      setOpenExpCreateDialog={setOpenExpCreateDialog}
                      setViewDialogText={{
                        set: setViewDialogText,
                        state: viewDialogText,
                      }}
                      sendExplanationData={sendExplanationData}
                    />
                  </Grid>

                  {/* )} */}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {/* {!loading && ( */}
                  <Grid>
                    <Grid sx={{ fontSize: "30px", textAlign: "center" }}>
                      Ərizələr
                    </Grid>
                    <Applications dialog={appDialogSwitcher} />
                  </Grid>
                  <Grid sx={{ marginBottom: "50px" }}>
                    <Grid sx={{ fontSize: "30px", textAlign: "center" }}>
                      İzahatlar
                    </Grid>
                    <div className="">
                      <Explanations dialog={expDialogSwitcher} />
                    </div>
                  </Grid>

                  {/* )} */}
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>

      <ExplanationData
        row={rowData}
        open={openExpCreateDialog}
        viewDialogText={viewDialogText}
        onClose={() => setOpenExpCreateDialog(false)}
        click={sendExplanationData}
      />

      <ExplanationsDialog
        row={rowData}
        open={viewExpDialog}
        onClose={expDialogSwitcher}
        click={expDialogSwitcher}
      />
      <ApplicationsDialog
        row={row}
        open={appDialog}
        onClose={appDialogSwitcher}
        click={appDialogSwitcher}
      />
    </>
  );
}
