import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import swal from "sweetalert";
import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { Person } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { getPagesData } from "../../../services/documentations/ContractServices";
import LabourPeriod from "./component/LabourPeriod";
import Wage from "./component/Wage";
import PhysicalEducationSport from "./component/PhysicalEducationSport";
import SocialProtection from "./component/SocialProtection";
import RegulationPropertyRelation from "./component/RegulationPropertyRelation";
import WorkVacationTime from "./component/WorkVacationTime";
import Protection from "./component/Protection";
import VacationForDialog from "./component/VacationForDialog";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import {
  empConstInitialValues,
  empConstModifeValue,
  empConstValidation,
  weekDays,
} from "./empConstMock/empConstMock";
import Administrative from "./component/Administrative";
import JobRelated from "./component/JobRelated";
import { DatePicker } from "@mui/x-date-pickers";
import InformationAboutParties from "./component/InformationAboutParties";
// SERVICES
import {
  updateLabourContractAfterEMAS_SERVICE,
  getEmployeesForLabourContractByID_SERVICE,
} from "../../../services/documentServices";
import { handleApiError } from "../../../api/changeRequests";

const InputCont = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 10px;
`;

export default function EmpContsDialog({ fetchData, open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [emps, setEmps] = useState([]);
  const [showCreateOrder, setShowCreateOrder] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState("");
  const [selectedEmpData, setSelectedEmpData] = useState(null);
  const [selectedCont, setSelectedCont] = useState("");
  const [positionId, setPositionId] = useState("");
  const [oldContract, setOldContract] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [banks, setBanks] = useState([]);

  const handleFetchEmps = async () => {
    try {
      const response = await getEmployeesForLabourContractByID_SERVICE();
      const res = await getPagesData("reasonOfTemporaryContract");
      setSelectedValue(res?.contracts?.static?.labour);
      setEmps(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleFetchEmps();
  }, []);
  const handleClose = () => {
    setOpen(false);
    setShowSubmit(false);
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const modifeValue = empConstModifeValue(values, selectedCont, oldData);

    const result = removeEmptyStrings(modifeValue);
    if (showSubmit) {
      try {
        const willSubmit = await swal({
          title: "Əminsiniz mi?",
          text: "Mövcud müqaviləyə dəyişiklik etməyə əminsiniz?",
          icon: "warning",
          dangerMode: true,
          buttons: ["Ləğv et", "Tətbiq et"],
        });
        if (willSubmit) {
          const resp = await updateLabourContractAfterEMAS_SERVICE(result);
          if (resp) {
            handleClose();
            swal(
              "Tamamlandı!",
              "Əmək müqavilələrinə dəyişiklik sorğusu göndərildi!",
              "success"
            );
            resetForm();
            await fetchData();
          }
        }
      } catch (error) {
        handleApiError(error);
      }
    }
    // setCreateOrder(false);
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: "70vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }} id="responsive-dialog-title">
          {"Ə.M. dəyişiklik"}
        </DialogTitle>
        <DialogContent sx={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Formik
              initialValues={empConstInitialValues(oldData)}
              enableReinitialize={true}
              validationSchema={empConstValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                resetForm,
                errors,
                setSubmitting,
                submitForm,
                isValid,
                dirty,
              }) => (
                <>
                  <Form style={{ width: "100%" }}>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          overflow: "scroll",
                        }}
                      >
                        {values?.labourContractChangeDate && (
                          <>
                            <InformationAboutParties
                              errors={errors}
                              values={values}
                              oldData={oldData}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                            />
                            <LabourPeriod
                              errors={errors}
                              values={values}
                              oldData={oldData}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                            />
                            <Wage
                              banks={banks}
                              values={values}
                              weekDays={weekDays}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                            />
                            <Protection
                              values={values}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                              inventoryTypes={inventoryTypes}
                            />
                            <WorkVacationTime
                              values={values}
                              weekDays={weekDays}
                              selectedValue={selectedValue}
                              setFieldValue={setFieldValue}
                            />
                            <VacationForDialog
                              values={values}
                              selectedValue={selectedValue}
                              setFieldValue={setFieldValue}
                              selectedEmpData={selectedEmpData}
                            />
                            <PhysicalEducationSport
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                            <SocialProtection
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                            <RegulationPropertyRelation
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                            <Administrative
                              values={values}
                              oldContract={oldContract}
                              setFieldValue={setFieldValue}
                            />
                            <JobRelated
                              values={values}
                              oldData={oldData}
                              positionIds={positionId}
                              selectedEmp={selectedEmp}
                              selectedCont={selectedCont}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <Typography
                          sx={{ paddingLeft: "10px", marginBottom: "10px" }}
                          variant="h4"
                        >
                          Əmək müqaviləsinə dəyişiklik ediləcək əməkdaşı seçin:
                        </Typography>
                        <InputCont>
                          <FormControl fullWidth>
                            <Autocomplete
                              options={emps || []}
                              getOptionLabel={(option) =>
                                `${option?.employee?.lastname || ""} ${
                                  option?.employee?.firstname || ""
                                } ${option?.employee?.fathername || ""}`
                              }
                              value={
                                emps?.find(
                                  (emp) => emp?.employee?._id === selectedEmp
                                ) || null
                              }
                              onChange={(event, selectedOption) => {
                                const val = selectedOption?.employee?._id || "";
                                setSelectedEmp(val);

                                if (selectedOption) {
                                  setBanks(
                                    selectedOption?.employee?.bankInfos || []
                                  );
                                  setInventoryTypes(
                                    selectedOption?.employee?.inventories || []
                                  );
                                  setSelectedEmpData(selectedOption);
                                }
                                setPositionId("");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Əməkdaş"
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>

                          <FormControl fullWidth sx={{ marginTop: "10px" }}>
                            <InputLabel id="cont-label">
                              Əmək müqavilələri
                            </InputLabel>
                            <Select
                              disabled={!selectedEmp}
                              label={"Əmək müqavilələri"}
                              labelId="cont-label"
                              value={selectedCont}
                              onChange={(e) => {
                                const val = e.target.value;
                                const selectedStaff = emps
                                  ?.filter(
                                    (emp) => emp?.employee?._id === selectedEmp
                                  )
                                  .flatMap((item) =>
                                    item?.staffs.filter(
                                      (staff) => staff?._id === val
                                    )
                                  );
                                const oldDataObj = {
                                  ...selectedStaff?.[0]?.contract?.data,
                                  isVerified:
                                    selectedStaff?.[0]?.contract
                                      ?.baseStructures?.[0]?.isVerified,
                                };

                                setOldData(oldDataObj);
                                setOldContract(
                                  selectedStaff?.[0]?.contract?.isOldContract
                                );
                                setPositionId(
                                  selectedStaff?.[0]?.contract
                                    ?.baseStructures?.[0]?._id
                                );
                                setSelectedCont(val);
                              }}
                            >
                              {emps &&
                                emps
                                  ?.filter(
                                    (emp, i) =>
                                      emp?.employee?._id === selectedEmp
                                  )
                                  .map((item, index) => {
                                    return item?.staffs.map((staff) => {
                                      return (
                                        <MenuItem value={staff?._id}>
                                          {staff?.contract?.data?.docNumber}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                            </Select>
                          </FormControl>

                          <Grid sx={{ width: "100%", marginTop: "10px" }}>
                            <Field name={`labourContractChangeDate`}>
                              {({ field, form }) => (
                                <DatePicker
                                  {...field}
                                  label="Əmək müqaviləsində dəyişiklik tarixi"
                                  inputFormat="dd/MM/yyyy"
                                  value={field.value || null}
                                  onChange={(date) => {
                                    if (date instanceof Date && !isNaN(date)) {
                                      form.setFieldValue(
                                        `labourContractChangeDate`,
                                        date
                                      );
                                    }
                                  }}
                                  maxDate={new Date()}
                                  minDate={new Date(1940, 0, 1)}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                              )}
                            </Field>
                          </Grid>
                        </InputCont>
                        <div
                          style={{
                            width: "96%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              window.open(`/employees/${selectedEmp}/view`)
                            }
                            sx={{ borderRadius: "5px" }}
                            disabled={!selectedEmp}
                          >
                            <Person />
                            <Typography>Əməkdaş haqqında</Typography>
                          </IconButton>
                        </div>
                      </div>
                      <Dialog
                        open={showCreateOrder}
                        onClose={() => setShowCreateOrder(false)}
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogTitle id="form-dialog-title">
                          Əmək müqaviləsinin rəsmiləşdirməsi formalaşsınmı?
                        </DialogTitle>

                        <DialogActions>
                          <Button
                            onClick={() => {
                              setShowSubmit(true);
                              setShowCreateOrder(false);
                              setFieldValue("createOrder", false);
                              submitForm();
                            }}
                            color="primary"
                          >
                            Xeyr
                          </Button>
                          <Button
                            onClick={() => {
                              setShowCreateOrder(false);
                              setFieldValue("createOrder", true);
                              submitForm();
                            }}
                            color="primary"
                          >
                            Bəli
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        paddingBottom: "15px",
                      }}
                    >
                      <Button
                        color="error"
                        variant="contained"
                        title="Ləğv et"
                        sx={{ width: "25%" }}
                        autoFocus
                        onClick={handleClose}
                      >
                        <Typography>Ləğv et</Typography>
                      </Button>
                      <Button
                        type={
                          !oldData?.isVerified &&
                          oldData.staffId !== values.jobRelated.staffId
                            ? "button"
                            : "submit"
                        }
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => {
                          setShowSubmit(true);
                          setShowCreateOrder(
                            !oldData?.isVerified &&
                              oldData.staffId !== values.jobRelated.staffId
                          );
                        }}
                        sx={{ width: "25%" }}
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        Tətbiq et
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
