import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";
import { BASE_URL } from "../../constants";
import { postTemplate_API } from "../../api/templateRequests";

// POST: /employee/contract/type/template -> Save Template
export const saveTemplate = async (file, id) => {
  try {
    // const response = await fetch(
    //   `${BASE_URL}/employee/contract/type/template`,
    //   {
    //     method: "POST",
    //     body: {
    //       content: file,
    //     },
    //   }
    // );
    const response = await postTemplate_API(file, id);
    return response;
  } catch (error) {
    console.log("error: ", error);
  }
};

// POST: /employee/contract/type -> Create Template
export const createTemplate = async (payload) => {
  try {
    const response = await api.post("/employee/contract/type", payload);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// GET: /employee/contract/type?limit=10&skip=0 -> Get Types
export const getTypes = async (payload) => {
  try {
    const response = await api.get(`/employee/contract/type`, {
      params: {
        limit: payload.limit,
        offset: payload.skip,
        type: payload.type || "",
        // name: payload.name || "",
        isPredefined: payload.isPredefined || "",
        filter: payload.filter,
      },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getTypesForFilter = async (payload) => {
  try {
    const response = await api.get(`/employee/contract/type/filter`, {
      params: {
        limit: payload.limit,
        offset: payload.skip,
        filter: payload.filter,
      },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// GET: /employee/contract/type/:id -> Get Type By Id
export const getTypeById = async (id) => {
  try {
    const response = await api.get(`/employee/contract/type/${id}`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// GET: /employee/contract/type/currentTemplate/template name -> Get Template
export const getTemplate = async (templateName) => {
  try {
    const response = await api.get(
      `/employee/contract/type/currentTemplate/${templateName}`
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// PATCH: /employee/contract/type/:id  -> Upload Template
export const updateTemplate = async (id, payload) => {
  try {
    const response = await api.patch(`/employee/contract/type/${id}`, payload);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// DELETE: /employee/contract/type/:id -> Delete Type
export const deleteTypes = async (id) => {
  try {
    const response = await api.delete(`/employee/contract/type/${id}`);
    toast.success("Şablon silindi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return await response.data;
  } catch (error) {
    error.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
    console.log("error: ", error);
  }
};

export const getDownloadTemplates = async ({
  columns,
  filter = undefined,
  isWithFilter,
}) => {
  const url = isWithFilter
    ? `employee/contract/type/download?filter=${filter}&columns=${columns}`
    : `employee/contract/type/download?columns=${columns}`;

  return await api.get(url, { responseType: "blob" });
};
