import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCommonData } from "../../api/api";
import useStaticData from "../../hooks/useStaticData/useStaticData";
import { isAfter } from "date-fns";
import { isDateMoreThan50Years } from "./Validations";
import moment from "moment";

const PersonalInformation = ({
  values,
  submitted,
  setOpenAddDialog,
  setSelectedType,
}) => {
  const [birthPlaces, setBirthPlaces] = useState([]);
  const [idCardAuthorities, setIdCardAuthorities] = useState([]);
  const [imageSrc, setImageSrc] = useState("");
  // Selector
  const { nationality } = useSelector((state) => state.nationality);

  const [state] = useStaticData();
  const { handleChange, errors, setErrors, setFieldValue } = useFormikContext();

  const inputDate = new Date();
  const finishYearDate = new Date(inputDate);
  finishYearDate.setFullYear(inputDate.getFullYear() - 16);
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 84);

  useEffect(() => {
    const handleUniversities = async () => {
      const birthResponse = await getCommonData("placeOfBirthList");
      const idCardResponse = await getCommonData("identityCardissuerList");
      // const workSchedule = await getCommonData("workSchedules");
      // setWorkSchedule(workSchedule?.data);
      setBirthPlaces(birthResponse?.data);
      setIdCardAuthorities(idCardResponse?.data);
    };
    handleUniversities();
  }, [submitted, values?.dob]);
  function setOneHundredDate() {
    let newDate = moment(values?.dob, "DD/MM/YYYY").add(100, "years");
    return newDate.format("DD/MM/YYYY");
  }

  const handleFileUpload = useCallback(
    async (event, index) => {
      const file = event.target.files[0];

      if (!file) {
        return; // No file selected
      }

      // Check if the file is a blob
      if (!(file instanceof Blob)) {
        console.error("File is not a Blob");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
        const fieldName = `employeePhotoAsBase64`;
        handleChange({ target: { name: fieldName, value: base64Image } });
        setImageSrc(reader.result);
      };

      reader.onerror = () => {
        console.error("Error reading file");
      };

      reader.readAsDataURL(file);

      if (errors?.employeePhotoAsBase64) {
        setErrors({
          ...errors,
          documents: [
            ...errors.documents,
            {
              ...errors.documents,
              employeePhotoAsBase64: "",
            },
            ...errors.documents,
          ],
        });
      }
    },
    [handleChange, errors]
  );
  const capitalizeFirstWord = (str) => {
    const words = str && str.split(" ");
    if (words?.length > 0) {
      words[0] =
        words[0]?.charAt(0)?.toUpperCase() + words[0]?.slice(1)?.toLowerCase();
    }
    return words?.join(" ");
  };
  return (
    <>
      <Typography mt={3} mb={3} variant="h6">
        Şəxsi məlumatlar
      </Typography>

      {/* Name and surname */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Grid sx={{ width: "100%" }}>
          <Field
            type="text"
            name="firstname"
            as={TextField}
            fullWidth
            required
            label="Ad"
            error={errors.firstname && Boolean(errors.firstname)}
            helperText={errors.firstname && errors.firstname}
            onChange={(event) => {
              setFieldValue("firstname", event.target.value);
              if (errors.firstname) {
                setErrors({ ...errors, firstname: "" });
              }
            }}
          />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Field
            type="text"
            name="lastname"
            as={TextField}
            label="Soyad"
            error={errors.lastname && Boolean(errors.lastname)}
            helperText={errors.lastname && errors.lastname}
            required
            fullWidth
            onChange={(event) => {
              setFieldValue("lastname", event.target.value);
              if (errors.lastname) {
                setErrors({ ...errors, lastname: "" });
              }
            }}
          />
        </Grid>
      </Grid>

      {/* Father name and gender */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Grid sx={{ width: "100%" }}>
          <Field
            type="text"
            name="fathername"
            as={TextField}
            fullWidth
            label="Ata adı"
            error={errors.fathername && Boolean(errors.fathername)}
            helperText={errors.fathername && errors.fathername}
            required
            onChange={(event) => {
              setFieldValue("fathername", event.target.value);
              if (errors.fathername) {
                setErrors({ ...errors, fathername: "" });
              }
            }}
          />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <FormControl error={errors.gender && errors.gender} fullWidth>
            <InputLabel id="gender">Cinsi *</InputLabel>
            <Field
              as={Select}
              name="gender"
              labelId="gender"
              label="Cinsi"
              id="gender-select"
              fullWidth
              error={errors.gender && Boolean(errors.gender)}
              required
              onChange={(event) => {
                setFieldValue("gender", event.target.value);
                if (errors.gender) {
                  setErrors({ ...errors, gender: "" });
                }
              }}
            >
              <MenuItem value="male">Kişi</MenuItem>
              <MenuItem value="female">Qadın</MenuItem>
            </Field>
            {errors.gender && errors.gender && (
              <FormHelperText error>{errors.gender}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      {/* Birthday and place of birth */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Field name="dob">
          {({ field, form }) => (
            <DatePicker
              {...field}
              label="Doğum tarixi *"
              defaultCalendarMonth={finishYearDate}
              inputFormat="dd/MM/yyyy"
              value={field.value || null}
              onChange={(date) => {
                if (date && !isAfter(date, finishYearDate)) {
                  form.setFieldValue("dob", date);
                  if (errors.dob) {
                    setErrors({ ...errors, dob: "" });
                  }
                } else {
                  form.setFieldValue("dob", "");
                }
              }}
              maxDate={finishYearDate}
              minDate={minDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={form.errors.dob}
                  error={Boolean(form.errors.dob)}
                  fullWidth
                />
              )}
              required
            />
          )}
        </Field>

        <Grid sx={{ width: "100%" }}>
          <FormControl
            error={errors?.placeOfBirth && Boolean(errors?.placeOfBirth)}
            fullWidth
          >
            <InputLabel id={`placeOfBirth`}>Doğulduğu yer *</InputLabel>
            <Field
              as={Select}
              name={`placeOfBirth`}
              labelId={`placeOfBirth`}
              label="Doğulduğu yer *"
              id={`placeOfBirth`}
              defaultValue=""
              fullWidth
              error={errors?.placeOfBirth && Boolean(errors?.placeOfBirth)}
              onChange={(event) => {
                setFieldValue("placeOfBirth", event.target.value);
                if (errors.placeOfBirth) {
                  setErrors({ ...errors, placeOfBirth: "" });
                }
              }}
            >
              {birthPlaces &&
                birthPlaces
                  ?.sort((a, b) => a.localeCompare(b, "az"))
                  .map((item, index) => (
                    <MenuItem key={`${item} ${index}`} value={item}>
                      {capitalizeFirstWord(item)}
                    </MenuItem>
                  ))}
              <Button
                sx={{
                  mt: 3,
                  ml: 3,
                }}
                variant="outlined"
                onClick={() => {
                  setOpenAddDialog(true);
                  setSelectedType([
                    "placeOfBirthList",
                    { name: "Doğum yerləri" },
                  ]);
                }}
              >
                Yeni doğum yeri əlavə et
              </Button>
            </Field>
            {errors.placeOfBirth && errors.placeOfBirth && (
              <FormHelperText error>{errors.placeOfBirth}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* <Grid sx={{ width: "100%" }}>
         <FormControl
            error={errors?.workTime && Boolean(errors?.workTime)}
            fullWidth
          >
            <InputLabel id={`workSchedule`}>İş vaxtları</InputLabel>
            <Field
              as={Select}
              name={`workTime`}
              labelId={`workTime`}
              label="İş vaxtı"
              id={`workTime`}
              defaultValue=""
              fullWidth
              error={errors?.workTime && Boolean(errors?.workTime)}
            >
              {workSchedule.map((item, index) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
              <Button
                sx={{
                  mt: 3,
                  ml: 3,
                }}
                variant="outlined"
                onClick={() => {
                  setOpenAddDialog(true);
                  setSelectedType(["workSchedules", { name: "İş vaxtları" }]);
                }}
              >
                İş vaxtı əlavə et
              </Button>
            </Field>
            {errors.placeOfBirth && errors.placeOfBirth && (
              <FormHelperText error>{errors.placeOfBirth}</FormHelperText>
            )}
            </FormControl> 
        </Grid>*/}
      </Grid>

      {/* Nationality and idCardAuthority */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
          <FormControl
            error={errors.nationality && errors.nationality}
            fullWidth
          >
            <InputLabel id="nationality">Milliyyət *</InputLabel>
            <Field
              as={Select}
              name="nationality"
              labelId="nationality"
              label="nationality"
              id="nationality-select"
              fullWidth
              error={errors.nationality && Boolean(errors.nationality)}
              required
              onChange={(event) => {
                setFieldValue("nationality", event.target.value);
                if (errors.nationality) {
                  setErrors({ ...errors, nationality: "" });
                }
              }}
            >
              {state?.nationalities?.data &&
                state?.nationalities?.data?.map((item, index) => (
                  <MenuItem key={`${item} ${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Field>
            {errors.nationality && errors.nationality && (
              <FormHelperText error>{errors.nationality}</FormHelperText>
            )}
          </FormControl>

          {nationality === "foreign" ? (
            <FormControl
              error={errors.citizenship && errors.citizenship}
              fullWidth
            >
              <InputLabel id="citizenship">Vətəndaşlığı *</InputLabel>
              <Field
                as={Select}
                name="citizenship"
                labelId="citizenship"
                label="citizenship"
                id="citizenship-select"
                fullWidth
                error={errors.citizenship && Boolean(errors.citizenship)}
                required
                onChange={(event) => {
                  setFieldValue("citizenship", event.target.value);
                  if (errors.citizenship) {
                    setErrors({ ...errors, citizenship: "" });
                  }
                }}
              >
                {state?.nationalities?.data &&
                  state?.nationalities?.data?.map((item, index) => {
                    if (item === "Azərbaycan") return;
                    return (
                      <MenuItem key={`${index} ${item}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Field>
              {errors.nationality && errors.nationality && (
                <FormHelperText error>{errors.nationality}</FormHelperText>
              )}
            </FormControl>
          ) : (
            <FormControl
              error={errors.citizenship && errors.citizenship}
              fullWidth
              style={{
                visibility: "hidden",
                position: "absolute",
                zIndex: "-100",
                width: "100px",
              }}
            >
              <InputLabel id="citizenship">Vətəndaşlığı *</InputLabel>
              <Field
                as={Select}
                name="citizenship"
                labelId="citizenship"
                label="citizenship"
                id="citizenship-select"
                fullWidth
                error={errors.citizenship && Boolean(errors.citizenship)}
                required
                disabled // Disable the select menu
              >
                <MenuItem selected value="Azərbaycan">
                  Azərbaycan
                </MenuItem>
              </Field>
              {errors.citizenship && (
                <FormHelperText error>{errors.citizenship}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <FormControl
            error={errors?.idCardAuthority && Boolean(errors?.idCardAuthority)}
            fullWidth
          >
            <InputLabel id={`idCardAuthority`}>
              {nationality === "foreign"
                ? "MYİ-ni verən təşkilat *"
                : "Şəxsiyyət vəsiqəsini verən təşkilat *"}
            </InputLabel>
            <Field
              as={Select}
              name={`idCardAuthority`}
              labelId={`idCardAuthority`}
              label={
                nationality === "foreign"
                  ? "MYİ-ni verən təşkilat *"
                  : "Şəxsiyyət vəsiqəsini verən təşkilat *"
              }
              id={`idCardAuthority`}
              defaultValue=""
              fullWidth
              error={
                errors?.idCardAuthority && Boolean(errors?.idCardAuthority)
              }
              onChange={(event) => {
                setFieldValue("idCardAuthority", event.target.value);
                if (errors.idCardAuthority) {
                  setErrors({ ...errors, idCardAuthority: "" });
                }
              }}
            >
              {idCardAuthorities &&
                idCardAuthorities
                  ?.sort((a, b) => a.localeCompare(b, "az"))
                  .map((item, index) => (
                    <MenuItem key={`${item} ${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              <Button
                sx={{
                  mt: 3,
                  ml: 3,
                }}
                variant="outlined"
                onClick={() => {
                  setOpenAddDialog(true);
                  setSelectedType([
                    "identityCardissuerList",
                    { name: "Ş.V verən orqanlar" },
                  ]);
                }}
              >
                {nationality === "foreign"
                  ? "Yeni MYİ-ni verən təşkilat əlavə et *"
                  : "Yeni şəxsiyyət vəsiqəsini verən təşkilat əlavə et *"}
              </Button>
            </Field>
            {errors.idCardAuthority && errors.idCardAuthority && (
              <FormHelperText error>{errors.idCardAuthority}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      {/* National ID and PIN */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
          {nationality === "foreign" ? null : (
            <Grid sx={{ width: 1 / 5 }}>
              <FormControl fullWidth>
                <InputLabel
                  error={
                    errors.idCardNumber?.code &&
                    Boolean(errors.idCardNumber?.code)
                  }
                  id={`idCardNumber.code`}
                >
                  Ş.V/Kod
                </InputLabel>
                <Field
                  as={Select}
                  name={`idCardNumber.code`}
                  label="Ş.V/Kod"
                  labelId={`idCardNumber.code`}
                  id={`idCardNumber.code`}
                  defaultValue=""
                  fullWidth
                  error={
                    errors.idCardNumber?.code &&
                    Boolean(errors.idCardNumber?.code)
                  }
                  helperText={
                    errors.idCardNumber?.code && errors.idCardNumber?.code
                  }
                  required
                  onChange={(event) => {
                    setFieldValue("idCardNumber.code", event.target.value);
                    if (errors?.idCardNumber?.code) {
                      setErrors({
                        ...errors,
                        idCardNumber: {
                          ...errors?.idCardNumber,
                          number: "",
                          code: "",
                        },
                      });
                    }
                  }}
                >
                  <MenuItem value="AA">AA</MenuItem>
                  <MenuItem value="AZE">AZE</MenuItem>
                </Field>
              </FormControl>
            </Grid>
          )}

          <Grid sx={{ width: nationality === "foreign" ? "100%" : 4 / 5 }}>
            <Field
              type="text"
              name="idCardNumber.number"
              as={TextField}
              fullWidth
              label={nationality === "foreign" ? "Passport №" : "Ş.V/Seriya №"}
              error={
                errors.idCardNumber?.number &&
                Boolean(errors.idCardNumber?.number)
              }
              helperText={
                errors.idCardNumber?.number && errors.idCardNumber?.number
              }
              required
              onChange={(event) => {
                setFieldValue("idCardNumber.number", event.target.value);
                if (errors?.idCardNumber?.number) {
                  setErrors({
                    ...errors,
                    idCardNumber: { ...errors?.idCardNumber, number: "" },
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
          <Field
            type="text"
            name="pin"
            as={TextField}
            fullWidth
            label="Fərdi identifikasiya Nömrəsi (FİN)"
            onChange={(event) => {
              const uppercaseValue = event.target.value.toUpperCase();
              setFieldValue("pin", uppercaseValue);
              if (errors.pin) {
                setErrors({ ...errors, pin: "" });
              }
            }}
            error={errors.pin && Boolean(errors.pin)}
            helperText={errors.pin && errors.pin}
            required
          />

          {nationality === "foreign" ? (
            <Field
              type="text"
              name="tRPNumber"
              as={TextField}
              fullWidth
              label="MYİ seriya Nömrəsi"
              error={errors.tRPNumber && Boolean(errors.tRPNumber)}
              helperText={errors.tRPNumber && errors.tRPNumber}
              required
              onChange={(event) => {
                setFieldValue("tRPNumber", event.target.value);
                if (errors.tRPNumber) {
                  setErrors({ ...errors, tRPNumber: "" });
                }
              }}
            />
          ) : null}
        </Grid>
      </Grid>

      {/* National id given date and validity period */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Field name="idCardIssuanceDate">
          {({ field, form }) => (
            <DatePicker
              {...field}
              label={
                nationality === "foreign"
                  ? "Passportun verilmə tarixi *"
                  : "Ş.V. verilmə tarixi *"
              }
              inputFormat="dd/MM/yyyy"
              value={field.value || null}
              maxDate={new Date()} // Disable future dates
              minDate={minDate}
              onChange={(date) => {
                if (date instanceof Date && !isNaN(date)) {
                  form.setFieldValue("idCardIssuanceDate", date);
                  if (errors.idCardIssuanceDate) {
                    setErrors({ ...errors, idCardIssuanceDate: "" });
                  }
                } else {
                  form.setFieldValue("idCardIssuanceDate", "");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={form.errors.idCardIssuanceDate}
                  error={Boolean(form.errors.idCardIssuanceDate)}
                  fullWidth
                />
              )}
              required
            />
          )}
        </Field>
        {/* <Field name="idCardValidityPeriod">
          {({ field, form }) => (
            <>
              <DatePicker
                {...field}
                label={
                  nationality === "foreign"
                    ? "Passportun etibarlılıq müddəti"
                    : "Ş.V. etibarlılıq Müddəti"
                }
                inputFormat="dd/MM/yyyy"
                value={
                  nationality !== "foreign" && isDateMoreThan50Years(values.dob)
                    ? setOneHundredDate()
                    : field.value || null
                }
                minDate={new Date()}
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date)) {
                    form.setFieldValue("idCardValidityPeriod", date);
                    if (errors.idCardValidityPeriod) {
                      setErrors({ ...errors, idCardValidityPeriod: "" });
                    }
                  } else {
                    form.setFieldValue("idCardValidityPeriod", "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={form.errors.idCardValidityPeriod}
                    error={Boolean(form.errors.idCardValidityPeriod)}
                    fullWidth
                  />
                )}
                required
              />
            </>
          )}
        </Field> */}
        {values.dob === undefined ? (
          <Field name="idCardValidityPeriod">
            {({ field, form }) => (
              <>
                <DatePicker
                  {...field}
                  label={
                    nationality === "foreign"
                      ? "Passportun etibarlılıq müddəti"
                      : "Ş.V. etibarlılıq Müddəti"
                  }
                  inputFormat="dd/MM/yyyy"
                  value={field.value || null}
                  minDate={new Date()} // Disable past dates
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      form.setFieldValue("idCardValidityPeriod", date);
                      if (errors.idCardValidityPeriod) {
                        setErrors({ ...errors, idCardValidityPeriod: "" });
                      }
                    } else {
                      form.setFieldValue("idCardValidityPeriod", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={form.errors.idCardValidityPeriod}
                      error={Boolean(form.errors.idCardValidityPeriod)}
                      fullWidth
                    />
                  )}
                  required
                />
              </>
            )}
          </Field>
        ) : (
          <Field name="idCardValidityPeriod">
            {({ field, form }) => (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <DatePicker
                  {...field}
                  label={
                    nationality === "foreign"
                      ? "Passportun etibarlılıq müddəti"
                      : "Ş.V. etibarlılıq Müddəti"
                  }
                  inputFormat="dd/MM/yyyy"
                  value={field.value || null}
                  minDate={new Date()}
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      form.setFieldValue("idCardValidityPeriod", date);
                      if (errors.idCardIssuanceDate) {
                        setErrors({ ...errors, idCardIssuanceDate: "" });
                      }
                    } else {
                      form.setFieldValue("idCardValidityPeriod", "");
                    }
                  }}
                  // onChange={(date) => {
                  //   // Check if age is greater than 50
                  //   if (
                  //     new Date().getFullYear() -
                  //       new Date(values.dob).getFullYear() >
                  //     50
                  //   ) {
                  //     // Reset idCardValidityPeriod to null
                  //     form.setFieldValue("idCardValidityPeriod", null);
                  //   } else {
                  //     // Age is less than or equal to 50, handle other cases
                  //     if (date instanceof Date && !isNaN(date)) {
                  //       form.setFieldValue("idCardValidityPeriod", date);
                  //       if (errors.idCardValidityPeriod) {
                  //         setErrors({ ...errors, idCardValidityPeriod: "" });
                  //       }
                  //     } else {
                  //       form.setFieldValue("idCardValidityPeriod", "");
                  //     }
                  //   }
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={form.errors.idCardValidityPeriod}
                      error={Boolean(form.errors.idCardValidityPeriod)}
                      fullWidth
                    />
                  )}
                  required
                />
                {nationality !== "foreign" &&
                isDateMoreThan50Years(values.dob) ? (
                  <span>
                    50 yaş üstü vətəndaşların şəxsiyyət vəsiqələrinin etibarlıq
                    müddəti olmaya bilər!
                  </span>
                ) : null}
              </div>
            )}
          </Field>
        )}
      </Grid>

      {/* For foreign employeers */}
      {nationality === "foreign" ? (
        <>
          {/* Living permission start and end date */}
          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
            <Field name="residencePermitStartDate">
              {({ field, form }) => (
                <DatePicker
                  {...field}
                  label="Yaşamaq icazəsi başlama tarixi *"
                  inputFormat="dd/MM/yyyy"
                  value={field.value || null}
                  maxDate={new Date()} // Disable future dates
                  minDate={new Date(1940, 0, 1)}
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      form.setFieldValue("residencePermitStartDate", date);
                      if (errors.residencePermitStartDate) {
                        setErrors({ ...errors, residencePermitStartDate: "" });
                      }
                    } else {
                      form.setFieldValue("residencePermitStartDate", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={form.errors.residencePermitStartDate} // Display the validation error message
                      error={Boolean(form.errors.residencePermitStartDate)} // Set error state based on the validation error
                      fullWidth
                    />
                  )}
                  required
                />
              )}
            </Field>
            <Field name="residencePermitEndDate">
              {({ field, form }) => (
                <DatePicker
                  {...field}
                  label="Yaşamaq icazəsi bitmə tarixi *"
                  inputFormat="dd/MM/yyyy"
                  value={field.value || null}
                  minDate={new Date()} // Disable past dates
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      form.setFieldValue("residencePermitEndDate", date);
                      if (errors.residencePermitEndDate) {
                        setErrors({ ...errors, residencePermitEndDate: "" });
                      }
                    } else {
                      form.setFieldValue("residencePermitEndDate", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={form.errors.residencePermitEndDate} // Display the validation error message
                      error={Boolean(form.errors.residencePermitEndDate)} // Set error state based on the validation error
                      fullWidth
                    />
                  )}
                  required
                />
              )}
            </Field>
          </Grid>

          {/* Work permission start and end date */}
          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
            <Field name="workPermitStartDate">
              {({ field, form }) => (
                <DatePicker
                  {...field}
                  label="İş icazəsi başlama tarixi *"
                  inputFormat="dd/MM/yyyy"
                  value={field.value || null}
                  maxDate={new Date()} // Disable future dates
                  minDate={new Date(1940, 0, 1)}
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      form.setFieldValue("workPermitStartDate", date);
                      if (errors.workPermitStartDate) {
                        setErrors({ ...errors, workPermitStartDate: "" });
                      }
                    } else {
                      form.setFieldValue("workPermitStartDate", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={form.errors.workPermitStartDate}
                      error={Boolean(form.errors.workPermitStartDate)}
                      fullWidth
                    />
                  )}
                  required
                />
              )}
            </Field>
            <Field name="workPermitEndDate">
              {({ field, form }) => (
                <DatePicker
                  {...field}
                  label="İş icazəsi bitmə tarixi *"
                  inputFormat="dd/MM/yyyy"
                  value={field.value || null}
                  minDate={new Date()} // Disable past dates
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      form.setFieldValue("workPermitEndDate", date);
                      if (errors.workPermitEndDate) {
                        setErrors({ ...errors, workPermitEndDate: "" });
                      }
                    } else {
                      form.setFieldValue("workPermitEndDate", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={form.errors.workPermitEndDate}
                      error={Boolean(form.errors.workPermitEndDate)}
                      fullWidth
                    />
                  )}
                  required
                />
              )}
            </Field>
          </Grid>
        </>
      ) : null}

      {/* SSN */}
      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
        <Grid sx={{ width: "100%" }}>
          <Field
            type="text"
            name="ssn"
            as={TextField}
            fullWidth
            label="SSN"
            // onInput={handleInput}
            error={errors.ssn && Boolean(errors.ssn)}
            helperText={errors.ssn && errors.ssn}
            onChange={(event) => {
              setFieldValue("ssn", event.target.value);
              if (errors.ssn) {
                setErrors({ ...errors, ssn: "" });
              }
            }}
          />
        </Grid>

        <Grid sx={{ width: "100%" }}>
          <FormControl
            error={errors?.maritalStatus && Boolean(errors?.maritalStatus)}
            fullWidth
          >
            <InputLabel id={`maritalStatus`}>Ailə vəziyyəti *</InputLabel>
            <Field
              as={Select}
              name={`maritalStatus`}
              labelId={`maritalStatus`}
              label="Ailə vəziyyəti *"
              id={`maritalStatus`}
              defaultValue=""
              fullWidth
              error={errors?.maritalStatus && Boolean(errors?.maritalStatus)}
              onChange={(event) => {
                setFieldValue("maritalStatus", event.target.value);
                if (errors.maritalStatus) {
                  setErrors({ ...errors, maritalStatus: "" });
                }
              }}
            >
              <MenuItem value="married">Evli</MenuItem>
              <MenuItem value="single">Subay</MenuItem>
            </Field>
            {errors?.maritalStatus && errors?.maritalStatus && (
              <FormHelperText error>{errors?.maritalStatus}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ width: "49%" }}>
        <div style={{ display: "flex" }}>
          <input
            type="file"
            accept="image/*"
            onChange={(event) => handleFileUpload(event)}
            fullWidth
          />
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Profile"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
              }}
            />
          )}
        </div>
        <hr />
        <InputLabel>Profil şəkli</InputLabel>
      </Grid>
    </>
  );
};

export default PersonalInformation;
