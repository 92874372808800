import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import CompensationTable from "./compensation-table/compensation-table";
import useCompensation from "../../../../../hooks/useCompensation";
const Divider = styled(MuiDivider)(spacing);

const Compensation = () => {
  const navigate = useNavigate();
  const {
    data,
    employee,
    tableData,
    commandData,
    contractData,
    handleSubmit,
    setContractName,
    compensationData,
    updateVacantorder,
    handleInputChange,
    currentContractData,
    handleEmployeeChange,
    handleCurrentContract,
    createFillingVacantPositionOrder,
  } = useCompensation();
  useEffect(() => {
    setContractName("compensation-order");
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Kompensassiyanın verilməsi
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: commandData?._id ? "none" : "",
                }}
              >
                <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  variant="outlined"
                  value={employee.employeeId || ""}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                >
                  {data &&
                    data?.map((d, i) => (
                      <MenuItem value={d?._id} key={i}>
                        {d?.firstname} {d?.lastname} {d?.fathername}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Müqavilə nömrəsi</InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    variant="outlined"
                    value={employee?.empStaffId || ""}
                    disabled={!employee.employeeId}
                    onChange={(e) => handleCurrentContract(e.target.value)}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem value={d?.contract?.staffs?.[0]?._id} key={i}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Müqavilənin tarixi"
                    value={currentContractData?.contract?.decisionDate || null}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                value={employee?.tabelNumber || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Təşkilat"
                label="Təşkilat"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={currentContractData?.structure?.organization?.name || ""}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Struktur"
                label="Struktur"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={
                  currentContractData?.contract?.topPartOfStructures?.[0]
                    ?.source?.name || ""
                }
                variant="outlined"
                my={2}
              />
              <TextField
                name="Substruktur"
                label="Substruktur"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={currentContractData?.part?.parent?.source?.name || ""}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Vəzifə"
                label="Vəzifə"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={currentContractData?.part?.source.name || ""}
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <TextField
                name="Gün"
                label="Gün"
                type="number"
                onChange={(e) =>
                  handleInputChange("residueDaysFromVacation", e.target.value)
                }
                value={compensationData?.residueDaysFromVacation || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                variant="outlined"
                my={2}
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <TextField
                  name="Məbləğ"
                  label="Məbləğ"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  my={2}
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "+" ||
                      e.key === "-"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  type="number"
                />
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Valyuta</InputLabel>
                  <Select
                    name="currency"
                    labelId="Valyuta"
                    label="Valyuta"
                    // value={"AZN"}
                    // value={compensationData?.currency || ""}
                    onChange={(e) =>
                      handleInputChange("currency", e.target.value)
                    }
                    value={"AZN"}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="AZN">AZN</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid mb={10}>
            <TextField
              onChange={(e) => handleInputChange("note", e.target.value)}
              name="Qeyd"
              label="Qeyd"
              fullWidth
              variant="outlined"
              inputProps={{ maxLength: 500 }}
              value={compensationData?.note || ""}
            />
          </Grid>
          <CompensationTable data={tableData} />
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
              onClick={
                commandData?._id
                  ? updateVacantorder
                  : createFillingVacantPositionOrder
              }
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default Compensation;
