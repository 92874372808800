import { api } from "../../api/axiosInterceptor";
import { handleApiError } from "../../api/changeRequests";
import { getFitlersData } from "../../services/filter/filterService";
import {
  getAllStructures,
  getPartsStructure,
  getPositionsStructure,
  getStructureNamesForStructureRequests,
  getTopPartsStructure,
} from "../../services/structure/StructureServices";
import { getDescendantProp } from "../helperFunctions";

const executeData = (data, path) => {
  const arr = [];
  data.map((item) => {
    arr.push({
      key: item._id,
      name: getDescendantProp(item, path),
    });
  });
  return arr;
};

export const getAllStructureRequestsNames = async () => {
  try {
    const { data } = await getStructureNamesForStructureRequests();
    if (!data) return [];
    return data && executeData(data, "name");
  } catch (error) {
    console.log("getStructureNamesForStructureRequests", error);
  }
};

export const patchStructureRequestById = async (payload) => {
  try {
    return await api.patch(`employee/s/editRequest/cancel/${payload.id}`);
  } catch (error) {
    console.log("patchStructureRequestById", error);
  }
};

export const getStructureRequestById = async (id) => {
  try {
    return await api.get(`employee/s/${id}`);
  } catch (error) {
    console.log("getStructureRequestById", error);
  }
};

//teskilat
export const getAllStructuresForFilter = async () => {
  try {
    const { data } = await getAllStructures();
    if (!data.structures) return [];

    return data.structures && executeData(data.structures, "organization.name");
  } catch (error) {
    console.log("getAllStructuresForFilter", error);
  }
};
// struktur
export const getTopPartsForFilter = async (id = undefined) => {
  try {
    const { data } = await getTopPartsStructure(id);
    const parts = data?.parts;
    if (!parts) return [];

    return parts && executeData(parts, "source.name");
  } catch (error) {
    console.log("getTopPartsForFilter", error);
  }
};
// substruktur
export const getPartsForFilter = async (id = undefined) => {
  try {
    const { data } = await getPartsStructure(id);
    const parts = data?.parts;

    if (!parts) return [];

    return data.parts && executeData(data.parts, "source.name"); // ????
  } catch (error) {
    console.log("getPartsForFilter", error);
  }
};
// vezife
export const getPositionsForFilter = async (id = undefined) => {
  try {
    const { data } = await getPositionsStructure(id);
    const parts = data?.parts;

    if (!parts) return [];

    return data.parts && executeData(data.parts, "source.name"); // ????
  } catch (error) {
    console.log("getPartsForFilter", error);
  }
};

export const getStaticFilterValues = async (
  dataGridName,
  structure = [],
  topOfStructure = []
) => {
  try {
    const { data } = await getFitlersData(
      dataGridName,
      structure,
      topOfStructure
    );
    console.log(data);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};
