import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../../api/api";
import CustomAccordion from "../../../components/ui/Accordion/Accordion";
// import { getCommonData, updateEmployee } from "../../api/api";
// import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { deductionsWagesValidation } from "../employees/Validations";

const MCDeductionWages = ({ employeeById }) => {
  const [deductionsName, setDeductionsName] = useState([]);
  const [pastData, setPastData] = useState([]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const modifiedValues = {
      ...values,
      deductionWages:
        values?.deductionWages &&
        values?.deductionWages.map((deduction) => {
          const { ...rest } = deduction;
          return {
            ...rest,
          };
        }),
    };
    await updateEmployee(employeeById._id, modifiedValues);
    setSubmitting(false);
    resetForm({ values });
  };

  useEffect(() => {
    const handleSupplements = async () => {
      try {
        const response = await getCommonData("deductionWageGeneral");
        const deductions = response?.data || [];
        setDeductionsName(deductions);
        const combinedData = [];
        if (deductions.length) {
          combinedData.push({ deductions: [...deductions] });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleSupplements();
  }, []);

  const handleWageSelect = (item) => {
    const selectWage = deductionsName.find((wage) => {
      return wage?.name === item;
    });
    return selectWage;
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    deductionsName &&
      deductionsName.push(employeeById?.deductionsWage[index]?.name);
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }

  return <CustomAccordion>salam</CustomAccordion>;
};

export default MCDeductionWages;
