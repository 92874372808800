// eslint-disable-next-line react/jsx-no-undef
import React from "react";
import { Close, AcUnit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import moment from "moment";

const CustomDialog = ({ data, view_dialog, buttons, title, type }) => {
  const handleClickOpen = () => {
    view_dialog?.set(true);
  };
  const handleClose = () => {
    view_dialog?.set(false);
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  let dialog_content = () => {
    switch (type) {
      case "preview-order":
        return (
          data?.length &&
          data?.map((d, i) => (
            <div>
              <div
                className="mt-2 mb-2 text-center"
                style={{ fontSize: "20px" }}
              >
                <b>
                  {d?.docNumber?.label} - {d?.docNumber?.value}
                </b>
              </div>
              {i !== 0 && (
                <div className="mb-4 text-center">
                  <div
                    style={{
                      border: "2px solid #80808047",
                      borderRadius: "8px",
                    }}
                  ></div>
                </div>
              )}
              <div>
                {/* <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.docNumber?.label}</div>
                  <div>{d?.docNumber?.value}</div>
                </div>
                <hr /> */}
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.orgName?.label}</div>
                  <div>{d?.orgName?.value}</div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.employeeName?.label}</div>
                  <div>{d?.employeeName?.value}</div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.employeeFatherRelation?.label}</div>
                  <div>{d?.employeeFatherRelation?.value}</div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.reason?.label}</div>
                  <div>{d?.reason?.value}</div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.startDate?.label}</div>
                  <div>
                    {moment(d?.startDate?.value).format("MM-DD-YYYY, h:mm:ss")}
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.endDate?.label}</div>
                  <div>
                    {moment(d?.endDate?.value).format("MM-DD-YYYY, h:mm:ss")}
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.recruiterName?.label}</div>
                  <div>{d?.recruiterName?.value}</div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.recruiterPositionName?.label}</div>
                  <div>{d?.recruiterPositionName?.value}</div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>{d?.signDate?.label}</div>
                  <div>
                    {moment(d?.signDate?.value).format("MM-DD-YYYY, h:mm:ss")}
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div>ƏM Maddələri:</div>
                  <div className="w-75">
                    {d?.clause?.value?.length &&
                      d?.clause?.value?.map((c, i) => (
                        <>
                          <div>{c}</div>
                          {i === d?.value?.length - 1 ? <hr /> : null}
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))
        );
      case "create-order":
        return (
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.employeeName?.label}</div>
              <div>{data?.employeeName?.value} </div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.orgName?.label}</div>
              <div>{data?.orgName?.value}</div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.clause?.label}</div>
              <div>
                {/* {data?.clause?.length &&
          selected_row_?.clause?.map((c) => <span>{c}</span>)} */}
              </div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.reason?.label}</div>
              <div>{data?.reason?.label}</div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.startDate?.label}</div>
              <div>
                {moment(data?.startDate?.value).format("MM-DD-YYYY, h:mm:ss")}
              </div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.endDate?.label}</div>
              <div>
                {moment(data?.endDate?.value).format("MM-DD-YYYY, h:mm:ss")}
              </div>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <div>{data?.recruiterName?.label}</div>
              <div>{data?.recruiterName?.value}</div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={view_dialog?.state}
      fullWidth
    >
      <DialogTitle
        sx={{ m: 0, p: 4, textAlign: "center" }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers style={{ padding: "12px" }}>
        {dialog_content()}
      </DialogContent>
      <DialogActions>
        {buttons?.map((b, index) => (
          <Button key={index} autoFocus onClick={b?.onClick}>
            {b?.text}
          </Button>
        ))}
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CustomDialog;
// saqqala el vurma
