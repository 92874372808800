import React, { useEffect, useRef, useState } from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../../api/api";
import CreateIcon from "@mui/icons-material/Create";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { EducationValidation } from "./Validations";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
];

const MCViewEducationInfo = ({ employeeById }) => {
  const [universities, setUniversities] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  useEffect(() => {
    const handleUniversities = async () => {
      const universityResponse = await getCommonData("universityList");
      const facultyResponse = await getCommonData("facultyList");
      setUniversities(universityResponse.data);
      setFaculties(facultyResponse.data);
    };
    handleUniversities();
  }, []);

  const initialValues = {
    education:
      employeeById?.education?.map((item, index) => ({ ...item, id: index })) ||
      [],
  };

  const [dataIndex, setDataIndex] = useState(initialValues.education.at(-1).id);

  // form change request methods

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key]) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a?.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "education-info",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.education.forEach((obj1) => {
      const obj2 = oldValue.education.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.education.forEach((obj2) => {
      const obj1 = formValue.education.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }

    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(data) {
    makeChangesData(data, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "education-info",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.education?.forEach((obj1) => {
      const obj2 = oldValue.education.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.education.forEach((obj2) => {
      const obj1 = formValue.education.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }

    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}

            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={EducationValidation}
            initialValues={{
              education: initialValues.education || [],
            }}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
            }) => (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <FieldArray name="education">
                  {({ push, remove }) => (
                    <div>
                      {values?.education?.map((form, index) => (
                        <div key={index}>
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <Typography sx={{ mt: 5 }} variant="h6">
                                {index + 1 + ")"}Təhsil məlumatları
                              </Typography>
                            </div>

                            {/* Education type and institution */}
                            <Grid
                              sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}
                            >
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.education?.[index]?.edType &&
                                    Boolean(errors?.education?.[index]?.edType)
                                  }
                                  fullWidth
                                >
                                  <InputLabel id={`education[${index}].edType`}>
                                    Təhsil növü
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    required
                                    name={`education[${index}].edType`}
                                    labelId={`reducation[${index}].edType`}
                                    label="Təhsil növü"
                                    id={`education[${index}].edType`}
                                    defaultValue=""
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `education[${index}].edType`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.education?.[index]?.edType &&
                                      Boolean(
                                        errors?.education?.[index]?.edType
                                      )
                                    }
                                  >
                                    {educationType.map((item, index) => (
                                      <MenuItem key={index} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {errors.education?.[index]?.edType &&
                                    errors.education?.[index]?.edType && (
                                      <FormHelperText error>
                                        {errors.education?.[index]?.edType}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.education?.[index]?.institution &&
                                    Boolean(
                                      errors?.education?.[index]?.institution
                                    )
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`education[${index}].institution`}
                                  >
                                    Təhsil müəssisəsi
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`education[${index}].institution`}
                                    labelId={`education[${index}].institution`}
                                    label="Təhsil müəssisəsi"
                                    id={`education[${index}].institution`}
                                    defaultValue=""
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `education[${index}].institution`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.education?.[index]?.institution &&
                                      Boolean(
                                        errors?.education?.[index]?.institution
                                      )
                                    }
                                  >
                                    {universities.map((item, index) => (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {errors.education?.[index]?.institution &&
                                    errors.education?.[index]?.institution && (
                                      <FormHelperText error>
                                        {errors.education?.[index]?.institution}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>

                            {/* education[${index}] field */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.education?.[index]?.faculty &&
                                    Boolean(errors?.education?.[index]?.faculty)
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`education[${index}].faculty`}
                                  >
                                    Təhsil sahəsi (fakultə)
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`education[${index}].faculty`}
                                    labelId={`education[${index}].faculty`}
                                    label="Təhsil sahəsi (fakultə)"
                                    id={`education[${index}].faculty`}
                                    defaultValue=""
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `education[${index}].faculty`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.education?.[index]?.faculty &&
                                      Boolean(
                                        errors?.education?.[index]?.faculty
                                      )
                                    }
                                  >
                                    {faculties.map((item, index) => (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {errors.education?.[index]?.faculty &&
                                    errors.education?.[index]?.faculty && (
                                      <FormHelperText error>
                                        {errors.education?.[index]?.faculty}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>

                            {/*start date and end date */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <Field name={`education[${index}].startDate`}>
                                  {({ field, form }) => {
                                    const selectedEndDate =
                                      form.values.education[index]?.endDate;

                                    return (
                                      <DatePicker
                                        {...field}
                                        label="Təhsilin başlama tarixi"
                                        inputFormat="dd/MM/yyyy"
                                        value={field.value}
                                        maxDate={
                                          selectedEndDate
                                            ? new Date(selectedEndDate)
                                            : undefined
                                        }
                                        minDate={new Date(1940, 0, 1)}
                                        onChange={(date) => {
                                          setRequestButton(true);

                                          form.setFieldValue(
                                            `education[${index}].startDate`,
                                            date
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={
                                              form.errors?.education?.[index]
                                                ?.startDate
                                            }
                                            error={Boolean(
                                              form.errors?.education?.[index]
                                                ?.startDate
                                            )}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    );
                                  }}
                                </Field>
                              </Grid>
                              <Grid sx={{ width: "100%" }}>
                                <Field name={`education[${index}].endDate`}>
                                  {({ field, form }) => {
                                    const selectedStartDate =
                                      form.values.education[index]?.startDate;

                                    return (
                                      <DatePicker
                                        {...field}
                                        label="Təhsilin bitmə tarixi"
                                        inputFormat="dd/MM/yyyy"
                                        value={field.value}
                                        minDate={
                                          selectedStartDate
                                            ? new Date(selectedStartDate)
                                            : undefined
                                        }
                                        onChange={(date) => {
                                          setRequestButton(true);
                                          form.setFieldValue(
                                            `education[${index}].endDate`,
                                            date
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={
                                              form.errors?.education?.[index]
                                                ?.endDate
                                            }
                                            error={Boolean(
                                              form.errors?.education?.[index]
                                                ?.endDate
                                            )}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    );
                                  }}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.education[index]?.stillStudying ||
                                      false
                                    }
                                    color="primary"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `education[${index}].stillStudying`,
                                        e.target.checked
                                      );
                                    }}
                                    name={`education[${index}].stillStudying`}
                                  />
                                }
                                label="Təhsil davam edir"
                              />
                            </Grid>
                          </>
                          <Button
                            sx={
                              formCorrection
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                            variant="contained"
                            color="error"
                            type="button"
                            style={{
                              marginBottom: "10px",
                            }}
                            onClick={() => {
                              setRequestButton(true);
                              remove(index);
                              const updatedArray = values.education.filter(
                                (item) => item.id !== index
                              );
                              controlFormForSubmitting({
                                education: updatedArray,
                              });
                            }}
                          >
                            Ləğv et
                          </Button>
                        </div>
                      ))}
                      <Button
                        sx={
                          formCorrection
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: "10px", marginTop: "20px" }}
                        onClick={() => {
                          let newId = dataIndex + 1;
                          setDataIndex(newId);
                          push({ id: newId });
                        }}
                      >
                        Əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid
                  style={
                    formCorrection
                      ? { visibility: "visible", marginTop: "10px" }
                      : { visibility: "hidden" }
                  }
                >
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişikliklər sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewEducationInfo;
