/* eslint-disable prettier/prettier */
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getEmployeeRequestsHr,
  resendEmployeeRequest,
} from "../../../api/employeeRequests/employeeRequests";

import swal from "sweetalert";
import * as contractServices from "../../../services/documentations/ContractServices";
import TableWithVacationFields from "../../layouts/dataTable/TableWithVacationFields";

import { Person, RemoveRedEye } from "@mui/icons-material";
import {
  OPERATORS_CONTAINING,
  SYSTEM_CONFIRMATION_STATUS_FOR_FILTER,
} from "../../../constants";
import Column from "../../../models/table/Column";
import Operator from "../../../models/table/Operator";
import {
  getDownloadVacationrRequest,
  getEmployeeRequestManager,
} from "../../../services/vacation/VacationRequest";
import { downloadFile } from "../../../utils/downloadCSV";
import { getStaticFilterValues } from "../../../utils/forFilterDropdown/getStructureUtils";
import {
  getDescendantProp,
  normalizeDate,
} from "../../../utils/helperFunctions";
import ContReqDialog from "../../dialogs/documentDialog/ContReqDialog";
import StatusGenerator from "../../table/StatusGenerator";
import Table from "../../table/Table";

import OperatorGenerator from "../../table/OperatorGenerator";

const DetailedVacationTable = () => {
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState();

  const [openModal, setOpenModal] = useState(false);
  const [vacationCommandDetails, setVacationCommandDetails] = useState();
  const [employeeRequestId, setEmployeeRequestId] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [idList, setIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedByCreator, setUpdatedByCreator] = useState(false);
  const [forwardedPerson, setForwardedPerson] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [chainEl, setChainEl] = useState(null);
  const [approvementChainStatus, setApprovementChainStatus] = useState([]);
  const [selectedVacation, setSelectedVacation] = useState();
  const [vacationType, setVacationType] = useState();
  const [handleVacationDialog, setHandleVacationDialog] = useState(false);
  const [description, setDescription] = useState();

  // Filter Dialog
  const [filters, setFilters] = useState([]);

  //   Submitted
  const [submitted, setSubmitted] = useState(false);

  //   Get vacations with pagination
  const [currentRequests, setCurrentRequests] = useState([]);

  useEffect(() => {
    getVacations(filters);
  }, [filters, offset, limit]);

  const downlaodHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([
          {
            column: "docModel",
            operator: "equalsTo",
            value: "VacationRequest",
          },
          ...filters,
        ])
      );
      const resp = await getDownloadVacationrRequest({
        columns: encodeColumns,
        filters: encodeFilters,
        isWithFilter: filters.length > 0,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const getVacations = async (filters) => {
    if (!filters) {
      filters = [];
    }

    filters.push({
      column: "docModel",
      operator: "equalsTo",
      value: "VacationRequest",
    });

    const vacationsResponse = await getEmployeeRequestsHr(
      limit,
      offset,
      encodeURIComponent(JSON.stringify(filters))
    );

    if (vacationsResponse && vacationsResponse.data) {
      setTotal(vacationsResponse.data.total);
      // const modifiedResponse = vacationsResponse.data.requests.map(
      //   (item, index) => {
      //     const statusEmployee = item.approvementChain.find(
      //       (user) => user.status === "pending"
      //     );

      //     return {
      //       ...item,
      //       id: item._id,
      //       createdAt: moment(item.createdAt).format("DD/MM/yyyy"),
      //       name:
      //         item.originalRequest.employee.firstname +
      //         " " +
      //         item.originalRequest.employee.lastname,
      //       position: item.creator.position?.name,
      //       startDate: new Date(item.originalRequest.startDate),
      //       endDate: new Date(item.originalRequest.endDate),
      //       structure: item?.decisionChain?.structure.organization.name,
      //       department: item?.decisionChain?.topCompanyPartRel?.source?.name,
      //       subStructure: item?.decisionChain?.companyPartRel.source.name,
      //       decisionDate: item?.decisionDate
      //         ? moment(item.decisionDate).format("DD/MM/yyyy")
      //         : "",
      //       pendingEmployee: statusEmployee?.employee
      //         ? statusEmployee.employee.firstname +
      //           " " +
      //           statusEmployee.employee.lastname
      //         : "Təsdiq olunub",
      //     };
      //   }
      // );
      setCurrentRequests(vacationsResponse.data.requests);
    }
  };

  useEffect(() => {
    getVacations();
  }, [offset, submitted, isCreated]);

  // Handle view versions
  const [lookAtMenu, setLookAtMenu] = useState(false);

  const [filterMenu, setFilterMenu] = useState(null);

  const handleViewVersions = (params) => {
    const listId = [];

    params?._id &&
      listId.push({
        id: params?._id,
        handler: getEmployeeRequestManager,
        type: "request",
        isGroup: true,
        label: "Məzuniyyət ərizəsi",
      });

    //originreq => contractCreated true
    params?.originalRequest?.contractCreated &&
      params?.originalRequest?.contract?._id &&
      listId.push({
        id: params?.originalRequest?.contract?._id,
        handler: contractServices?.getContract,
        type: "contract",
        isGroup: true,
        label: "Məzuniyyət əmri",
      });

    setIdList([...listId]);
    setLookAtMenu(true);
  };

  const handleMenuClick = (event, action, params) => {
    if (action === "forward") {
      setAnchorEl(event.currentTarget);
    } else if (action === "filter") {
      setFilterMenu(event.currentTarget);
    } else if (action === "approvementChain") {
      setChainEl(event.currentTarget);
      setApprovementChainStatus(params);
    }
  };

  const handleMenuClose = (action) => {
    if (action === "forward") {
      setAnchorEl(null);
    } else if (action === "filter") {
      setFilterMenu(null);
    } else if (action === "approvementChain") {
      setChainEl(null);
      setApprovementChainStatus([]);
    }
  };

  const handleEmployeeRequest = async (id) => {
    const subject = forwardedPerson === "Rəhbərliyə" ? "manager" : "employee";
    const response = await resendEmployeeRequest(id, subject, description);
    setSubmitted(!submitted);
    setHandleVacationDialog(false);
  };

  const createCommand = async (employeeRequestId, row) => {
    setVacationType(row?.originalRequest?.vacationType);
    setEmployeeRequestId(employeeRequestId);
    if (employeeRequestId && row?.originalRequest?.vacationType) {
      handleShowCreateDialog(employeeRequestId,row?.originalRequest?.vacationType);
    }
  };
  const handleShowCreateDialog = async (employeeRequestId,vacationType) => {
    try {
      setOpenModal(true);

      const response = await contractServices.getPreviewStaticContract(
        employeeRequestId,
        `${vacationType}-vacation-order`
      );
      setVacationCommandDetails(response);
    } catch (error) {
      console.log("error: ", error);
    }
  };
  // useEffect(() => {
  //   if (employeeRequestId) {
  //     handleShowCreateDialog();
  //   }
  // }, [vacationType, employeeRequestId]);

  const column = [
    new Column({
      path: "#",
      unhidableColumn: true,
      disableColSearch: true,

      headerName: "Operatorlar",
      renderCell: (row, column) => {
        const operators = [
          new Operator({
            icon: <RemoveRedEye />,
            hideCondition: false,
            onClick: () => {
              handleViewVersions(row);
            },
          }),
          new Operator({
            icon: <SendIcon />,
            hideCondition: row.status !== "updatedByCreator",
            onClick: (event) => {
              handleMenuClick(event, "forward");
            },
          }),
          new Operator({
            icon: <SendIcon />,
            hideCondition: row.status !== "rejectedByChainUser",
            onClick: async () => {
              await resendEmployeeRequest(row._id, "employee");
              setSubmitted(!submitted);
              setHandleVacationDialog(false);
            },
          }),
          new Operator({
            icon: (
              <>
                <p>Əmr yarat</p>
              </>
            ),
            hideCondition: row.status !== "pendingOrder",
            onClick: () => {
              createCommand(row._id, row);
            },
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "requestNO",
      headerName: "Sorğu NO",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "createdAt",
      headerName: "Sorğu tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalRequest.employee.firstname",
      headerName: "Sorğu subyekti adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      hasCellButton: true,
      cellButtonProps: {
        icon: <Person />,
        onClick: (value) => window.open(`/employees/${value}/view`),
        value: (row) => {
          return getDescendantProp(row, "originalRequest.employee.id");
        },
      },
    }),
    new Column({
      path: "originalRequest.employee.lastname",
      headerName: "Sorğu subyekti soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "baseStructure.organization.name",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterColumnKey: "baseStructure._id",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      keyForResponseDataFromFilter: "baseStructures",
    }),
    new Column({
      path: "topPartOfStructure.source.name",
      headerName: "Struktur",
      filterColumnKey: "topPartOfStructure._id",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterColumnKey: "subStructure._id",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "subStructures",
    }),
    new Column({
      path: "position.source.name",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.OPTION,
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "positions",
      getValue: (row, col) => {
        return getDescendantProp(row, col.path, false);
      },
    }),
    new Column({
      path: "originalRequest.startDate",
      headerName: "Başlanğıc tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalRequest.endDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),

    new Column({
      path: "decisionDate",
      headerName: "Qərar tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: SYSTEM_CONFIRMATION_STATUS_FOR_FILTER,

      renderCell: (row, column) => {
        const approvers = [];

        if (row.status === "pendingOrderApprove") {
          row.originalRequest.contract.verifiers.map((item, index) => {
            approvers.push({
              index: index + 1,
              employee: item.employee.firstname + item.employee.lastname,
              status: item.status,
            });
          });
        } else {
          row.approvementChain.map((item, index) => {
            approvers.push({
              index: index + 1,
              employee:
                item.staff.employee[0].firstname +
                item.staff.employee[0].lastname,
              status: item.status,
            });
          });
        }

        return (
          <StatusGenerator
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"systemConfirmation"}
          />
        );
      },
    }),
  ];

  const [scrollLeft, setScrollLeft] = useState(0);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCreateVacationOrder = async () => {
    if (vacationType) {
      try {
        const willCreateVacationOrder = await swal({
          title: "Əminsiniz mi?",
          text: "Əmr yaratmaq üçün əminsiniz mi?",
          icon: "warning",
          dangerMode: true,
          buttons: ["Ləğv et", "Yarat"],
        });

        if (willCreateVacationOrder) {
          const response = await contractServices.createVacationOrder(
            employeeRequestId,
            `${vacationType}-vacation-order`
          );
          if (response) {
            setIsCreated(!isCreated);
            swal("Əlavə olundu!", "Uğurla əmr yaradıldı!", "success");
          } else {
            swal("Xəta!", "Xəta baş verdi!", "error");
          }
          setOpenModal(false);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  };

  return (
    <>
      {openModal && vacationType && (
        <Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className="vacation_dialog"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Əmr yarat
          </DialogTitle>
          <DialogContent>
            <TableWithVacationFields
              data={vacationCommandDetails}
              headers={["Name", "", ""]}
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose} color="primary">
              Ləğv et
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={handleCreateVacationOrder}
            >
              Əmr Yarat
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CardContent>
        <Table
          columns={column}
          data={currentRequests}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={(filters) => setFilters(filters)}
          cancelAppliedFilters={() => setFilters([])}
          downloadHandler={(cols) => downlaodHandler(cols)}
        />
      </CardContent>

      {idList.length > 0 && lookAtMenu && (
        <ContReqDialog
          open={lookAtMenu}
          onClose={() => {
            setIdList([]);
            setLookAtMenu(false);
          }}
          idList={idList}
          idListProps={{
            defaultIndex: idList.length - 1,
          }}
          disableSigning={true}
          fromWhichPage={"hr-vacation"}
        />
      )}

      {handleVacationDialog && (
        <Dialog
          open={handleVacationDialog}
          onClose={() => {
            setHandleVacationDialog(false);
            setSelectedVacation();
          }}
        >
          <Grid mt={5} p={10}>
            <TextField
              fullWidth
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              label={`Şərh - HRın ${forwardedPerson.toLowerCase()} yeni şərhi`}
              variant="standard"
              multiline
              minRows={3}
              maxRows={8}
            />

            <Button
              onClick={() => {
                handleEmployeeRequest(selectedVacation._id);
              }}
            >
              Yönləndir
            </Button>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default DetailedVacationTable;
