import { api } from "./axiosInterceptor";

export const createFillingVacantPositionOrder_API = async (
  contractName,
  data
) => {
  try {
    return await api.post(`employee/contract/static/${contractName}`, data);
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};
export const updateVacantOrder_API = async (contractName, id, data) => {
  try {
    return await api.put(
      `employee/contract/static/${contractName}/${id}`,
      data
    );
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};

export const getFillingVacantPositionOrder_API = async (filter) => {
  try {
    return await api.get(`employee/e?filter=${filter}`);
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};

export const getCommand_API = async (contractId, contractName) => {
  try {
    return await api.get(
      `employee/contract/${contractId}?contractType=${contractName}`
    );
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};
export const getEmployeeTable_API = async (employeeId) => {
  try {
    return await api.get(
      `employee/vacation-request/vacation-days-of-employee?employeeId=${employeeId}`
    );
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};

export const getEmployeesForRecallData = async () => {
  try {
    return await api.get(`employee/contract/employees/details`);
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};
export const getEmployeeWithDetails = async () => {
  try {
    return await api.get(`employee/contract/employees/details`);
  } catch (err) {
    console.error("API Error:", err);
    throw err;
  }
};
