import React from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const SocialProtection = ({ values, setFieldValue }) => {
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          // sx={{ border: "1px solid #56606F", borderRadius: "4px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Sosial müdafiə məsələləri</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              type="number"
              label="Sığorta haqqı (aylıq)"
              value={values?.socialProtection?.monthlyInsuranceFee}
              fullWidth
              name="socialProtection.monthlyInsuranceFee"
              onWheel={(e) => e.target.blur()}
              onChange={(event) => {
                setFieldValue(
                  "socialProtection.monthlyInsuranceFee",
                  event.target.value
                );
              }}
            />
            <TextField
              type="number"
              label="Əlavə sığorta məbləği"
              value={values?.socialProtection?.extraSumInsured}
              fullWidth
              name="socialProtection.extraSumInsured"
              onWheel={(e) => e.target.blur()}
              onChange={(event) => {
                setFieldValue(
                  "socialProtection.extraSumInsured",
                  event.target.value
                );
              }}
            />
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default SocialProtection;
